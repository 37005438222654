import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import { useAuth } from "./../util/auth";
import TrustGame from "./Games/TrustGame";
import PlaySessions from "./PlaySessions";
import PlaySessionsHistory from "./PlaySessionsHistory";


const useStyles = makeStyles((theme) => ({
  cardContent: {
    padding: theme.spacing(3),
  }
}));

function PlayroomSection(props) {
  const classes = useStyles();

  const auth = useAuth();

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />
        
        <Grid container={true} spacing={4}>
          <Grid item={true} xs={12} md={12}>
            
                <TrustGame></TrustGame>
              
          </Grid>
          { (auth.user.playroom === undefined || auth.user.playroom === null) && <><Grid item={true} xs={12} md={12}>
            <Card>
              <CardContent className={classes.cardContent}>
                <PlaySessions></PlaySessions>
              </CardContent>
            </Card>
          </Grid> 
          <Grid item={true} xs={12} md={12}>
          <Card>
            <CardContent className={classes.cardContent}>
              <PlaySessionsHistory></PlaySessionsHistory>
            </CardContent>
          </Card>
        </Grid>  </> }
        </Grid>
      </Container>
    </Section>
  );
}

export default PlayroomSection;
