import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import Leaderboard from "./Leaderboard";


function LeadersSection(props) {


  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
      <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
          backTo={-1}
        />
        

        <Grid container={true} spacing={4}>        
          <Grid item={true} xs={12} md={12}>

                <Leaderboard></Leaderboard>

          </Grid>
        </Grid>
      </Container>
    </Section>
  );
}

export default LeadersSection;
