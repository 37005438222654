import React from "react";
import Meta from "./../components/Meta";
import ContactSection from "./../components/ContactSection";
import { useTranslation } from 'react-i18next';

function ContactPage(props) {
  const { t } = useTranslation();

  return (
    <>
      <Meta title={t("meta.contact.title")} />
      <ContactSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title={t("contact.title")}
        subtitle=""
        buttonText={t("contact.button")}
        buttonColor="primary"
        showNameField={true}
      />
    </>
  );
}

export default ContactPage;
