import React from "react";
import Meta from "./../components/Meta";
import LegalSection from "./../components/LegalSection";
import { useRouter } from "./../util/router";
import { useTranslation } from 'react-i18next';

function LegalPage(props) {
  const router = useRouter();
  const { t } = useTranslation();

  return (
    <>
      <Meta title={t("meta.legal.title")}/>
      <LegalSection
        bgColor="default"
        size="normal"
        bgImage=""
        bgImageOpacity={1}
        section={router.query.section}
        key={router.query.section}
      />
    </>
  );
}

export default LegalPage;
