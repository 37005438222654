import React, { useEffect, Suspense } from "react";
import Navbar from "./../components/Navbar";
import IndexPage from "./index";
import AboutPage from "./about";
import RulesPage from "./rules";
import DoNotSellPage from "./donotsell";
import FaqPage from "./faq";
import ContactPage from "./contact";
import DashboardPage from "./dashboard";
import PlayroomPage from "./playroom";
import NotificationsPage from "./notifications";
import StatisticsPage from "./statistics"
import LeadersPage from "./leaders";
import AuthPage from "./auth";
import CannyAuthPage from "./cannyauth";
import SettingsPage from "./settings";
import LegalPage from "./legal";
import { BrowserRouter, Routes, Route } from "./../util/router";
import PurchasePage from "./purchase";
import FirebaseActionPage from "./firebase-action";
import NotFoundPage from "./404";
import Footer from "./../components/Footer";
import "./../util/analytics";
import { AuthProvider } from "./../util/auth";
import { ThemeProvider } from "./../util/theme";
import { QueryClientProvider } from "./../util/db";
import { useTranslation } from 'react-i18next';
import IdeasPage from "./ideas";

function App(props) {

  const { t, i18n } = useTranslation();

  useEffect(() => {
    window.CRISP_RUNTIME_CONFIG = {
      locale : i18n.resolvedLanguage
    };
  }, [i18n]);

  useEffect(() => {
    window.$crisp=[];
    window.CRISP_WEBSITE_ID=process.env.REACT_APP_CRISP_WEBSITE_ID;
    (function(){
      const d=document;
      const s=d.createElement("script");
      s.src="https://client.crisp.chat/l.js";
      s.async=1;
      d.getElementsByTagName("head")[0].appendChild(s);
    })();
  },[]);

  return (
    <Suspense fallback="...is loading">
    <QueryClientProvider>
      <ThemeProvider>
        <AuthProvider>
          <BrowserRouter>
            <>

              <Navbar
                color="default"
                logo={t("logo.light")}
                logoInverted={t("logo.inverted")}
              />
              
              <Routes>
                <Route  path="/" element={<IndexPage />} />

                <Route  path="/psb/:psb" element={<DashboardPage />} />

                <Route  path="/ttb/:ttb" element={<DashboardPage />} />

                <Route  path="/i/:invite" element={<DashboardPage />} />

                <Route  path="/c/:connection"  element={<DashboardPage />} />

                <Route  path="/about" element={<AboutPage/>} />

                <Route  path="/rules" element={<RulesPage />} />

                <Route  path="/faq" element={<FaqPage />} />

                <Route  path="/contact" element={<ContactPage />} />

                <Route  path="/dashboard"  element={<DashboardPage />} />

                <Route  path="/playroom"  element={<PlayroomPage />} />

                <Route  path="/auth/:type" element={<AuthPage />} />

                <Route  path="/cannyssoauth" element={<CannyAuthPage />} />

                <Route exact path="/ideas" element={<IdeasPage />} />

                <Route path="/ideas/:section/*" element={<IdeasPage />} />

                <Route  path="/notifications" element={<NotificationsPage />} />
                
                <Route  path="/statistics" element={<StatisticsPage />} />

                <Route  path="/leaders" element={<LeadersPage />} />

                <Route
                  
                  path="/settings/:section"
                  element={<SettingsPage />}
                />

                <Route  path="/legal/:section" element={<LegalPage />} />

                <Route  path="/donotsell" element={<DoNotSellPage />} />

                <Route  path="/purchase/:plan" element={<PurchasePage />} />

                <Route
                  
                  path="/firebase-action"
                  element={<FirebaseActionPage />}
                />

                <Route path="/*" element={<NotFoundPage />} />
              </Routes>
              <Footer
                bgColor="light"
                size="normal"
                bgImage=""
                bgImageOpacity={1}
                description={t("meta.moto")}
                copyright={`©${new Date().getFullYear()} STC Labs`}
                logo={t("logo.light")}
                logoInverted={t("logo.inverted")}
                sticky={true}
              />
            </>
          </BrowserRouter>
        </AuthProvider>
      </ThemeProvider>
    </QueryClientProvider>
    </Suspense>
  );
}

export default App;
