import React from "react";
import Meta from "./../components/Meta";
import LeadersSection from "./../components/LeadersSection";
import { useTranslation } from 'react-i18next';

function LeadersPage(props) {

  const { t } = useTranslation();

  return (
    <>
    <Meta title={t("meta.leaders.title")}/>
    
      <LeadersSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title={t("leaderboard.headline")}
        subtitle=""
      />
    </>
  );
}

export default LeadersPage;