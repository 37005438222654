import React from "react";
import Meta from "./../components/Meta";
import PlayroomSection from "./../components/PlayroomSection";
import { requireAuth, useAuth } from "./../util/auth";
import { useRouter } from "./../util/router";
import IntroModal from "./../components/IntroModal";
import { useTranslation} from 'react-i18next';

function PlayroomPage(props) {
  
  const auth = useAuth();
  const router = useRouter();

  const { t } = useTranslation();

  return (
    <>
    <Meta title={t("meta.playroom.title")} />
    { !auth.user.name && <IntroModal /> } 
    { auth.user.name &&
      <PlayroomSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title={t("playroom.headline")}
        subtitle=""
        invite={router.query.invite}
        connection={router.query.connection}
        ttb={router.query.ttb}
        psb={router.query.psb}
      />
    }
    </>
  );
}

export default requireAuth(PlayroomPage);