import React, { useState } from "react";
import { 
    Box,
    Grid,
    Card,
    CardContent,
    InputLabel,
    MenuItem,
    Select,
    Button,
    Typography,
    CircularProgress, 
    FormControl
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import { Trans, useTranslation } from 'react-i18next';
import { 
    createPlayroom,
    usePlayroom,
    updateChoice,
    updateState,
    updateTrustor,
    updateBalances
} from "../../util/db";
import { useAuth } from "../../util/auth";
import GameRules from "./GameRules";
import {
    rules,
    roleA,
    roleB,
    getPlayer,
    getRole,
    getChoice,
    getRoleAChoice,
    getRoleBChoice,
    getOpponentChoice,
    state
} from "./utils";
import AfterParty from "./AfterParty";
import ChoiceMaker from "./ChoiceMaker";
import ScoreBoard from "./ScoreBoard";

const useStyles = makeStyles((theme) => ({
    cardContent: {
      padding: theme.spacing(3),
    }
  }));

function TrustGame(props) {

    const classes = useStyles();
    const auth = useAuth();
    const { t } = useTranslation();

    const {
        data: game,
        status: gameStatus,
        error: gameError,
      } = usePlayroom(auth.user.playroom);

    const gamesAreEmpty = !game || game.length === 0;

    const createSession = async function () {
        setChoice(0.0);
        const data = {
            endowment: rules[gameType].endowment,
            gameType: gameType,
            roundNumber: 1,
            nextRoom: null,
            players: [auth.user.uid],
            playerA: {
                id: auth.user.uid,
                role: null,
                balance: 0,
                choice: null,
                submittedOn: null
            },
            playerB: {
                id: null,
                role: null,
                balance: 0,
                choice: null,
                submittedOn: null
            },
            state: 0
        };
        const playroom = await createPlayroom(data);
        updateTrustor(auth.user.uid, { playroom: playroom.id });
    }

    const replay = async function () {   
        setChoice(0.0);  
        if (game.state === 4 || game.state === 5) {
            const role = Math.random() > 0.5 ? 1 : 0;
            const data = {
                endowment: rules[game.gameType].endowment,
                gameType: game.gameType,
                roundNumber: game.roundNumber + 1,
                nextRoom: null,
                players: [game.playerA.id, game.playerB.id],
                playerA: {
                    id: game.playerA.id,
                    role: role,
                    balance: rules[game.gameType].getBalance(game, "A"),
                    choice: null,
                    submittedOn: null
                },
                playerB: {
                    id: game.playerB.id,
                    role: 1 - role,
                    balance: rules[game.gameType].getBalance(game, "B"),
                    choice: null,
                    submittedOn: null
                },
                state: 1
            };      
            const playroom =  await createPlayroom(data);
             updateState(auth.user.playroom, 8, playroom.id);
             updateTrustor(game.playerA.id, { playroom: playroom.id });
             updateTrustor(game.playerB.id, { playroom: playroom.id });
        } else {
            updateState(auth.user.playroom, getRole(game, auth.user.uid) === roleA ? 4 : 5);
        }  
    }

    const exit = async function () {  
        setChoice(0.0);   
        switch (game.state) {
            case 1: updateState(auth.user.playroom, 7); break;
            case 2: updateState(auth.user.playroom, 7); break;
            case 3: updateState(auth.user.playroom, 6); break;
            case 4: updateState(auth.user.playroom, 6); break;
            case 5: updateState(auth.user.playroom, 6); break;
            default: updateState(auth.user.playroom, 7); break;
        }
        if (game.state < 6 ) updateBalances(auth.user.playroom, game, rules[game.gameType].getBalance(game, "A"), rules[game.gameType].getBalance(game, "B"));
        updateTrustor(auth.user.uid, { playroom: null });
    }

    const submitChoice = function (choice) {
        const player = getPlayer(game, auth.user.uid);
        const finished = (rules[game.gameType].required === 1 && choice === 0) || getOpponentChoice(game, player) !== null;
        const nextState = finished ? state.finished : state.pending;
        updateChoice(auth.user.playroom, player, choice, nextState) // normal step if roleA choice is positive or both choices required
        // either roleA is greedy and second choice is irrelevant or trustee made their choice - game resolved
    }

    // UI states and event handlers
    const [choice, setChoice] = useState(0);
    const [gameType, setGameType] = useState('');

    const handleTypeChange = (event) => {
        setGameType(event.target.value);
      };

    const handleChoiceChange = (event, newValue) => {
        setChoice(newValue);
    }

    return(
        <>
    <Grid container={true} spacing={4}>       
         { // scoreboard bar
        (gameStatus !== "loading" && !gamesAreEmpty) && (         
            <Grid item={true} xs={12} md={12}> 
                <ScoreBoard game={game} uid={auth.user.uid}></ScoreBoard>
            </Grid>
        )}

        <Grid item={true} xs={12} md={12}>               
            <Card>
                <CardContent className={classes.cardContent}>

            { // Button to start a new session
            (gameStatus === "loading" || (gamesAreEmpty)) && (
            <Box py={5} px={3} align="center">
                {gameStatus === "loading" && <CircularProgress size={32} />}

                {(gameStatus !== "loading" && gamesAreEmpty) && ( <>
                    <Grid container={true} spacing={4}>
                        <Grid item={true} xs={12} md={6}>
                    <FormControl fullWidth>
                    <InputLabel id="game-type-label"><Trans i18nKey="playroom.choose"></Trans></InputLabel>
                    <Select
                        labelId="game-type-label"
                        id="gametype-select"
                        value={gameType}
                        label={t("playroom.choose")}
                        onChange={handleTypeChange}
                    >
                         { // generate games list for selection from rules object skipping the 0 indexed first object
                         rules.slice(1).map((item, index) => (
                            <MenuItem key={index.toString()} value={(index+1).toString()}><Trans i18nKey={"playroom.gametypes." + (index+1).toString()}></Trans></MenuItem>                        
                         ))}
                    </Select>
                    </FormControl></Grid>
                    <Grid item={true} xs={12} md={6}> <Button
                            disabled={ gameType === ''}
                            variant="contained"
                            size="medium"
                            color="primary"
                            onClick={() => createSession()}
                        >
                       <Trans i18nKey="playroom.start"></Trans>
                        </Button> </Grid>
                        </Grid>
                </>)}
            </Box>
            )}

            { // Waiting for the session to fill
            (gameStatus !== "loading" && !gamesAreEmpty && game.state === 0) && (
                 <Box py={5} px={3} align="center">
               
               <Typography paragraph={true}><Trans i18nKey="playroom.waiting"></Trans></Typography>
                <CircularProgress size={32} /><br/><br />
                <Button
                            variant="contained"
                            size="medium"
                            color="secondary"
                            onClick={() => exit()}
                        >
                    <Trans i18nKey="playroom.cancel"></Trans>
                </Button>
                </Box>
            )}
 
            { // Endowment size and assigned role header
            (gameStatus !== "loading" && !gamesAreEmpty && game.state > 0) && ( 
                <>
                State: {game.state}
                    { game.endowment > 0 && <Typography paragraph={true} variant="body1">{t("playroom.rules."+game.gameType+".endowment", {amount: game.endowment})}</Typography>}
                    <Typography paragraph={true}><Trans i18nKey="playroom.role"></Trans><b><Trans i18nKey={"playroom.rules."+game.gameType+".rolenames."+getRole(game, auth.user.uid)}></Trans></b>.</Typography>
                </>
            )}

            { // State 1 - roleB awaits in sequence or everyone presented with choice maker
            (gameStatus !== "loading" && !gamesAreEmpty && game.state === 1) && ( 
                <>
                    { // if sequential rule then roleB awaits for their turn
                    rules[game.gameType].turns === "sequential" && getRole(game, auth.user.uid) === roleB &&  <>
                    <Typography paragraph={true}><Trans i18nKey={"playroom.rules."+game.gameType+".prompt."+roleB}></Trans><CircularProgress size={32} /></Typography>
                            
                            <Button
                                    variant="contained"
                                    size="medium"
                                    color="secondary"
                                    onClick={() => exit()}
                                >
                            <Trans i18nKey="playroom.exit"></Trans>
                        </Button> </> }

                    { // otherwise choice maker control is presented with role dependent prompt
                    (rules[game.gameType].turns === "concurrent" || getRole(game, auth.user.uid) === roleA) && <>
                    <Typography paragraph={true}><Trans i18nKey={"playroom.rules."+game.gameType+".prompt."+getRole(game, auth.user.uid)}></Trans></Typography>
                    <ChoiceMaker game={game} uid={auth.user.uid} choice={choice} handleChoiceChange={handleChoiceChange} submitChoice={submitChoice}></ChoiceMaker>
                    </>  
                    }
                </>
            )}

            {// State 2 - RoleB decides, RoleA awaits
            (gameStatus !== "loading" && !gamesAreEmpty && game.state === 2) && (
            <>
             { // roleA submitted decision
                getRole(game, auth.user.uid) === roleA && 
                        <>
                { getRoleAChoice(game)!== null && getRoleAChoice(game) > 0 && <Typography paragraph={true}>{t("playroom.rules."+game.gameType+".decision.sent", {amount: getRoleAChoice(game), multiplied: getRoleAChoice(game) * rules[game.gameType].multiplier })}</Typography>}
                { getRoleAChoice(game)!== null && getRoleAChoice(game) === 0 && <Typography paragraph={true}>{t("playroom.rules."+game.gameType+".decision.greedy.mistrust")}</Typography>}
                </>
             }

            { // roleA submitted decision
                getRole(game, auth.user.uid) === roleB && 
                        <>
                { getRoleBChoice(game)!== null && getRoleBChoice(game) > 0 && <Typography paragraph={true}>{t("playroom.rules."+game.gameType+".decision.sent", {amount: getRoleAChoice(game), multiplied: getRoleAChoice(game) * rules[game.gameType].multiplier })}</Typography>}
                { getRoleBChoice(game)!== null && getRoleBChoice(game) === 0 && <Typography paragraph={true}>{t("playroom.rules."+game.gameType+".decision.greedy.mistrust")}</Typography>}
                </>
             }
                { // if opponent has not made a choice yet - show waiting
                getOpponentChoice(game, getPlayer(game, auth.user.uid)) === null && <>
                        <Typography paragraph={true}><Trans i18nKey={"playroom.rules."+game.gameType+".decision.next"}></Trans><CircularProgress size={32} /></Typography>
                        <Button
                                    variant="contained"
                                    size="medium"
                                    color="secondary"
                                    onClick={() => exit()}
                                >
                            <Trans i18nKey="playroom.exit"></Trans>
                        </Button> 
                </>}
                
                { // if there is a choice sill to be made - show choice maker
                (getChoice(game, getPlayer(game, auth.user.uid)) === null)  && <>
                    <Typography paragraph={true}>{t("playroom.rules."+game.gameType+".decision."+getRole(game, auth.user.uid), {amount: getRoleAChoice(game), multiplied:getRoleAChoice(game) * rules[game.gameType].multiplier })}</Typography>
                    <Typography paragraph={true}><Trans i18nKey={"playroom.rules."+game.gameType+".decision.prompt"}></Trans></Typography>
                    <ChoiceMaker game={game} uid={auth.user.uid} choice={choice} handleChoiceChange={handleChoiceChange} submitChoice={submitChoice}></ChoiceMaker>
                </> }

            </> 
            )}

            {// State 3 and further - round over - resolutions 
            (gameStatus !== "loading" && !gamesAreEmpty && game.state > 2) && (
                <>
                    { // resolution for role A
                    getRole(game, auth.user.uid) === roleA && 
                        <>
                            { getRoleAChoice(game) > 0 && 
                            <>
                                <Typography paragraph={true}>{t("playroom.rules."+game.gameType+".decision.sent", {amount: getRoleAChoice(game), multiplied: getRoleAChoice(game) * rules[game.gameType].multiplier })}</Typography>
                                { getRoleBChoice(game) > 0  && <Typography paragraph={true}>{t("playroom.rules."+game.gameType+".decision.trustee", {amount: getRoleBChoice(game), kept: getRoleAChoice(game) * rules[game.gameType].multiplier -getRoleBChoice(game) })}</Typography>}
                                { getRoleBChoice(game) === 0  && <Typography paragraph={true}><Trans i18nKey={"playroom.rules."+game.gameType+".decision.greedy.noreturn"}></Trans></Typography>}
                                 
                            </>
                            }

                            { getRoleAChoice(game) === 0 && <>
                                    { rules[game.gameType].required === 1 && <><Typography paragraph={true}>{t("playroom.rules."+game.gameType+".decision.greedy.mistrust")}</Typography>  </>}
                                    { rules[game.gameType].required === 2 && <>
                                        { getRoleBChoice(game) === 0 && <>
                                            <Typography paragraph={true}>{t("playroom.rules."+game.gameType+".decision.greedy.mistrust")}</Typography> 
                                            <Typography paragraph={true}><Trans i18nKey={"playroom.rules."+game.gameType+".decision.greedy.reciprocal"}></Trans></Typography>
                                        </>}
                                        { getRoleBChoice(game) === 1 && <Typography paragraph={true}><Trans i18nKey={"playroom.rules."+game.gameType+".decision.greedy.abuse"}></Trans></Typography>}
                                    </>}
                                </>
                            } 
                           
                            {  // when roleA have not submitted their choice and roleB exited
                            (getRoleAChoice(game) === null && getRoleBChoice(game) === null) && <Typography paragraph={true}><Trans i18nKey={"playroom.rules."+game.gameType+".escaped.1"}></Trans></Typography>}           
                        </>
                    }

                    { // resolution for role B
                    getRole(game, auth.user.uid) === roleB && 
                        <> {getRoleAChoice(game) > 0 && 
                             <><Typography paragraph={true}>{t("playroom.rules."+game.gameType+".decision."+roleB, {amount: getRoleAChoice(game), multiplied:getRoleAChoice(game) * rules[game.gameType].multiplier })}</Typography>
                                { getRoleBChoice(game) > 0 && <Typography paragraph={true}>{t("playroom.rules."+game.gameType+".decision.returned", {amount: getRoleBChoice(game), kept: getRoleAChoice(game) * rules[game.gameType].multiplier - getRoleBChoice(game) })}</Typography>}
                                { getRoleBChoice(game) === 0 && <Typography paragraph={true}><Trans i18nKey={"playroom.rules."+game.gameType+".decision.greedy.abuse"}></Trans></Typography>}
                                { getRoleBChoice(game) === null && <Typography paragraph={true}><Trans i18nKey={"playroom.rules."+game.gameType+".escaped.0"}></Trans></Typography>}
                            </>
                            }
                            {getRoleAChoice(game) === 0 && <>
                                { rules[game.gameType].required === 1 && <><Typography paragraph={true}><Trans i18nKey={"playroom.rules."+game.gameType+".decision.greedy.0"}></Trans></Typography> </>}
                                { rules[game.gameType].required === 2 && <>
                                        { getRoleBChoice(game) === 0 && <>
                                         <Typography paragraph={true}>{t("playroom.rules."+game.gameType+".decision.greedy.mistrust")}</Typography> 
                                         <Typography paragraph={true}><Trans i18nKey={"playroom.rules."+game.gameType+".decision.greedy.reciprocal"}></Trans></Typography>
                                        </>}
                                        { getRoleBChoice(game) === 1 && <Typography paragraph={true}><Trans i18nKey={"playroom.rules."+game.gameType+".decision.greedy.noreturn"}></Trans></Typography>}
                                </>}
                            </>
                            }
                            {getRoleAChoice(game) === null && <Typography paragraph={true}><Trans i18nKey={"playroom.rules."+game.gameType+".escaped.0"}></Trans></Typography>}
                        </>
                    }
    
                   
                   <AfterParty uid={auth.user.uid} game={game} replay={replay} exit={exit}></AfterParty>
                </>
            )}
                
            {gameError && (
                <Box mb={3}>
                <Alert severity="error">{gameError.message}</Alert>
                </Box>
            )}

                </CardContent>
            </Card>
       
        </Grid>

        <Grid item={true} xs={12} md={12}>
           <Card>
              <CardContent className={classes.cardContent}>
                 <GameRules type={(game && game.gameType) || 0 }></GameRules>
              </CardContent>
            </Card>
        </Grid>
    </Grid>
    
       
    </>);
}

export default TrustGame;