import React, { useEffect, useRef, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { useAuth } from "../util/auth";
import { Trans, useTranslation} from 'react-i18next';
import { formatPhoneNumberIntl } from "react-phone-number-input";

const useStyles = makeStyles((theme) => ({
  content: {
    paddingBottom: 24,
  },
  rightButton: {
    marginLeft: 'auto',
    marginRight: -12,
  },
}));

function EditInviteModal(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const auth = useAuth();

  const outgoing = props.invite.initiator.id === auth.user.id && props.invite.status === 0;
  const incoming = props.invite.invitee.id === auth.user.id && props.invite.status === 0;
  
  const actionNegative = () => {
      incoming ? props.onDone(2) : props.onDone(3);
  }

  const actionPositive = () => {
      incoming ? props.onDone(5) : props.onDone(0);
  }

  const twentyFourHoursAgo = new Date(Date.now() - 24 * 60 * 60 * 1000);
  const lastUpdatedOn = props.invite.updatedOn.toDate();
  const toWaitMili = lastUpdatedOn - twentyFourHoursAgo;

  const mountedRef = useRef(true);

  const [timer, setTimer] = useState(Math.floor(toWaitMili / 1000 ) || 0)

  useEffect(() => {
      if (timer <= 0) return; 
          setTimeout(() => {
            if (mountedRef.current) setTimer(timer - 1); 
          }, 1000);
  }, [timer])

  const cleanUpAndClose = () => {
    mountedRef.current = false;
    props.onClose();
  }

  return (
    <Dialog open={true} onClose={cleanUpAndClose}>
      <DialogTitle>
        {outgoing && <Typography variant="body1"><Trans i18nKey="dashboard.invite.introsent"></Trans>{props.invite.invitee.name} ({formatPhoneNumberIntl(props.invite.invitee.phoneNumber)})</Typography>}
        {incoming && <Typography variant="body1"><Trans i18nKey="dashboard.invite.introreceived"></Trans>{props.invite.initiator.name} ({formatPhoneNumberIntl(props.invite.initiator.phoneNumber)})</Typography>}
      </DialogTitle>
      <DialogContent className={classes.content}>
          <Grid container={true} spacing={3} >
          <Grid item={true} xs={12}><Trans i18nKey="dashboard.invite.actionPrompt"></Trans></Grid>
            <Grid item={true} xs={12}>
            </Grid>
            <Grid item={true} xs={12}>
              <Grid container justifyContent="space-between"><Button
                sx={{ textAlign: "right" }}
                variant="contained"
                color="primary"
                size="large"
                type="button"
                onClick={actionPositive}
                disabled={outgoing && props.invite.invitee.id === "noIDyet" && lastUpdatedOn > twentyFourHoursAgo }
              >
               <span>{incoming && <Trans i18nKey="dashboard.invite.accept"></Trans> }{outgoing && <> { (props.invite.invitee.id === "noIDyet" && lastUpdatedOn > twentyFourHoursAgo) ? t("dashboard.invite.wait") + String(Math.floor(timer/3600)).padStart(2, "0") + ":" + String(Math.floor(timer%3600/60)).padStart(2,"0")+":"+String(timer%60).padStart(2,"0") : t("dashboard.invite.remind")}</>}</span>
              </Button><Button
                sx={{ align: "flex-end"}}
                variant="contained"
                color="secondary"
                size="large"
                type="button"
                onClick={actionNegative}
              >
              <span>{incoming && <Trans i18nKey="dashboard.invite.reject"></Trans>} { outgoing && <Trans i18nKey="dashboard.invite.rescind"></Trans>}</span>
              </Button></Grid>
              
            
            </Grid>
          </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default EditInviteModal;
