import React, { useEffect } from "react";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Section from "./Section";
import IdeasNav from "./IdeasNav";
import { useAuth } from "./../util/auth";
import { useNavigate } from "../util/router";

function IdeasSection(props) {

  const auth = useAuth();
  const navigate = useNavigate();

  const validSections = {
    mechanics: true,
    experience: true,
  };

  const section = validSections[props.section] ? props.section : "mechanics";

  const getSectionBoard = (section) => {
    switch (section) {
      case "mechanics" : return '0e4db71e-5273-9d1b-ffa6-5d80542f7c0b';
      case "experience": return '6d00ce1c-877a-1465-0f2b-c6394cb70faa';
      default: return '0e4db71e-5273-9d1b-ffa6-5d80542f7c0b';
    }
  }

  const BoardToken = getSectionBoard(section);

  useEffect(() => {
    // (re)render widget in iframe when section is valid and 
    // auth state is defined (i.e. not null)
        if (validSections[props.section] && auth.user !== null) {
          // eslint-disable-next-line no-undef
          Canny('render', {
            boardToken: BoardToken,
            basePath: "/ideas/" + section, 
            ssoToken: auth.user?.cannyJSWT, 
          });
        } else {
          navigate("/ideas/" + section, { replace: true });
        }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.user]);

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <IdeasNav activeKey={section} />
      <Box mt={5}>
        <Container maxWidth="lg">    
          <div key={auth.user?.uid} data-canny />
        </Container>
      </Box>
    </Section>
    
  );
}

export default IdeasSection;
