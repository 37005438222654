import React from 'react';
import IdeasSection from '../components/IdeasSection';
import Meta from "./../components/Meta";
import { useTranslation } from 'react-i18next';
import { useRouter } from "./../util/router";

function IdeasPage(props) {
    const router = useRouter();
    const { t } = useTranslation();
    
  return (
    <>
      <Meta title={t("meta.ideas.title")} />
      <IdeasSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        section={router.query.section}
        key={router.query.section}
      />
    </>
  );
}

export default IdeasPage;