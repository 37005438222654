import React from "react";
import PropTypes from "prop-types";


var DEGREE_IN_RADIANS = Math.PI / 180;

var polarToCartesian = function polarToCartesian(pathRadius, angle, radius) {
    var angleInRadians = (angle - 180) * DEGREE_IN_RADIANS;
    var x = radius + pathRadius * Math.cos(angleInRadians);
    var y = radius + pathRadius * Math.sin(angleInRadians);
    return x + ' ' + y;
  };

var getArc = function getArc(startAngle, endAngle, width, trackSize, direction) {
    var radius = width / 2,
        strokeWidth = trackSize;
    var pathRadius = radius - strokeWidth / 2;
    var start = polarToCartesian(pathRadius, startAngle, radius);
    var end = polarToCartesian(pathRadius, endAngle, radius);
    var largeArcFlag = startAngle <= 180 ? 0 : 1;
    return "M " + start + " A " + pathRadius + " " + pathRadius + " 0 " + largeArcFlag + " " + direction + " " + end;
  };

const Svg = ({
         width,
         strokeDasharrayNeg,
         strokeDashoffsetNeg,
         strokeDasharrayPos,
         strokeDashoffsetPos,
         progressColorNeg,
         progressColorPos,
         trackColor,
         progressSize,
         trackSize,
         svgFullPathNeg,
         svgFullPathPos,
         progressLineCap,
     }) => {

    const styles = ({
        svg: {
            position: 'relative',
            zIndex: 2
        },

        RotationFill:{
            transform: "rotate(-70deg)",
            transformOrigin: 'center center'
          },
          RotationNeg:{
            transform: "rotate(-70deg)",
            transformOrigin: 'center center'
          },
          RotationPos:{
            transform: "rotate(90deg)",
            transformOrigin: 'center center'
          }
    });

    return (
        <svg
            width={`${width}px`}
            height={`${width}px`}
            viewBox={`0 0 ${width} ${width}`}
            overflow="visible"
            style={styles.svg}
        >
            <path
                style={styles.RotationFill}
                strokeWidth={progressSize}
                strokeLinecap={progressLineCap !== 'round' ? 'butt' : 'round'}
                fill="none"
                stroke={trackColor}
                d={getArc(320,0, width, trackSize, 0)}/>
            <path
                style={styles.RotationNeg}
                ref={svgFullPathNeg}
                strokeDasharray={strokeDasharrayNeg}
                strokeDashoffset={strokeDashoffsetNeg}
                strokeWidth={progressSize}
                strokeLinecap={progressLineCap !== 'round' ? 'butt' : 'round'}
                fill="none"
                stroke={progressColorNeg}
                d={getArc(160, 0, width, trackSize, 0)}/>
            <path
                style={styles.RotationPos}
                ref={svgFullPathPos}
                strokeDasharray={strokeDasharrayPos}
                strokeDashoffset={strokeDashoffsetPos}
                strokeWidth={progressSize}
                strokeLinecap={progressLineCap !== 'round' ? 'butt' : 'round'}
                fill="none"
                stroke={progressColorPos}
                d={getArc(0, 160, width, trackSize, 1)}/>
        </svg>
    );
};

Svg.propTypes = {
    width: PropTypes.number,
    svgFullPathPos: PropTypes.object,
    svgFullPathNeg: PropTypes.object,
    strokeDasharrayPos: PropTypes.number,
    strokeDashoffsetPos: PropTypes.number,
    strokeDasharrayNeg: PropTypes.number,
    strokeDashoffsetNeg: PropTypes.number,
    progressColorNeg: PropTypes.string,
    progressColorPos: PropTypes.string,
    progressLineCap: PropTypes.string,
    progressSize: PropTypes.number,
    trackColor: PropTypes.string,
    trackSize: PropTypes.number
};

export default Svg;
