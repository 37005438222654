import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import BalanceHistory from "./BalanceHistory";

function TransactionsModal(props) {

  return (
    <Dialog open={true} onClose={props.onClose} fullWidth={true}>
      <DialogTitle>
        <Typography variant="body1">{props.title}</Typography>
      </DialogTitle>
      <DialogContent >
        <BalanceHistory parties={props.parties}></BalanceHistory>
      </DialogContent>
    </Dialog>
  );
}

export default TransactionsModal;
