import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import AuthSocial from "./AuthSocial";
import { useRouter } from "./../util/router";
import AuthPhoneForm from "./AuthPhoneForm";
import { getMessaging, getToken, isSupported } from "firebase/messaging";
import { setMessagingTokenForTrustor } from "./../util/db";

function Auth(props) {
  const router = useRouter();
  const [formAlert, setFormAlert] = useState(null);

  const handleAuth = (user) => {
    requestPermission(user);
    router.navigate(props.afterAuthPath);
  };

  const getMessagingToken = (user) => {
    // Get registration token. Initially this makes a network call, once retrieved
    // subsequent calls to getToken will return from cache.
    const messaging = getMessaging();
    getToken(messaging, { vapidKey: process.env.REACT_APP_FIREBASE_MESSAGING_KEY }).then((currentToken) => {
      if (currentToken) {
        setMessagingTokenForTrustor(user.uid, currentToken).then(() => {
      }).catch( (error) => {
        console.log(error);
      });
      } else {
        // Show permission request UI
        requestPermission(user);
        // ...
      }
    }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
      // ...
    });
  }
  
  const requestPermission = async (user) => {
    const isSupportedBrowser = await isSupported();
    if (isSupportedBrowser) {
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
            getMessagingToken(user); 
        }
      });
    }
  }

  const handleFormAlert = (data) => {
    setFormAlert(data);
  };

  return (
    <>
      {formAlert && (
        <Box mb={3}>
          <Alert severity={formAlert.type}>{formAlert.message}</Alert>
        </Box>
      )}

      <AuthPhoneForm 
        onAuth={handleAuth}
      />

      {["signup", "signin"].includes(props.type) && (
        <>
          {props.providers && props.providers.length && (
            <>
              <Box textAlign="center" fontSize={12} my={2}>
                OR
              </Box>
              <AuthSocial
                buttonAction={props.buttonAction}
                providers={props.providers}
                showLastUsed={true}
                onAuth={handleAuth}
                onError={(message) => {
                  handleFormAlert({
                    type: "error",
                    message: message,
                  });
                }}
              />
            </>
          )}
        </>
      )}
    </>
  );
}

export default Auth;
