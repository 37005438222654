import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Card, CardContent, Grid, Typography } from "@material-ui/core";
import { Trans } from "react-i18next";
import { rules, getPlayer, getRoundsPlayed } from "./utils";
import { floatString } from "../../util/util";


const useStyles = makeStyles((theme) => ({
    cardContent: {
      padding: theme.spacing(3),
    }
  }));

function ScoreBoard(props) {
    
    const classes = useStyles();  

    const game = props.game;
    const player =  getPlayer(game, props.uid);
    const rounds = getRoundsPlayed(game);
    const maximum = rules[game.gameType].maximum;
    const balance = rules[game.gameType].getBalance(game, player);

    return(
    <Grid item={true} xs={12} md={12} >
        <Card>
        <CardContent className={classes.cardContent} >
        <Grid container={true} spacing={4}> 
                <Grid item={true} xs={6} md={3} > <Button
                    variant="outlined"
                    color="default"
                    size="large"
                    type="button" 
                ><Typography variant="body1"><Trans i18nKey="playroom.rounds"></Trans><b>{ rounds }</b></Typography> 
                </Button>  </Grid>
                <Grid item={true} xs={6} md={3} > <Button
                    variant="outlined"
                    color="default"
                    size="large"
                    type="button"
                ><Typography variant="body1"><Trans i18nKey="playroom.balance"></Trans> <b>{ floatString(balance,2) }</b></Typography> 
                </Button>
                </Grid>
                <Grid item={true} xs={6} md={3} > <Button
                    variant="outlined"
                    color="default"
                    size="large"
                    type="button" 
                ><Typography variant="body1"><Trans i18nKey="playroom.maxutility"></Trans><b>{ floatString(maximum * rounds,2)  }</b></Typography> 
                </Button>  </Grid>
                <Grid item={true} xs={6} md={3} >  <Button
                    variant="outlined"
                    color="default"
                    size="large"
                    type="button" 
                ><Typography variant="body1"><Trans i18nKey="playroom.utilityrate"></Trans><b>{ floatString(balance / (maximum * rounds),2) || 0 }</b></Typography> 
                </Button>  </Grid>
                </Grid>
            </CardContent>
        </Card>
    </Grid>
    );

}

export default ScoreBoard;