import React from "react";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import LinkMui from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import DashboardConnections from "./DashboardConnections";
import { Link } from "./../util/router";
import { useAuth } from "./../util/auth";
import Notifications from "./Notifications";
import { useCounters } from "./../util/rtdb";
import { Trans } from 'react-i18next';
import { formatPhoneNumberIntl } from "react-phone-number-input";

const useStyles = makeStyles((theme) => ({
  cardContent: {
    padding: theme.spacing(3),
  },
  ideanote: {
    border: "none",
    width: "560px",
    height: "700px",
    boxShadow: "0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12)",
    borderRadius: "5px",
  }
}));

function DashboardSection(props) {
  const classes = useStyles();

  const auth = useAuth();

  const counters = useCounters();

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />
        
         
        { /*router.query.paid && auth.user.planIsActive && (
          <Box mx="auto" mb={4} maxWidth={400}>
            <Alert severity="success">
              You are now subscribed to the {auth.user.planId} plan
              <span
                role="img"
                aria-label="party"
                style={{ marginLeft: "10px" }}
              >
                🥳
              </span>
            </Alert>
          </Box>
        ) */}

        <Grid container={true} spacing={4}>
          <Grid item={true} xs={12} md={6}>
            <DashboardConnections counters={counters} {...props}/>
          </Grid>
          <Grid item={true} xs={12} md={6}>
            <Notifications limit={5}/>
          </Grid>
         {/*<Grid item={true} xs={12} md={6}><Paper ><iframe
                allowFullScreen
                name="idea-widget-frame"
                title="Idea Collection Widget"
                className={classes.ideanote}
                frameBorder="0"
                src="https://idea-widget.ideanote.io?config=e8198d7ad2"
                ></iframe></Paper>
          </Grid>*/} 
          
          <Grid item={true} xs={12} md={12}>
            <Card>
              <CardContent className={classes.cardContent}>
                <Box>
                  <Typography variant="h6" paragraph={true}>
                    <strong><Trans i18nKey="dashboard.extra.whatisthis"></Trans></strong>
                  </Typography>
                  <Typography paragraph={true}>
                  <Trans i18nKey="dashboard.extra.part1"></Trans>
                  </Typography>
                  <Typography paragraph={true}>
                  <Trans i18nKey="dashboard.extra.part2"></Trans>
                  </Typography>
                  <Typography paragraph={true}>
                  <Trans i18nKey="dashboard.extra.part3"></Trans>
                  </Typography>
                  <Typography paragraph={true}>
                  <Trans i18nKey="dashboard.extra.part4"></Trans>
                  </Typography>
                  <Box mt={3}>
                    <Typography variant="h6" paragraph={true}>
                      <strong><Trans i18nKey="dashboard.extra.extraInfo"></Trans></strong>
                    </Typography>
                    <Typography component="div">
                      <div>
                      <Trans i18nKey="dashboard.extra.signedin"></Trans><strong>{formatPhoneNumberIntl(auth.user.phoneNumber)}</strong>.
                      </div>

                      {auth.user.stripeSubscriptionId && (
                        <>
                          <div>
                            You are subscribed to the{" "}
                            <strong>{auth.user.planId} plan</strong>.
                          </div>
                          <div>
                            Your plan status is{" "}
                            <strong>
                              {auth.user.stripeSubscriptionStatus}
                            </strong>
                            .
                          </div>
                        </>
                      )}

                      <div>
                      <Trans i18nKey="dashboard.extra.change"></Trans>{` `}
                        {auth.user.stripeSubscriptionId && <>and plan{` `}</>}
                        <Trans i18nKey="dashboard.extra.in"></Trans>{` `}
                        <LinkMui component={Link} to="/settings/general">
                          <strong><Trans i18nKey="dashboard.extra.settings"></Trans></strong>
                        </LinkMui>
                        .
                      </div>

                      {/*!auth.user.stripeSubscriptionId && (
                        <div>
                          You can signup for a plan in{" "}
                          <LinkMui component={Link} to="/pricing">
                            <strong>pricing</strong>
                          </LinkMui>
                          .
                        </div>
                      )*/}
                    </Typography>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          
        </Grid>
      </Container>
    </Section>
  );
}

export default DashboardSection;
