import { IconButton, Typography } from "@material-ui/core";
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAlt';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';
import FavoriteRoundedIcon from '@material-ui/icons/FavoriteRounded';
import React, { useState } from "react";
import { Trans } from "react-i18next";
import { updateFeedback } from "./../../util/db";


function SmileyFeedback(props) {


 const [isFeedbackSelected, setFeedbackSelected] = useState(false);
 
  const onChangeEmoji = function(value) {
    setFeedbackSelected(true);
    updateFeedback(props.playroom, props.player, value);
  }

 return(

    <div >
      { isFeedbackSelected === true && <><Typography paragraph={true}><FavoriteRoundedIcon /><Trans i18nKey="playroom.resolution.thankyou"></Trans></Typography></>}
      { isFeedbackSelected !== true && <><Typography paragraph={true}><Trans i18nKey="playroom.resolution.happyornot"></Trans></Typography>
      <ul style={{ display: "flex", listStyleType: "none", justifyContent: "space-around" }}>
            <li><IconButton 
                    edge="end"
                    size="medium"
                    aria-label="very unhappy"
                    onClick={() => { onChangeEmoji(-2);}}
                  >
                    <SentimentVeryDissatisfiedIcon fontSize="large"/>
                  </IconButton>
            </li>
            <li><IconButton
                    edge="end"
                    size="medium"
                    aria-label="unhappy"
                    onClick={() => { onChangeEmoji(-1);}}
                  >
                    <SentimentDissatisfiedIcon fontSize="large"/>
                  </IconButton>
            </li>
            <li><IconButton
                    edge="end"
                    size="medium"
                    aria-label="neutral"
                    onClick={() => { onChangeEmoji(0);}}
                  >
                    <SentimentSatisfiedIcon fontSize="large"/>
                  </IconButton>
            </li>
            <li><IconButton
                    edge="end"
                    size="medium"
                    aria-label="happy"
                    onClick={() => { onChangeEmoji(1);}}
                  >
                    <SentimentSatisfiedAltIcon fontSize="large"/>
                  </IconButton>
            </li>
            <li><IconButton
                    edge="end"
                    size="medium"
                    aria-label="very happy"
                    onClick={() => { onChangeEmoji(2);}}
                  >
                    <SentimentVerySatisfiedIcon fontSize="large"/>
                  </IconButton>
            </li>
        </ul>
        
       
        </>}
    </div>

 );

}

export default SmileyFeedback;