import React, { useState, useEffect } from "react";
import CookieConsent from "react-cookie-consent";
import AppBar from "@material-ui/core/AppBar";
import Container from "@material-ui/core/Container";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";
import NightsStayIcon from "@material-ui/icons/NightsStay";
import WbSunnyIcon from "@material-ui/icons/WbSunny";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import LinkMui from "@material-ui/core/Link";
import Section from "./Section";
import { Link } from "./../util/router";
import { useAuth } from "./../util/auth";
import { useDarkMode } from "./../util/theme";
import toast, { Toaster } from 'react-hot-toast';
import { onMessageListener } from "../util/firebase";
import { isSupported, getMessaging, getToken,  } from "firebase/messaging";
import { Trans, useTranslation} from 'react-i18next';
import { localize } from "../util/i18n";
import { Select } from "@material-ui/core";
import { setMessagingTokenForTrustor } from "./../util/db";


const useStyles = makeStyles((theme) => ({
  logo: {
    height: 64,
    marginRight: theme.spacing(2),
  },
  drawerList: {
    width: 250,
  },
  spacer: {
    flexGrow: 1,
  },
}));

const lngs = {
  en: { nativeName: 'English' },
  uk: { nativeName: 'Рідна' }
};

function Navbar(props) {
  const classes = useStyles();

  const auth = useAuth();
  const darkMode = useDarkMode();
  const { t, i18n } = useTranslation();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [menuState, setMenuState] = useState(null);
  const [notification, setNotification] = useState({title: '', body: ''});
  const [language, setLanguage] = useState(i18n.resolvedLanguage);

  const notify = () =>  toast(<ToastDisplay/>);
  function ToastDisplay() {
    return (
      <div>
        <p><b>{notification?.title}</b></p>
        <p>{notification?.body}</p>
      </div>
    );
  };

  const getMessagingToken = (user) => {
    // Get registration token. Initially this makes a network call, once retrieved
    // subsequent calls to getToken will return from cache.
    const messaging = getMessaging();
    getToken(messaging, { vapidKey: process.env.REACT_APP_FIREBASE_MESSAGING_KEY }).then((currentToken) => {
      if (currentToken) {
        console.log("Got messaging token: ", currentToken);
        setMessagingTokenForTrustor(user.uid, currentToken).then(() => {
      }).catch( (error) => {
        console.log(error);
      });
      } else {
        // Show permission request UI
        requestPermission(user);
        // ...
      }
    }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
      // ...
    });
  }

  const requestPermission = async (user) => {
    const isSupportedBrowser = await isSupported();
    if (isSupportedBrowser) {
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          console.log("Getting messaging token");
            getMessagingToken(user); 
        }
      });
    }
  }

  useEffect(() => {
    if (notification?.title ){
     notify()
    }
  }, [notification])

  isSupported().then( (yes) => {
    if (yes) {
      onMessageListener()
      .then((payload) => {
        setNotification({title: localize(payload?.data?.title), body: localize(payload?.data?.body)});     
      })
      .catch((err) => console.log('failed: ', err));
    }
  });

  // Use inverted logo if specified
  // and we are in dark mode
  const logo =
    props.logoInverted && darkMode.value ? props.logoInverted : props.logo;

  const handleOpenMenu = (event, id) => {
    // Store clicked element (to anchor the menu to)
    // and the menu id so we can tell which menu is open.
    setMenuState({ anchor: event.currentTarget, id });
  };

  const handleCloseMenu = () => {
    setMenuState(null);
  };

  if (auth.user) {
    // eslint-disable-next-line no-undef
    $crisp.push(["set", "user:nickname", auth.user.name]);
    // eslint-disable-next-line no-undef
    if (auth.user.email && auth.user.email !== "") $crisp.push(["set", "user:email", auth.user.email]);
  }

  return (
    <Section bgColor={props.color} size="auto">
      <CookieConsent location="top" 
                    buttonText={t("cookies.button")}
                    overlay
                    onAccept={() => requestPermission()}
                    style={{ minHeight: "70px" }}
                    buttonStyle={{ textAlign: "middle", color: "white", background: "#3f51b5", boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)", borderRadius: "4px", padding: "8px 22px" }}
                   >
                <Trans i18nKey="cookies.part1"></Trans><LinkMui component={Link} to="/legal/privacy-policy#cookies"><Trans i18nKey="legal.privacy"></Trans></LinkMui>.
              </CookieConsent>
              <Toaster/>
      <AppBar position="static" color="transparent" elevation={0}>
        <Container disableGutters={true}>
          <Toolbar>
            <Link to="/">
              <img src={logo} alt="Logo" className={classes.logo} />
            </Link>
            <div className={classes.spacer} />
            <Hidden mdUp={true} implementation="css">
              <IconButton
                onClick={() => {
                  setDrawerOpen(true);
                }}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
            </Hidden>
            <Hidden smDown={true} implementation="css">
              <Button component={Link} to="/statistics" color="inherit">
                <Trans i18nKey="menu.stats"></Trans>
              </Button>
              <Button component={Link} to="/leaders" color="inherit">
              <Trans i18nKey="menu.leaders"></Trans>
              </Button>
              <Button component={Link} to="/faq" color="inherit">
              <Trans i18nKey="menu.faq"></Trans>
              </Button>
              <Button component={Link} to="/ideas/mechanics" color="inherit">
              <Trans i18nKey="menu.ideas"></Trans>
              </Button>
              
              {!auth.user && (
                <>
                  <Box component="span" ml={1}>
                    <Button
                      component={Link}
                      to="/auth/signin"
                      variant="contained"
                      color="primary"
                    >
                      <Trans i18nKey="menu.signin"></Trans>
                    </Button>
                  </Box>
                </>
              )}

              {auth.user && (
                <>
                  <Button
                    color="inherit"
                    aria-label="Account"
                    aria-controls="account-menu"
                    aria-haspopup="true"
                    onClick={(event) => {
                      handleOpenMenu(event, "account-menu");
                    }}
                  >
                    <Trans i18nKey="menu.account"></Trans>
                    <ExpandMoreIcon className={classes.buttonIcon} />
                  </Button>
                  <Menu
                    id="account-menu"
                    open={
                      menuState && menuState.id === "account-menu"
                        ? true
                        : false
                    }
                    anchorEl={menuState && menuState.anchor}
                    getContentAnchorEl={undefined}
                    onClick={handleCloseMenu}
                    onClose={handleCloseMenu}
                    keepMounted={true}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    <MenuItem component={Link} to="/dashboard">
                    <Trans i18nKey="menu.dashboard"></Trans>
                    </MenuItem>
                    <MenuItem component={Link} to="/playroom">
                    <Trans i18nKey="menu.playroom"></Trans>
                    </MenuItem>
                    <MenuItem component={Link} to="/notifications">
                    <Trans i18nKey="menu.notifications"></Trans>
                    </MenuItem>
                    <MenuItem component={Link} to="/settings/general">
                    <Trans i18nKey="menu.settings"></Trans>
                    </MenuItem>
                    <Divider />
                    <MenuItem
                      onClick={(event) => {
                        auth.signout();
                      }}
                    >
                      <Trans i18nKey="menu.signout"></Trans>
                    </MenuItem>
                  </Menu>
                </>
              )}
              
              <IconButton
                color="inherit"
                onClick={darkMode.toggle}
                style={{ opacity: 0.6 }}
              >
                {darkMode.value && <NightsStayIcon />}

                {!darkMode.value && <WbSunnyIcon />}
              </IconButton>
              <Select
                id="lng"
                value={language}
                onChange={(e) => {if (language !== e.target.value) { setLanguage(e.target.value); i18n.changeLanguage(e.target.value);}}}
                inputProps={{ 'aria-label': 'Language' }}
              >
                {Object.keys(lngs).map((lng) => (<MenuItem key={lng} value={lng}>{lngs[lng].nativeName}</MenuItem>))}
              </Select>
                    
            </Hidden>
          </Toolbar>
        </Container>
      </AppBar>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        <List
          className={classes.drawerList}
          onClick={() => setDrawerOpen(false)}
        >

          {!auth.user && (
            <>
              <ListItem>
                <Button
                  component={Link}
                  to="/auth/signin"
                  variant="contained"
                  color="primary"
                >
                  <Trans i18nKey="menu.signin"></Trans>
                </Button>
              </ListItem>
            </>
          )}

          {auth.user && (
            <>
              <ListItem component={Link} to="/dashboard" button={true}>
                <ListItemText><Trans i18nKey="menu.dashboard"></Trans></ListItemText>
              </ListItem>
              <ListItem component={Link} to="/playroom" button={true}>
                <ListItemText><Trans i18nKey="menu.playroom"></Trans></ListItemText>
              </ListItem>
              <ListItem component={Link} to="/notifications" button={true}>
                <ListItemText><Trans i18nKey="menu.notifications"></Trans></ListItemText>
              </ListItem>
      </>
          )}
          <ListItem component={Link} to="/statistics" button={true}>
            <ListItemText><Trans i18nKey="menu.statistics"></Trans></ListItemText>
          </ListItem>
          <ListItem component={Link} to="/leaders" button={true}>
            <ListItemText><Trans i18nKey="menu.leaders"></Trans></ListItemText>
          </ListItem>
          
          
          {auth.user && (
            <>
            <ListItem component={Link} to="/settings/general" button={true}>
            <ListItemText><Trans i18nKey="menu.settings"></Trans></ListItemText>
          </ListItem>
              <Divider />
              <ListItem
                button={true}
                onClick={(event) => {
                  auth.signout();
                }}
              >
                <ListItemText><Trans i18nKey="menu.signout"></Trans></ListItemText>
              </ListItem>
            </>
          )}
          <ListItem component={Link} to="/faq" button={true}>
            <ListItemText><Trans i18nKey="menu.faq"></Trans></ListItemText>
          </ListItem>
          <ListItem component={Link} to="/ideas/mechanics" button={true}>
            <ListItemText><Trans i18nKey="menu.ideas"></Trans></ListItemText>
          </ListItem>
          <ListItem>
            <IconButton
              color="inherit"
              aria-label="dark-mode-switch"
              onClick={darkMode.toggle}
              style={{ opacity: 0.6 }}
            >
              {darkMode.value && <NightsStayIcon />}

              {!darkMode.value && <WbSunnyIcon />}
            </IconButton>
            <Select
                id="lng"
                value={language}
                onChange={(e) => {if (language !== e.target.value) { setLanguage(e.target.value); i18n.changeLanguage(e.target.value);}}}
                inputProps={{ 'aria-label': 'Language' }}
              >
                {Object.keys(lngs).map((lng) => (<MenuItem key={lng} value={lng}>{lngs[lng].nativeName}</MenuItem>))}
              </Select>
 
          </ListItem>

            
        </List>
      </Drawer>
    </Section>
   
  );
}

export default Navbar;
