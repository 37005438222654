// Game states: 0 - looking for match, 1 - started (player 1 input awaited), 2 - player 2 input awaited, 3 - results distributed, exit/continue inputs awaited, 4 - role 0 wants to continue, 5 - role 1 wants to continue, 6 - other player exited, 8 -  moved to next round, 7 - terminated
export const state = {
    initial: 0, // State 0 - Play session created by playerA but playerB has not joined yet ... waiting
    started: 1, // State 1 - PlayerB has joined, role assigned, endowment displayed if any, playerA prompted to make a choice, Control to make a choice ( Buttons or Slider ), Submit button (in future maybe timer for time-limited plays)
    pending: 2, // State 2 - PlayerA submitted their choice, now it is playerB's turn. same as state 1 just for another role
    finished: 3, // State 3 - explains the resulting distribution, binary choice buttons to exit or repeat the game
    roleAcontinues: 4, // State 4 - roleA player expressed desire to continue, waiting for roleB player decision to play again or exit
    roleBcontinues: 5, // State 5 - roleB player expressed desire to continue, waiting for roleA player decision to play again or exit
    exited: 6, // State 6 - one of the players exited, session is over but another player still needs to exit the session for it to be terminated
    terminated: 7, // State 7 - game has been terminated either prematurely or in between rounds. Session is over.
    continued: 8 // State 8 - game has been continued into the next playroom
}

export const roleA = 0;
export const roleB = 1;

export const rules = [{},
    { 
        endowment: 0,
        maximum: 20,
        choice: "binary",
        required: 1,
        turns: "sequential",
        multiplier: 0,
        precision: 1,
        getBalance: function(game, player) {

            // until both choices are submitted or session is impossible to continue due to exit - return existing balance
            if (game.playerA.choice == null || game.playerB.choice == null || game.state > state.roleBcontinues) 
                return player === "A" ? game.playerA.balance : game.playerB.balance;
            // when both choices submitted and someoone wants to continue - calculate outcome 
            switch (player) {
                case "A" : 
                    switch (game.playerA.role) {
                        case roleA: return game.playerA.balance + getRoleAChoice(game) * (getRoleBChoice(game) === 0 ? -5 : 10 ); 
                        case roleB: return game.playerA.balance + getRoleAChoice(game) * (getRoleBChoice(game) === 0 ? 15 : 10 );
                        default: return game.playerA.balance;
                    }
                case "B" :
                    switch (game.playerB.role) {
                        case roleA: return game.playerB.balance + getRoleAChoice(game) * (getRoleBChoice(game) === 0 ? -5 : 10 ); 
                        case roleB: return game.playerB.balance + getRoleAChoice(game) * (getRoleBChoice(game) === 0 ? 15 : 10 );
                        default: return game.playerB.balance;
                    }
                default: return 0;
            }
        }
    },
    {
        endowment: 10,
        maximum: 20,
        choice: "integer",
        required: 1,
        turns: "sequential",
        multiplier: 2,
        precision: 1,
        getBalance: function(game, player) {
            if (game.state < 3 || game.state > 5 ) return player === "A" ? game.playerA.balance : game.playerB.balance;
            if (player === "A") return game.playerA.balance + (game.playerA.role === roleA ? game.endowment - getRoleAChoice(game) + getRoleBChoice(game) : getRoleAChoice(game) * rules[game.gameType].multiplier - getRoleBChoice(game));
            return game.playerB.balance + (game.playerB.role === roleA ? game.endowment - getRoleAChoice(game) + getRoleBChoice(game) : getRoleAChoice(game) * rules[game.gameType].multiplier  - getRoleBChoice(game));
        }
    },
    {
        endowment: 10.0,
        maximum: 20.0,
        choice: "float",
        required: 1,
        turns: "sequential",
        multiplier: 2,
        precision: 0.1,
        getBalance: function(game, player) {
            if (game.state < 3 || game.state > 5 ) return player === "A" ? game.playerA.balance : game.playerB.balance;
            if (player === "A") return game.playerA.balance + Math.round((game.playerA.role === roleA ? game.endowment - getRoleAChoice(game) + getRoleBChoice(game) : getRoleAChoice(game) * rules[game.gameType].multiplier - getRoleBChoice(game)) * 100) / 100;
            return game.playerB.balance + Math.round((game.playerB.role === roleA ? game.endowment - getRoleAChoice(game) + getRoleBChoice(game) : getRoleAChoice(game) * rules[game.gameType].multiplier  - getRoleBChoice(game)) * 100) / 100;
        }
    },
    { 
        endowment: 0,
        maximum: 18,
        choice: "binary",
        required: 2,
        turns: "concurrent",
        multiplier: 0,
        precision: 1,
        getBalance: function(game, player) {
            // until both choices are submitted or session is impossible to continue due to exit - return existing balance
            if (game.playerA.choice == null || game.playerB.choice == null || game.state > state.roleBcontinues) 
                return player === "A" ? game.playerA.balance : game.playerB.balance;
            // when both choices submitted and someoone wants to continue - calculate outcome 
            switch (getRoleAChoice(game) + getRoleBChoice(game)) { // 0 - cooperate, 1 - defect
                case 0: return (player === "A" ? game.playerA.balance : game.playerB.balance ) + 5; // both cooperate
                case 1: if (player === "A") return game.playerA.balance + (game.playerA.choice === 0 ? 10 : 0); // player A cooperates while B defects
                        if (player === "B") return game.playerB.balance + (game.playerB.choice === 0 ? 10 : 0); // player B cooperates while A defects
                        break;
                case 2: return (player === "A" ? game.playerA.balance : game.playerB.balance ) + 9; // both defect
                default: return 0;
            }
        }
    }
]

export const getPlayer = function(game, uid) {
    return game.playerA.id === uid ? "A" : "B";
}

export const getRole = function(game, uid) {
    return game.playerA.id === uid ? game.playerA.role : game.playerB.role;
}

export const getRoundsPlayed = function(game) {
   return  (game.state < 3 || game.state === 7) ? game.roundNumber - 1 :  game.roundNumber; // when game terminated before finishing (7) consider current round number as unfinished
}

export const getMaxUtility = function(game) {
    return   getRoundsPlayed(game) * rules[game.gameType].maximum; // when game terminated before finishing (7) consider current round number as unfinished
 }

export const getRoleAChoice = function(game) {
    return game.playerA.role === roleA ? game.playerA.choice : game.playerB.choice;
}

export const getRoleBChoice = function(game) {
    return game.playerA.role === roleA ? game.playerB.choice : game.playerA.choice;
}

export const getOpponentChoice = function(game, player) {
    return player === "A" ? game.playerB.choice : game.playerA.choice;
}

export const getChoice = function(game, player) {
    return player === "A" ? game.playerA.choice : game.playerB.choice;
}
