import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { Trans } from 'react-i18next';

function GameRules(props) {

    return(
        <Box>
                  <Typography variant="h6" paragraph={true}>
                    <strong><Trans i18nKey={"playroom.rules."+props.type+".headline"}></Trans></strong>
                  </Typography>
                  <Typography paragraph={true}>
                  <Trans i18nKey={"playroom.rules."+props.type+".part1"}></Trans>
                  </Typography>
                  <Typography paragraph={true}>
                  <Trans i18nKey={"playroom.rules."+props.type+".part2"}></Trans>
                  </Typography>
                  <Typography paragraph={true}>
                  <Trans i18nKey={"playroom.rules."+props.type+".part3"}></Trans>
                  </Typography>
                  <Typography paragraph={true}>
                  <Trans i18nKey={"playroom.rules."+props.type+".part4"}></Trans>
                  </Typography>
        </Box>
    );
}

export default GameRules;