import React from "react";
import LongContent from "./LongContent";

function LegalPrivacy(props) {
  return (
    <LongContent>
      <h1>TRUST.GAME by STC LABS - PRIVACY POLICY</h1>
 
  <p>Effective date: 07/06/2022</p>
  
       <h3>Table of Contents</h3>
       <ol>
       <li><a href="#introduction">INTRODUCTION</a></li>
       <li><a href="#definitions">DEFINITIONS</a></li>
       <li><a href="#typesofdata">TYPES OF DATA COLLECTED</a></li>
       <li><a href="#useofdata">USE OF DATA</a></li>
       <li><a href="#retention">RETENTION OF DATA</a></li>
       <li><a href="#transfer">TRANSFER OF DATA</a></li>
       <li><a href="#disclosure">DISCLOSURE OF DATA</a></li>
       <li><a href="#security">SECURITY OF DATA</a></li>
       <li><a href="#gdpr">YOUR DATA PROTECTION RIGHTS UNDER GENERAL DATA PROTECTION REGULATION (GDPR)</a></li>
       <li><a href="#caloppa">YOUR DATA PROTECTION RIGHTS UNDER THE CALIFORNIA PRIVACY PROTECTION ACT (CALOPPA)</a></li>
       <li><a href="#ccpa">YOUR DATA PROTECTION RIGHTS UNDER THE CALIFORNIA CONSUMER PRIVACY ACT (CCPA)</a></li>
       <li><a href="#california">NOTE TO CALIFORNIA USERS</a></li>
       <li><a href="#providers">SERVICE PROVIDERS</a></li>
       <li><a href="#analytics">ANALYTICS</a></li>
       <li><a href="#remarketing">BEHAVIORAL REMARKETING</a></li>
       <li><a href="#payments">PAYMENTS</a></li>
       <li><a href="#otherlinks">LINKS TO OTHER SITES</a></li>
       <li><a href="#children">CHILDREN'S PRIVACY</a></li>
       <li><a href="#changes">CHANGES TO THIS PRIVACY POLICY</a></li>
       <li><a href="#contactus">CONTACT US</a></li></ol>

 <h2 id="introduction">1.	Introduction</h2>
  
 <p><b>Welcome to Trust.Game</b></p>
  
 <p>An independent development studio, <b>STC Labs, (“us”, “we”, “our” and “STC Labs”)</b> operates the website <a href="https://trust.game" target="_blank" rel="noreferrer">https://trust.game</a> as well as the <b>Trust.Game</b> mobile application (hereinafter referred to as <b>“Service”</b>).
 </p> 
 <p>Everyone’s privacy is important to us. At STC Labs, we believe that privacy is a paramount human right and fundamental basis of any high functioning society. In fact, STC Labs was founded with privacy as one of its core values and a goal to develop all our products with privacy and safety in mind at all stages. That includes first and foremost your right to privacy.
 </p>
 <p>This Privacy Policy explains the personal data that STC Labs collects and processes, how it processes data, and for what purposes data is collected and processed. This Privacy Policy further describes our commitment to preserving the privacy and security of your personal data. This policy applies to all interactions that STC Labs has with you through your use of our products and services. Our Privacy Policy governs your visit to the Service, and explains how we collect, safeguard, and disclose if ever, information that results from your use of our Service.
 </p>
 <p>We use your data to provide and improve the Service. By using the Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, the terms used in this Privacy Policy have the same meanings as in our Terms of Service.
 </p>
 <p>Our Terms of Service <b>(“Terms”)</b> govern all use of our Service and together with the Privacy Policy constitute your agreement with us <b>(“Agreement”)</b>.
 </p>
 <h2 id="definitions">2.	Definitions</h2>
  
 <p><b>SERVICE</b> means the website <a href="https://trust.game" target="_blank" rel="noreferrer">https://trust.game</a> and <b>Trust.Game</b> mobile application operated by STC Labs.
 </p> 
 <p><b>PERSONAL DATA</b> means data about a living individual who can be identified from those data (or from those and other information either in our possession or likely to come into our possession).
 </p>
 <p><b>USAGE DATA</b> is data collected automatically either generated by the use of Service or from the Service infrastructure itself (for example, the duration of a page visit).
 </p>
 <p><b>COOKIES</b> are small pieces of information that websites request your browser to store on your computer or mobile device filesystem. The purpose of a cookie is to help a website keep track of your visits and activity and to re-identify your device or account during subsequent visits. 
 </p>
 <p><b>DATA CONTROLLER</b> means a natural or legal person who (either alone or jointly or in common with other persons) determines the purposes for which and the manner in which any personal data are, or are to be, processed. For the purpose of this Privacy Policy, we are a Data Controller of your data.
 </p>
 <p><b>DATA PROCESSORS (OR SERVICE PROVIDERS)</b> means any natural or legal person who processes the data on behalf of the Data Controller. We may use the services of various Service Providers in order to process your data more effectively.
 </p>
 <p><b>DATA SUBJECT</b> is any living individual who is the subject of Personal Data.
 </p>
 <p><b>THE USER</b> is the individual using our Service. The User corresponds to the Data Subject, who is the subject of Personal Data.
 </p>
 <h2 id="typesofdata">3.	Types of Data Collected</h2>
  
 <h3>Personal Data</h3>
 <p>While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you <b>(“Personal Data”)</b>. Personal Data may include, but is not limited to:
 </p>
 <ol type="a">  
<li>Email address
  </li>
  <li>First name and last name
 </li>
 <li>Cookies and Usage Data
 </li>
 <li>Credit Card information or other payment method information
 </li>
 <li>Home, Shipping or Billing Address
 </li>
 <li>Telephone Number
 </li></ol>
 <p> 
 We may use your Personal Data to contact you with newsletters, marketing or promotional materials and other information that may be of interest to you. You may opt-out of receiving any, or all, of these communications from us by following the unsubscribe link or by emailing at <a href="mailto:support@stclabs.dev?subject=RE:Unsubscribe">support@stclabs.dev</a>
 </p> 
 <h3>Usage Data</h3>
 <p>We may also collect information that your browser sends whenever you visit our Service or when you access the Service by or through a mobile device <b>(“Usage Data”)</b>.
 </p> <p>
 This Usage Data may include information such as your computer's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.
 </p> <p>
 When you access the Service with a mobile device, this Usage Data may include information such as the type of mobile device you use, your mobile device unique ID, the IP address of your mobile device, your mobile operating system, the type of mobile Internet browser you use, unique device identifiers and other diagnostic data.
 </p> 
 <h3 id="cookies">Tracking Cookies Data</h3>
 <p>We use cookies and similar tracking technologies to track the activity on our Service and we hold certain information.
 </p>  <p>
 Cookies are files with a small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Other tracking technologies are also used such as beacons, tags, and scripts to collect and track information and to improve and analyze our Service.
 </p> <p>
 You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.
 </p>
 <p>Examples of Cookies we use:</p>
 <ol type="a"> 
<li><b>Session Cookies:</b> We use Session Cookies to operate our Service.
 </li> 
 <li><b>Preference Cookies:</b> We use Preference Cookies to remember your preferences and various settings.
 </li> 
 <li><b>Security Cookies:</b> We use Security Cookies for security purposes.
 </li> 
 <li><b>Advertising Cookies:</b> Advertising Cookies are used to serve you with advertisements that may be relevant to you and your interests.
 </li> </ol> 
 <h2 id="useofdata">4.	Use of Data</h2>
  
 <p>STC Labs uses the collected data for various purposes:</p>
 <ol type="a"> 
<li>to provide and maintain our Service;
  </li>
  <li>to notify you about changes to our Service; 
 </li>
 <li>to allow you to participate in interactive features of our Service when you choose to do so; 
 </li>
 <li>to provide customer support; 
 </li>
 <li>to gather analysis or valuable information so that we can improve our Service; 
 </li>
 <li>to monitor the usage of our Service;
 </li>
 <li>to detect, prevent and address technical issues;
 </li>
 <li>to fulfill any other purpose for which you provide it;
 </li>
 <li>to carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collection;
 </li>
 <li>to provide you with notices about your account and/or subscription, including expiration and renewal notices, email-instructions, etc.;
 </li>
 <li>to provide you with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless you have opted not to receive such information;
 </li> 
 <li>in any other way we may describe when you provide the information;
 </li>
 <li>for any other purpose with your consent.</li> 
 </ol> 
 <h2 id="retention">5.	Retention of Data</h2>
 <p>We will retain your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.
 </p> 
 <p>We will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period, except when this data is used to strengthen the security or to improve the functionality of our Service, or we are legally obligated to retain this data for longer time periods.
  </p>
 <h2 id="transfer">6.	Transfer of Data</h2>
  
 <p>Your information, including Personal Data, may be transferred to – and maintained on – computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ from those of your jurisdiction.
  </p>
 <p>If you are located outside the United States and choose to provide information to us, please note that we transfer the data, including Personal Data, to the United States and process it there.
 </p> 
 <p>Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.
  </p>
 <p>STC Labs will take all the steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of your data and other personal information.
  </p>
 <h2 id="disclosure">7.	Disclosure of Data</h2>
 <p>We may disclose personal information that we collect, or you provide:</p>
 <ol type="a"> 
<li><b>Disclosure for Law Enforcement.</b>
 Under certain circumstances, we may be required to disclose your Personal Data if required to do so by law or in response to valid requests by public authorities.
 </li>
 <li><b>Business Transaction.</b>
 If we or our subsidiaries are involved in a merger, acquisition, or asset sale, your Personal Data may be transferred.
 </li>
 <li><b>Other cases. We may disclose your information also:</b> 
 <ol type="i"> 
 <li>to our subsidiaries and affiliates;
 </li>
 <li>to contractors, service providers, and other third parties we use to support our business;
 </li> 
 <li>to fulfill the purpose for which you provide it;
 </li> 
 <li>for the purpose of including your company’s logo on our website;
 </li>
 <li>for any other purpose disclosed by us when you provide the information;
 </li>
 <li>with your consent in any other cases;
 </li>
 <li>if we believe disclosure is necessary or appropriate to protect the rights, property, or safety of the Company, our customers, or others.
 </li></ol></li></ol> 
 <h2 id="security">8.	Security of Data</h2>
  
 <p>The security of your data is important to us but remember that no method of transmission over the Internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.
 </p>
 <p>The Service includes the functionality of data synchronization between your devices via our own infrastructure as well as via third-party cloud storage service providers. Your data is being encrypted with the latest commercially available encryption algorithms and protocols both at rest and before being transferred anywhere out of your device. Cryptographic keys used for such encryption are also reliably stored in secure environments (also known as keychains, vaults, and conclaves) within your devices only. 
 </p>
 <p>Please beware that if you lose access to all your devices used for access to secure features of the Service and thus to all of your cryptographic keys - we will not be able to help you to regain access to your encrypted data in any way. We are not storing or sending your private cryptographic keys under any circumstances.
  </p>
 <h2 id="gdpr">9.	Your Data Protection Rights Under General Data Protection Regulation (GDPR)</h2>
  
 <p>If you are a resident of the European Union (EU) and European Economic Area (EEA), you have certain data protection rights, covered by GDPR. – See more at <a href="https://eur-lex.europa.eu/eli/reg/2016/679/oj" target="_blank" rel="noreferrer">https://eur-lex.europa.eu/eli/reg/2016/679/oj</a> 
  </p>
 We aim to take reasonable steps to allow you to correct, amend, delete, or limit the use of your Personal Data.
  
 <p>If you wish to be informed what Personal Data we hold about you and if you want it to be removed from our systems, please email us at <a href="mailto:support@stclabs.dev?subject=RE:%20GDPR%20request">support@stclabs.dev</a>
  </p>
 <p>In certain circumstances, you have the following data protection rights:</p>
  <ol type="a">
<li>the right to access, update or to delete the information we have on you;
  </li>
  <li>the right of rectification. You have the right to have your information rectified if that information is inaccurate or incomplete;
 </li>
 <li>the right to object. You have the right to object to our processing of your Personal Data;
 </li>
 <li>the right of restriction. You have the right to request that we restrict the processing of your personal information;
 </li>
 <li>the right to data portability. You have the right to be provided with a copy of your Personal Data in a structured, machine-readable and commonly used format;
 </li>
 <li>the right to withdraw consent. You also have the right to withdraw your consent at any time where we rely on your consent to process your personal information;
 </li></ol> 
 <p>Please note that we may ask you to verify your identity before responding to such requests. Please note, we may not able to provide Service without some necessary data.
  </p>
 <p>You have the right to complain to a Data Protection Authority about our collection and use of your Personal Data. For more information, please contact your local data protection authority in the European Economic Area (EEA).
  </p>
 <h2 id="caloppa">10.	Your Data Protection Rights under the California Privacy Protection Act (CalOPPA)</h2>
 CalOPPA is the first state law in the nation to require commercial websites and online services to post a privacy policy. The law’s reach stretches well beyond California to require a person or company in the United States (and conceivable the world) that operates websites collecting personally identifiable information from California consumers to post a conspicuous privacy policy on its website stating exactly the information being collected and those individuals with whom it is being shared, and to comply with this policy. – See more at: <a href="https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-protection-act-caloppa-3/" target="_blank" rel="noreferrer">https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-protection-act-caloppa-3/</a> 
  
 <p>According to CalOPPA, we agree to the following:</p>
  <ol type="a">
<li>users can visit our site anonymously;</li>
  
<li>our Privacy Policy link includes the word “Privacy”, and can easily be found on the page specified above on the home page of our website;
 </li>
 <li>users will be notified of any privacy policy changes on our Privacy Policy Page;
 </li>
 <li>users are able to change their personal information by emailing us at <a href="mailto:support@stclabs.dev?subject=RE:%20CalOPPA%20request">support@stclabs.dev </a> 
 </li> </ol>
 <p>Our Policy on “Do Not Track” Signals:
 </p>
 Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track (“DNT”) feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected.
 
 <p>No uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy policy.
  </p>
 <h2 id="ccpa">11.	Your Data Protection Rights under the California Consumer Privacy Act (CCPA)</h2>
  
 If you are a California resident, you are entitled to learn what data we collect about you, ask to delete your data, and not to sell (share) it. To exercise your data protection rights, you can make certain requests and ask us:
<ol type="a">
 <li>What personal information we have about you. If you make this request, we will return to you:
  <ol type="I">
 <li>The categories of personal information we have collected about you.
  </li>
  <li>The categories of sources from which we collect your personal information.
 </li>
 <li>The business or commercial purpose for collecting or selling your personal information.
 </li>
 <li>The categories of third parties with whom we share personal information.
 </li>
 <li>The specific pieces of personal information we have collected about you.
 </li>
 <li>A list of categories of personal information that we have sold, along with the category of any other company we sold it to. If we have not sold your personal information, we will inform you of that fact.
 </li>
 <li>A list of categories of personal information that we have disclosed for a business purpose, along with the category of any other company we shared it with.
 </li> </ol>
 Please note, you are entitled to ask us to provide you with this information up to two times in a rolling twelve-month period. When you make this request, the information provided may be limited to the personal information we collected about you in the previous 12 months.
 </li> <li>
 To delete your personal information.<br /> If you make this request, we will delete the personal information we hold about you as of the date of your request from our records and direct any service providers to do the same. In some cases, the deletion may be accomplished through the de-identification of the information. If you choose to delete your personal information, you may not be able to use certain functions that require your personal information to operate. 
  </li><li>
 To stop selling your personal information. <br />We do not sell your personal information for monetary consideration. However, under some circumstances, a transfer of personal information to a third party, or within our family of companies, without monetary consideration may be considered a “sale” under California law.
 </li></ol> 
 If you submit a request to stop selling your personal information, we will stop making such transfers. If you are a California resident, to opt-out of the sale of your personal information, click <a href="https://trust.game/donotsell" target="_blank" rel="noreferrer">“Do Not Sell My Data”</a> at the bottom of our home page to submit your request.
  
 <p>Please note, if you ask us to delete or stop selling your data, it may impact your experience with us, and you may not be able to participate in certain programs or membership services that require the usage of your personal information to function. But in no circumstances, we will discriminate against you for exercising your rights.
  </p>
 <p>To exercise your California data protection rights described above, please send your request(s) by one of the following means:
 </p> <p>
 By email: <a href="mailto:support@stclabs.dev?subject=RE:%20Privacy%20rights">support@stclabs.dev</a><br />
  
 By visiting this page on our website: <a href="https://trust.game/contact" target="_blank" rel="noreferrer">https://trust.game/contact</a>
  </p>
 <p>Your data protection rights, described above, are covered by the CCPA, short for the California Consumer Privacy Act. To find out more, visit the official California Legislative Information website. The CCPA took effect on 01/01/2020. <a href="https://leginfo.legislature.ca.gov/faces/billTextClient.xhtml?bill_id=201720180AB375"  target="_blank" rel="noreferrer">https://leginfo.legislature.ca.gov/faces/billTextClient.xhtml?bill_id=201720180AB375</a>
  </p>
 <h2 id="california">12.	Note to California Users</h2>
 <p>California Civil Code Section 1798.83, also known as the “Shine The Light” law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year.
 </p>
 If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below.
 
 <p>If you are under 18 years of age, reside in California, and have a registered account with the Service, you have the right to request the removal of unwanted data that you publicly post on the Service. To request the removal of such data, please contact us using the contact information provided below and include the email address associated with your account and a statement that you reside in California.
 <br />We will make sure the data is not publicly displayed on or through the Service, but please be aware that the data may not be completely or comprehensively removed from our systems.
 </p>
 <h2 id="providers" >13.	Service Providers</h2>
  
 We may employ third party companies and individuals to facilitate our Service <b>(“Service Providers”)</b>, provide the Service on our behalf, perform Service-related services or assist us in analyzing how our Service is used.
  
 <p>These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.
  </p>
 <h2 id="analytics">14.	Analytics</h2>
  
 We may use third-party Service Providers to monitor and analyze the use of our Service.
 <ul>
 <li><b>Google Analytics</b><br />
 Google Analytics is a web analytics service offered by Google LLC that tracks and reports website traffic. Google uses the data collected to track and monitor the use of our Service. This data is shared with other Google services. Google may use the collected data to contextualize and personalize the ads of its own advertising network.
  
 <p>For more information on the privacy practices of Google, please visit the Google Privacy Terms web page: <a href="https://policies.google.com/privacy?hl=en" target="_blank" rel="noreferrer">https://policies.google.com/privacy?hl=en</a>
  </p>
 We also encourage you to review Google's policy for safeguarding your data: <a href="https://support.google.com/analytics/answer/6004245" target="_blank" rel="noreferrer">https://support.google.com/analytics/answer/6004245</a>
 </li><br /><li>
 <b>Firebase</b><br />
 Firebase is an analytics service provided by Google LLC.
  
 You may opt-out of certain Firebase features through your mobile device settings, such as your device advertising settings or by following the instructions provided by Google in their Privacy Policy: <a href="https://policies.google.com/privacy?hl=en" target="_blank" rel="noreferrer">https://policies.google.com/privacy?hl=en</a>
 </li></ul>  
 <p>For more information on what type of information Firebase collects, please visit the Google Privacy Terms web page: <a href="https://policies.google.com/privacy?hl=en" target="_blank" rel="noreferrer">https://policies.google.com/privacy?hl=en</a>
  </p>
 <h2 id="remarketing">15.	Behavioral Remarketing</h2>
  
 STC Labs uses remarketing services to advertise on third party websites to you after you used our Service. We and our third-party vendors use cookies to inform, optimize and serve ads based on your past visits to our Service.
 <ul>
  <li>
 <b>Google Ads (AdWords)</b><br />
 Google Ads (AdWords) remarketing service is provided by Google LLC.
  
 <p>You can opt-out of Google Analytics for Display Advertising and customize the Google Display Network ads by visiting the Google Ads Settings page: <a href="https://www.google.com/settings/ads" target="_blank" rel="noreferrer">https://www.google.com/settings/ads</a> 
  </p>
 Google also recommends installing the Google Analytics Opt-out Browser Add-on – <a href="https://tools.google.com/dlpage/gaoptout" target="_blank" rel="noreferrer">https://tools.google.com/dlpage/gaoptout</a> – for your web browser. Google Analytics Opt-out Browser Add-on provides visitors with the ability to prevent their data from being collected and used by Google Analytics. 
  
 <p>For more information on the privacy practices of Google, please visit the Google Privacy Terms web page:  <a href="https://policies.google.com/privacy?hl=en" target="_blank" rel="noreferrer">https://policies.google.com/privacy?hl=en</a> 
 </p></li> <br />   <li>
 <b>Bing Ads Remarketing</b><br />
 Bing Ads remarketing service is provided by Microsoft Corporation.
  
 <p>You can opt-out of Bing Ads interest-based ads by following their instructions: <a href="https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads" target="_blank" rel="noreferrer">https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads</a>
 </p> 
 You can learn more about the privacy practices and policies of Microsoft by visiting their Privacy Policy page: <a href="https://privacy.microsoft.com/en-us/PrivacyStatement" target="_blank" rel="noreferrer">https://privacy.microsoft.com/en-us/PrivacyStatement</a>
 </li><br /><li>
 <b>Facebook</b><br />
 Facebook remarketing service is provided by Facebook Inc.
  
 <p>You can learn more about interest-based advertising from Facebook by visiting this page: <a href="https://www.facebook.com/help/585318558251813" target="_blank" rel="noreferrer">https://www.facebook.com/help/585318558251813</a>
  </p>
 To opt-out from Facebook's interest-based ads, follow these instructions from Facebook: <a href="https://www.facebook.com/help/568137493302217" target="_blank" rel="noreferrer">https://www.facebook.com/help/568137493302217</a>
 <p>
 Facebook adheres to the Self-Regulatory Principles for Online Behavioural Advertising established by the Digital Advertising Alliance. You can also opt-out from Facebook and other participating companies through the Digital Advertising Alliance in the USA <a href="https://www.aboutads.info/choices/" target="_blank" rel="noreferrer">https://www.aboutads.info/choices/</a>, the Digital Advertising Alliance of Canada in Canada <a href="https://youradchoices.ca/"  target="_blank" rel="noreferrer">https://youradchoices.ca/</a> or the European Interactive Digital Advertising Alliance in Europe <a href="https://www.youronlinechoices.eu/"  target="_blank" rel="noreferrer">https://www.youronlinechoices.eu/</a>, or opt-out using your mobile device settings. 
 </p>  
 For more information on the privacy practices of Facebook, please visit Facebook's Data Policy: <a href="https://www.facebook.com/privacy/explanation/" target="_blank" rel="noreferrer">https://www.facebook.com/privacy/explanation/</a>
 </li></ul>  
 <h2 id="payments">16.	Payments</h2>
  <p>
 We may provide paid products and/or services within the Service. In that case, we use third-party services for payment processing (e.g. payment processors).
</p><p>
 We will not store or collect your payment card details. That information is provided directly to our third-party payment processors, whose use of your personal information is governed by their Privacy Policy. These payment processors adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, Mastercard, American Express, and Discover. PCI-DSS requirements help ensure the secure handling of payment information.
 </p>
 The payment processors we work with are:
<ul>
 <li><b>PayPal or Braintree:</b><br />
 Their Privacy Policy can be viewed at <a href="https://www.paypal.com/webapps/mpp/ua/privacy-full" target="_blank" rel="noreferrer">https://www.paypal.com/webapps/mpp/ua/privacy-full</a>
 </li><br />
 <li><b>Apple Store In-App Payments:</b><br />
 Their Privacy Policy can be viewed at <a href="https://www.apple.com/legal/privacy/en-ww" target="_blank" rel="noreferrer">https://www.apple.com/legal/privacy/en-ww</a>  <a href="https://support.apple.com/en-us/HT203027" target="_blank" rel="noreferrer">https://support.apple.com/en-us/HT203027</a>
 </li> <br />
 <li><b>Google Play In-App Payments:</b><br />
 Their Privacy Policy can be viewed at <a href="https://policies.google.com/privacy?hl=en&gl=us" target="_blank" rel="noreferrer">https://policies.google.com/privacy?hl=en&gl=us</a> <a href="https://payments.google.com/payments/apis-secure/u/0/get_legal_document?ldo=0&ldt=privacynotice&ldl=en" target="_blank" rel="noreferrer">https://payments.google.com/payments/apis-secure/u/0/get_legal_document?ldo=0&ldt=privacynotice&ldl=en</a>
 </li><br />
 <li><b>Stripe:</b><br />
 Their Privacy Policy can be viewed at <a href="https://stripe.com/us/privacy" target="_blank" rel="noreferrer">https://stripe.com/us/privacy</a>
 </li> <br />
 <li><b>Square:</b><br />
 Their Privacy Policy can be viewed at <a href="https://squareup.com/us/en/legal/general/privacy" target="_blank" rel="noreferrer">https://squareup.com/us/en/legal/general/privacy</a>
 </li><br />
 <li><b>Plaid:</b><br />
 Their End User Privacy Policy, Cookie Policy, and Privacy Statement can be viewed at <a href="https://plaid.com/legal/" target="_blank" rel="noreferrer">https://plaid.com/legal/</a>
 </li></ul>
 <h2 id="otherlinks">17.	Links to Other Sites</h2>
 <p> 
 Our Service may contain links to other websites that are not operated by us. If you click a third party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit.
 </p> <p>
 We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.
  </p>
 <h2 id="children">18.	Children's Privacy</h2>
 
 <p>Our Services at present are not intended for use by persons under the age of 18 <b>(“Child” or “Children”)</b>. 
  </p>
 <p>We do not knowingly collect personally identifiable information from Children under 18. If you become aware that a Child has provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from Children without verification of parental consent, we take steps to remove that information from our servers.
  </p>
 <h2 id="changes">19.	Changes to This Privacy Policy</h2>
  
 <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on the Service.
  </p>
  <p>We will make a reasonable effort to let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update “effective date” at the top of this Privacy Policy.
 </p>
 <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.
 </p>
 <h2 id="contactus">20.	Contact Us</h2>
  
 <p>If you have any questions about this Privacy Policy, please contact us:</p>
  
 By email: <a href="mailto:support@stclabs.dev?subject=RE:%20Privacy%20Policy">support@stclabs.dev</a><br />
 By visiting this page on our website: <a href="https://trust.game/contact" target="_blank" rel="noreferrer">https://trust.game/contact</a>
 
 <h3>Change Log</h3>
 
<p>07/06/2022 – Initial version of Privacy Policy has been published and took effect. </p>
    </LongContent>
  );
}

export default LegalPrivacy;
