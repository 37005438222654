import React from "react";
import Meta from "./../components/Meta";
import HeroSection2 from "./../components/HeroSection2";
import { useTranslation } from 'react-i18next';
//import TeamBiosSection from "./../components/TeamBiosSection";

function AboutPage(props) {
  const { t } = useTranslation();
  return (
    <>
      <Meta title={t("meta.about.title")} description={t("meta.about.description")} />
      <HeroSection2
        bgColor="primary"
        size="large"
        bgImage=""
        bgImageOpacity={0.2}
        title={t("about.title")}
        subtitle={t("about.subtitle")}
      />
     { /*  <TeamBiosSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Meet the Team"
        subtitle=""
  /> */ }
    </>
  );
}

export default AboutPage;
