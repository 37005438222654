import React from "react";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import List from "@material-ui/core/List";
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import { useConnectionsLeaders, useTrustorsPSBLeaders, useTrustorsTTBLeaders } from "../util/rtdb";
import { floatString, elipsisMiddle } from "../util/util";
import { Grid } from "@material-ui/core";
import { Trans } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  featured: {
    backgroundColor:
      theme.palette.type === "dark" ? theme.palette.action.selected : "#fdf8c2",
  },
  starFeatured: {
    color: theme.palette.warning.main,
  },
  cardContent: {
    padding: theme.spacing(3),
  },
}));

function Leaderboard(props) {

  const classes = useStyles();

  const {
    data: connLeaders,
    status: connStatus,
    error: connError,
  } = useConnectionsLeaders();

  const {
    data: tpsbLeaders,
    status: tpsbStatus,
    error: tpsbError,
  } = useTrustorsPSBLeaders();

  const {
    data: tttbLeaders,
    status: tttbStatus,
    error: tttbError,
  } = useTrustorsTTBLeaders();

  const connLeadersAreEmpty = !connLeaders || Object.keys(connLeaders).length === 0;
  const tpsbLeadersAreEmpty = !tpsbLeaders || Object.keys(tpsbLeaders).length === 0;
  const tttbLeadersAreEmpty = !tttbLeaders || Object.keys(tttbLeaders).length === 0;

  return (
    <>
      {connError && (
        <Box mb={3}>
          <Alert severity="error">{connError.message}</Alert>
        </Box>
      )}
      {tpsbError && (
        <Box mb={3}>
          <Alert severity="error">{tpsbError.message}</Alert>
        </Box>
      )}
      {tttbError && (
        <Box mb={3}>
          <Alert severity="error">{tttbError.message}</Alert>
        </Box>
      )}
    <Grid container={true} spacing={4}>
          <Grid item={true} xs={12} md={12}>
      <Paper className={classes.paperItems}>
        {(connStatus === "loading" || (connLeadersAreEmpty)) && (
          <Box py={5} px={3} align="center">
            {connStatus === "loading" && <CircularProgress size={32} />}

            {(connStatus !== "loading" && connLeadersAreEmpty) && (
              <>Nothing to show here yet. This is weird.</>
            )}
          </Box>
        )}
        {connStatus !== "loading" && connLeaders && !connLeadersAreEmpty && (
          <List disablePadding={true}
          subheader={
            <ListSubheader disableSticky component="div" id="nested-list-subheader">
              <Box component="div"  sx={{ display: 'inline', width: '100%' }}><Trans i18nKey="leaderboard.mtb"></Trans></Box>
              
            </ListSubheader>
          }>
            {Object.entries(connLeaders).sort((a,b) => b[1] - a[1]).map((item, index) => (
              <ListItem
                divider={index !== Object.keys(connLeaders).length - 1}
                key={item[0]}
              >
                <ListItemText primary={String(index+1) + ". " +  elipsisMiddle(String(item[0])) }></ListItemText>
                <ListItemText style={{display:'block', textAlign:'right'}} primary={floatString(item[1] / 1000000)}
                />
              </ListItem>
            ))}
          </List>
        )}
       </Paper>
       </Grid>
       <Grid item={true} xs={12} md={12}>
      <Paper className={classes.paperItems}>
        {(tttbStatus === "loading" || (tttbLeadersAreEmpty)) && (
          <Box py={5} px={3} align="center">
            {tttbStatus === "loading" && <CircularProgress size={32} />}

            {(tttbStatus !== "loading" && tttbLeadersAreEmpty) && (
              <>Nothing to show here yet. This is weird.</>
            )}
          </Box>
        )}
        {tttbStatus !== "loading" && tttbLeaders && !tttbLeadersAreEmpty && (
          <List disablePadding={true}
          subheader={
            <ListSubheader disableSticky component="div" id="nested-list-subheader">
              <Box component="div"  sx={{ display: 'inline', width: '100%' }}><Trans i18nKey="leaderboard.ttb"></Trans></Box>
              
            </ListSubheader>
          }>
            {Object.entries(tttbLeaders).sort((a,b) => (b[1].TTB || 0) - (a[1].TTB || 0)).map((item, index) => (
              <ListItem
                divider={index !== Object.keys(tttbLeaders).length - 1}
                key={item[0]}
              >
                <ListItemText primary={ String(index+1) + ". " + elipsisMiddle(String(item[0])) }></ListItemText>
                <ListItemText style={{display:'block', textAlign:'right'}} primary={floatString(item[1].TTB / 1000000)}
                />
              </ListItem>
            ))}
          </List>
        )}
       </Paper>
</Grid><Grid item={true} xs={12} md={12}>
       <Paper className={classes.paperItems}>
        {(tpsbStatus === "loading" || (tpsbLeadersAreEmpty)) && (
          <Box py={5} px={3} align="center">
            {tpsbStatus === "loading" && <CircularProgress size={32} />}

            {(tpsbStatus !== "loading" && tpsbLeadersAreEmpty) && (
              <>Nothing to show here yet. This is weird.</>
            )}
          </Box>
        )}
        {tpsbStatus !== "loading" && tpsbLeaders && !tpsbLeadersAreEmpty && (
          <List disablePadding={true}
          subheader={
            <ListSubheader disableSticky component="div" id="nested-list-subheader">
              <Box component="div"  sx={{ display: 'inline', width: '100%' }}><Trans i18nKey="leaderboard.psb"></Trans></Box>
              
            </ListSubheader>
          }>
            {Object.entries(tpsbLeaders).sort((a,b) => (b[1].PSB || 0) - (a[1].PSB || 0)).map((item, index) => (
              <ListItem
                 divider={index !== Object.keys(tpsbLeaders).length - 1}
                key={item[0]}
              >
                <ListItemText primary={ String(index+1) + ". " + elipsisMiddle(String(item[0])) }></ListItemText>
                <ListItemText style={{display:'block', textAlign:'right'}} primary={floatString(item[1].PSB / 1000000)}
                />
              </ListItem>
            ))}
          </List>
        )}
       </Paper>
       </Grid>
       </Grid>
       
    </>
  );
}

export default Leaderboard;
