import React from "react";
import Meta from "./../components/Meta";
import ContactSection from "./../components/ContactSection";
import { useTranslation} from 'react-i18next';

function DoNotSellPage(props) {
  const { t } = useTranslation();
  return (
    <>
      <Meta title={t("meta.donotsell.title")} />
      <ContactSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title={t("donotsell.title")}
        subtitle={t("donotsell.subtitle")}
        buttonText={t("donotsell.button")}
        buttonColor="primary"
        messagePrefill={t("donotsell.prefill")}
        showNameField={true}
      />
    </>
  );
}

export default DoNotSellPage;