import React from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Link } from "./../util/router";
import { useTranslation} from 'react-i18next';

function SettingsNav(props) {

  const { t } = useTranslation();

  return (
    <Tabs
      value={props.activeKey}
      indicatorColor="primary"
      textColor="primary"
      centered={true}
    >
      <Tab
        component={Link}
        to="/settings/general"
        label={t("settings.general")}
        value="general"
      />
    </Tabs>
  );
}

export default SettingsNav;
