import React from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Link } from "./../util/router";
import { useTranslation} from 'react-i18next';

function IdeasNav(props) {

  const { t } = useTranslation();

  return (
    <Tabs
      value={props.activeKey}
      indicatorColor="primary"
      textColor="primary"
      centered={true}
    >
      <Tab
        component={Link}
        to="/ideas/mechanics"
        label={t("ideas.mechanics")}
        value="mechanics"
      />
      <Tab
        component={Link}
        to="/ideas/experience"
        label={t("ideas.experience")}
        value="experience"
      />
    </Tabs>
  );
}

export default IdeasNav;
