import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import LinkMui from "@material-ui/core/Link";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import { Link } from "./../util/router";
import { useDarkMode } from "./../util/theme";
import { Trans } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  sticky: {
    marginTop: "auto",
  },
  brand: {
    display: "block",
    height: 32,
  },
  listItem: {
    paddingTop: 2,
    paddingBottom: 2,
    paddingLeft: 12,
    paddingRight: 12,
  },
  listItemTextHeader: {
    fontWeight: "bold",
  },
  socialIcon: {
    minWidth: 30,
    maxWidth: 30,
    
  },
  legal: {
    marginTop: theme.spacing(3),
    fontSize: "0.875rem",
    opacity: 0.6,
    "& a": {
      color: "inherit",
      marginLeft: "0.8rem",
    },
  },
}));

function Footer(props) {

  const classes = useStyles();

  const darkMode = useDarkMode();
  // Use inverted logo if specified
  // and we are in dark mode
  const logo =
    props.logoInverted && darkMode.value ? props.logoInverted : props.logo;

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      className={props.sticky && classes.sticky}
    >
      <Container>
        <Grid container={true} justifyContent="space-between" spacing={4}>
          <Grid item={true} xs={12} md={4}>
            <Link to="/">
              <img src={logo} alt="Logo" className={classes.brand} />
            </Link>

            {props.description && (
              <Box mt={3}>
                <Typography component="p">{props.description}</Typography>
              </Box>
            )}

            <div className={classes.legal}>
              {props.copyright}
              <LinkMui component={Link} to="/legal/terms-of-service">
              <Trans i18nKey="legal.termsShort"></Trans>
              </LinkMui>
              <LinkMui component={Link} to="/legal/privacy-policy">
              <Trans i18nKey="legal.privacyShort"></Trans>
              </LinkMui>
            </div>
            <div className={classes.legal}>
              <LinkMui component={Link} to="/donotsell">
              <Trans i18nKey="legal.doNotSell"></Trans>
              </LinkMui>
              </div>
          </Grid>
          <Grid item={true} xs={12} md={6}>
            <Grid container={true} spacing={4}>
              <Grid item={true} xs={12} md={4}>
                <List disablePadding={true}>
                  <ListItem className={classes.listItem}>
                    <Typography
                      variant="overline"
                      className={classes.listItemTextHeader}
                    >
                      <Trans i18nKey="footer.game"></Trans>
                    </Typography>
                  </ListItem>
                  <ListItem
                    component={Link}
                    to="/rules"
                    button={true}
                    className={classes.listItem}
                  >
                    <ListItemText><Trans i18nKey="footer.rules"></Trans></ListItemText>
                  </ListItem>
                  <ListItem
                    component={Link}
                    to="/faq"
                    button={true}
                    className={classes.listItem}
                  >
                    <ListItemText><Trans i18nKey="menu.faq"></Trans></ListItemText>
                  </ListItem>
                  <ListItem
                    component={Link}
                    to="/statistics"
                    button={true}
                    className={classes.listItem}
                  >
                    <ListItemText><Trans i18nKey="menu.statistics"></Trans></ListItemText>
                  </ListItem>
                  <ListItem
                    component={Link}
                    to="/leaders"
                    button={true}
                    className={classes.listItem}
                  >
                    <ListItemText><Trans i18nKey="menu.leaderboard"></Trans></ListItemText>
                  </ListItem>
                  
                </List>
              </Grid>
              <Grid item={true} xs={12} md={4}>
                <List disablePadding={true}>
                  <ListItem className={classes.listItem}>
                    <Typography
                      variant="overline"
                      className={classes.listItemTextHeader}
                    >
                      <Trans i18nKey="footer.company"></Trans>
                    </Typography>
                  </ListItem>
                  <ListItem
                    component={Link}
                    to="/about"
                    button={true}
                    className={classes.listItem}
                  >
                    <ListItemText><Trans i18nKey="footer.about"></Trans></ListItemText>
                  </ListItem>
                  <ListItem
                    component={Link}
                    to="/contact"
                    button={true}
                    className={classes.listItem}
                  >
                    <ListItemText><Trans i18nKey="footer.contact"></Trans></ListItemText>
                  </ListItem>
             
                  
                </List>
              </Grid>
              <Grid item={true} xs={12} md={4}>
                <List disablePadding={true}>
                  <ListItem className={classes.listItem}>
                    <Typography
                      variant="overline"
                      className={classes.listItemTextHeader}
                    >
                      <Trans i18nKey="footer.community"></Trans>
                    </Typography>
                  </ListItem>
                  <ListItem
                    button={true}
                    component="a"
                    href="https://twitter.com/game_of_trust"
                    target="_blank"
                    rel="noreferrer"
                    className={classes.listItem}
                  >
                    <ListItemIcon className={classes.socialIcon}>
                      <img
                        src="https://uploads.divjoy.com/icon-twitter.svg"
                        alt="Twitter"
                      />
                    </ListItemIcon>
                    <ListItemText><Trans i18nKey="social.twitter"></Trans></ListItemText>
                  </ListItem>
                  <ListItem
                    button={true}
                    component="a"
                    href="https://www.facebook.com/GameThatMatters"
                    target="_blank"
                    rel="noreferrer"
                    className={classes.listItem}
                  >
                    <ListItemIcon className={classes.socialIcon}>
                      <img
                        src="https://uploads.divjoy.com/icon-facebook.svg"
                        alt="Facebook"
                      />
                    </ListItemIcon>
                    <ListItemText><Trans i18nKey="social.facebook"></Trans></ListItemText>
                  </ListItem>
                  <ListItem
                    button={true}
                    component="a"
                    href="https://www.instagram.com/game_that_matters/"
                    target="_blank"
                    rel="noreferrer"
                    className={classes.listItem}
                  >
                    <ListItemIcon className={classes.socialIcon}>
                      <img
                        src="https://uploads.divjoy.com/icon-instagram.svg"
                        alt="Instagram"
                      />
                    </ListItemIcon>
                    <ListItemText><Trans i18nKey="social.instagram"></Trans></ListItemText>
                  </ListItem>
                  <ListItem
                    button={true}
                    component="a"
                    href="https://discord.gg/E33p262gfA"
                    target="_blank"
                    rel="noreferrer"
                    className={classes.listItem}
                  >
                    <ListItemIcon className={classes.socialIcon}>
                      <img
                        src="/icons/discord.svg"
                        alt="Discord"
                      />
                    </ListItemIcon>
                    <ListItemText><Trans i18nKey="social.discord"></Trans></ListItemText>
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Section>
  );
}

export default Footer;
