import React, { useEffect } from "react";
import { requireAuth , useAuth } from "./../util/auth";
import { useRouter } from "./../util/router";
import PageLoader from "./../components/PageLoader";


function CannyAuthPage(props) {
  const router = useRouter();
  const auth = useAuth();

  useEffect(() => {

    const getRedirectPath = () => {
      if (!auth.user?.cannyJSWT) return null;
      var redirectURL = router.query.redirect;
      var companyID = router.query.companyID;
      if (!redirectURL || !companyID) {
        return null;
      }
      return 'https://canny.io/api/redirects/sso?companyID=' + companyID + '&ssoToken=' + auth.user.cannyJSWT + '&redirect=' + redirectURL;
    }

    var redirectURL = getRedirectPath();
      if (redirectURL) {
        console.log("Redirecting to: ", redirectURL);
        window.location.assign(redirectURL);
      }

  }, [auth.user.cannyJSWT, router.query.redirect, router.query.companyID]);

  return (
     <PageLoader />
  );
}

export default requireAuth(CannyAuthPage);
