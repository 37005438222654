import React , { useState }  from "react";
import { useForm } from "react-hook-form";
import { useAuth } from "./../util/auth";
import { firebaseApp } from "./../util/firebase";
import {
  getAuth,
  signInWithPhoneNumber,
  RecaptchaVerifier
} from "firebase/auth";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import Button from "@material-ui/core/Button";
import CustomPhoneNumber from '../components/CustomPhoneNumber';
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import { Link } from "./../util/router";
import LinkMui from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import { isSafari } from "react-device-detect";
import { getFormattedAddressFromCoords } from "../util/geocode"
import { makeStyles } from "@material-ui/core";
import { useTranslation, Trans } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  inputCustom: {
    "-webkit-background-clip": "text !important",
    backgroundClip:  "text !important",
  },
}));


function AuthPhoneForm(props)  {

  const auth = useAuth();
  const appauth = getAuth(firebaseApp);
  const { t } = useTranslation();
  const classes = useStyles();

  const [mynumber, setnumber] = useState("");
  const [country, setCountry] = useState("US");
  const [show, setshow] = useState(false);
  const [final, setfinal] = useState('');
  const [uninvited, setUninvited] = useState(false);
  const [pending, setPending] = useState(false);

  const { handleSubmit, register, errors } = useForm();

  if ("geolocation" in navigator) {
    navigator.geolocation.getCurrentPosition(function(position) {
          getFormattedAddressFromCoords(position.coords.latitude, position.coords.longitude).then( result => {
             setCountry(result.countryCode);
          }).catch( e=> {
          // console.log(e);
          });
       }, function(error) {
         // console.log(error);
       });
      } else {
     console.log("Not Available");
}


// Sent OTC
const signin = () => {
  
  if (mynumber === "" || mynumber.length < 10) return;
  setPending(true);
  window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
    'size': 'invisible',
    'callback': (response) => {
    }
  }, appauth);

  window.recaptchaVerifier.render().then((widgetId) => {
    window.recaptchaWidgetId = widgetId;
  });

   signInWithPhoneNumber(appauth, mynumber, window.recaptchaVerifier).then((result) => {
      setfinal(result);
      setshow(true);
  })
      .catch((err) => {
          alert(err);
          window.location.reload()
      }).finally(() => {
        setPending(false);
      });
}

  // Validate OTP
  const ValidateOtp = ({ otc }) => {
    if (otc === null || final === null) return;
    setPending(true);
    auth.signinWithPhone(final, otc).then((user) => {
      if (user!=null) {
      // Call auth complete handler
      props.onAuth(user);
      } else {
        // user has not been invited
        setshow(false);
        setUninvited(true);
        setPending(false);
        auth.signout();
      }
    }).catch((err) => {
      console.log(err);
      alert(t("auth.wrongCode"));
      setPending(false);
    });
}

  return (
    <center>
        {uninvited && (
          <Box mb={3}>
            <Alert severity="warning"><Trans i18nKey="auth.error.inviteOnly"></Trans></Alert>
          </Box>
        )}
        <div style={{ display: !show ? "block" : "none" }}>
        <PhoneInput
          international
          defaultCountry={country}
          //placeholder="Enter phone number"
          countryCallingCodeEditable={isSafari ? false : true}
          withCountryCallingCode={true}
          value={mynumber}
          onChange={setnumber}
          inputComponent={CustomPhoneNumber}
             />
            <br /><br />
            <div id="recaptcha-container"></div>
            <Button
              variant="contained"
              size="medium"
              color="primary"
              onClick={signin}
            >
            {!pending && t("auth.sendOTCButton")}
           {pending && <CircularProgress size={28} />}
          </Button><br /><br />
          <Trans i18nKey="auth.consent.part1"></Trans>&nbsp;
          <LinkMui component={Link} to="/legal/terms-of-service">
                          <strong><Trans i18nKey="legal.terms"></Trans></strong>
                        </LinkMui><Trans i18nKey="auth.consent.and"></Trans>&nbsp;
                        <LinkMui component={Link} to="/legal/privacy-policy">
                          <strong><Trans i18nKey="legal.privacyConj"></Trans></strong>
                        </LinkMui><Trans i18nKey="auth.consent.part2"></Trans>
        </div>
        <div style={{ display: show ? "block" : "none" }}>
        <form onSubmit={handleSubmit(ValidateOtp)}>
          <TextField
                variant="outlined"
                type="tel"
                name="otc"
                error={errors.otc ? true : false}
                fullWidth={true}
                inputRef={register({
                  required: t("auth.enterOTC"),
                })}
                inputProps={{ placeholder: t("auth.oneTimeCode"), 'auto-complete': "one-time-code",  className: classes.inputCustom}}
              >
          </TextField> 
            <br /><br />
            <Button
              variant="contained"
              size="medium"
              color="primary"
              type="submit"
            >
            {!pending &&  t("auth.verifyOTC")}
           {pending && <CircularProgress size={28} />}
          </Button>
          </form>
        </div>
        
    </center>
  );
  
}

export default AuthPhoneForm;
