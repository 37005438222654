import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import Typography from "@material-ui/core/Typography";
import Section from "./Section";
import { useCounters } from "./../util/rtdb";
import CircularProgress from "@material-ui/core/CircularProgress";
import { floatString } from "../util/util";
import { Trans } from 'react-i18next';

function StatsSection(props) {

  const {
    data: counters,
    status: countersStatus,
    error: countersError,
  } = useCounters();

  const countersAreEmpty = !counters || counters.length === 0;

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
        { countersStatus === "loading" && <CircularProgress size={28} />}
        { countersAreEmpty && <Trans i18nKey="stats.nodata"></Trans>}
        { countersError && (
          <Box mb={3}>
            <Alert severity="error">{countersError.message}</Alert>
          </Box>
        )}
       { countersStatus !== "loading" && !countersAreEmpty && <Container>
        <Grid container={true} justifyContent="center" spacing={4}>
            <Grid item={true} xs={12} sm={3}>
              <Box textAlign="center">
                <Typography variant="overline"><Trans i18nKey="stats.trustors"></Trans></Typography>
                <Typography variant="h4">{counters.ttc}</Typography>
              </Box>
            </Grid>
            <Grid item={true} xs={12} sm={3}>
              <Box textAlign="center">
                <Typography variant="overline"><Trans i18nKey="stats.connections"></Trans></Typography>
                <Typography variant="h4">{counters.tcc}</Typography>
              </Box>
            </Grid>
            <Grid item={true} xs={12} sm={3}>
              <Box textAlign="center">
                <Typography variant="overline"><Trans i18nKey="stats.totalTrust"></Trans></Typography>
                <Typography variant="h4">{floatString((counters.gTBm+counters.gPBm+counters.gCBm) / 1000000)}</Typography>
              </Box>
            </Grid>
            <Grid item={true} xs={12} sm={3}>
              <Box textAlign="center">
                <Typography variant="overline"><Trans i18nKey="stats.sharedTrust"></Trans></Typography>
                <Typography variant="h4">{floatString(counters.gTBm / 1000000)}</Typography>
              </Box>
            </Grid>
        </Grid>
      </Container> 
      }
    </Section>
  );
}

export default StatsSection;
