import React from "react";
import LongContent from "./LongContent";

function LegalTerms(props) {
  return (
    <LongContent>
      <h1>TRUST.GAME by STC LABS - TERMS OF SERVICE</h1>
 
 <p>Last updated and in effect on: 07/06/2022</p>
  
 <h3>Table of Contents</h3>
 <ol>
  <li><a href="#introduction">INTRODUCTION</a></li>
  <li><a href="#description">DESCRIPTION OF SERVICE</a></li>
  <li><a href="#communications">COMMUNICATIONS</a></li>
  <li><a href="#purchases">PURCHASES</a></li>
  <li><a href="#contests">CONTESTS, SWEEPSTAKES, AND PROMOTIONS</a></li>
  <li><a href="#subscriptions">SUBSCRIPTIONS</a></li>
  <li><a href="#freetrial">FREE TRIAL</a></li>
  <li><a href="#feechanges">FEE CHANGES</a></li>
  <li><a href="#refunds">REFUNDS</a></li>
  <li><a href="#changestoservice">CHANGES TO SERVICE</a></li>
  <li><a href="#usergenerated">USER-GENERATED CONTENT</a></li>
  <li><a href="#prohibited">PROHIBITED USES</a></li>
  <li><a href="#nominors">NO USE BY MINORS</a></li>
  <li><a href="#accounts">ACCOUNTS</a></li>
  <li><a href="#ip">INTELLECTUAL PROPERTY</a></li>
  <li><a href="#copyright">COPYRIGHT POLICY</a></li>
  <li><a href="#dmca">DMCA NOTICE AND PROCEDURE FOR COPYRIGHT INFRINGEMENT CLAIMS</a></li>
  <li><a href="#feedback">ERROR REPORTING AND FEEDBACK</a></li>
  <li><a href="#outgoinglinks">LINKS TO OTHER WEB SITES</a></li>
  <li><a href="#nowarranty">DISCLAIMER OF NO WARRANTY</a></li>
  <li><a href="#limitedliability">LIMITATION OF LIABILITY</a></li>
  <li><a href="#termination">TERMINATION</a></li>
  <li><a href="#gowerninglaw">GOVERNING LAW</a></li>
  <li><a href="#disputeresolution">RESOLUTION OF DISPUTES</a></li>
  <li><a href="#noclassaction">NO CLASS ACTION</a></li>
  <li><a href="#nojurytrial">NO JURY TRIAL</a></li>
  <li><a href="#timelimit">LIMITED TIME FOR BRINGING CLAIMS</a></li> 
  <li><a href="#claimsbyothers">CLAIMS BY OTHERS</a></li>
  <li><a href="#amendments">AMENDMENTS TO TERMS</a></li> 
  <li><a href="#waiver">WAIVER AND SEVERABILITY</a></li>
  <li><a href="#acknowledgment">ACKNOWLEDGMENT</a></li>  
  <li><a href="#california">NOTE FOR CALIFORNIA USERS</a></li>  
  <li><a href="#contactus">CONTACT US</a></li>  
  </ol>
 
 <h2 id="introduction">1.     Introduction</h2>
 <p>Welcome to <b>STC Labs (“Company”, “we”, “our”, “us”)</b>. As you have just decided to check out our Terms of Service, please make a pause, grab a cup of coffee or tea (whatever makes you more alert), and carefully read the following pages. It will take you approximately 20 minutes.
 </p>
 <p>These <b>Terms of Service (“Terms”, “Terms of Service”)</b> govern your use of our websites located at <a href="https://trust.game" target="_blank" rel="noreferrer">https://trust.game</a> as well as our mobile application <b>Trust.Game</b> (together or individually <b>“Service”</b>) operated by STC Labs.
 </p>
 <p>Our Privacy Policy also governs your use of the Service and explains how we collect, safeguard, and disclose information that results from your use of our web sites and applications. Please read it here: <a href="https://trust.game/legal/privacy-policy" target="_blank" rel="noreferrer">https://trust.game/legal/privacy-policy</a>
  </p>
 <p>Your agreement with us includes these Terms and our Privacy Policy <b>(“Agreements”)</b>. You acknowledge that you have read and understood the Agreements. It is important that you do this carefully and understand completely because you will be legally bound by these Agreements.
  </p>
 <p>THESE AGREEMENTS ALSO CONTAIN A MANDATORY DISPUTE RESOLUTION CLAUSE THAT REQUIRES THE USE OF INDIVIDUAL ARBITRATION TO RESOLVE DISPUTES, RATHER THAN JURY TRIALS OR CLASS ACTIONS.
  </p>
 <p>If you do not agree with (or cannot comply with) the Agreements, then you may not use the Service, but please let us know by emailing at <a href="mailto:support@stclabs.dev?subject=RE:%20Terms%20of%20Service">support@stclabs.dev</a>, so we can try to find a solution. These Terms apply to all visitors, users, and others who wish to access or use the Service.
  </p>
  <p>Thank you for being a responsible member of our community.</p>
 <h2 id="description">2.     Description of Service</h2>
 The <b>“Service”</b> means:
 
  <ol type="a">
 <li>STC Labs operated socio-economic game Trust.Game, website <a href="https://trust.game" target="_blank" rel="noreferrer">https://trust.game</a> (the “Site”), and</li>
 <li>all software applications, data, text, images, and other content made available by or on behalf of STC Labs.</li>
 </ol>
 <p>Any modifications to the Service are also subject to these Terms. STC Labs reserves the right to modify or discontinue the Service or any feature or functionality thereof at any time without notice. All rights, title, and interest in and to the Service will remain with and belong exclusively to STC Labs.
</p>
 <h2 id="communications">3.     Communications</h2>
 <p>By creating an Account within our Service, you agree to subscribe to newsletters, marketing or promotional materials and other information we may consider to be useful for you. However, you may opt out of receiving any, or all, of these communications from us by following the unsubscribe link or by emailing at <a href="mailto:support@stclabs.dev">support@stclabs.dev</a>
  </p>
 <p>The Service may send operational emails including but not limited to billing emails, account activity emails, and service updates. These email notifications are required to provide the Service and cannot be unsubscribed from.
 </p>
 <h2 id="purchases">4.     Purchases</h2>
 <p>If you wish to purchase any product or service made available through the Service <b>(“Purchase”)</b>, you may be asked to supply certain information relevant to your Purchase including, without limitation, your credit card number, the expiration date of your credit card, your billing address, and your shipping information.
  </p>
  <p>You represent and warrant that: (i) you have the legal right to use any credit card(s) or another payment method(s) in connection with any Purchase; and that (ii) the information you supply to us is true, correct and complete.
 </p>
 <p>We may employ the use of third-party services for the purpose of facilitating payment and the completion of Purchases. By submitting your information, you grant us the right to provide the information to these third parties subject to our Privacy Policy.
 </p>
 <p>We reserve the right to refuse or cancel your order at any time for reasons including but not limited to the following reasons: product or service availability, errors in the description or price of the product or service, error in your order, or other reasons.
 </p>
 <p>We reserve the right to refuse or cancel your order if fraud or an unauthorized or illegal transaction is suspected.
 </p>
 <h2 id="contests">5.     Contests, Sweepstakes, and Promotions</h2>
 <p>Any contests, sweepstakes or other promotions (collectively, <b>“Promotions”</b>) made available through the Service may be governed by rules that are separate from these Terms. If you participate in any Promotions, please review the applicable rules as well as our Privacy Policy. If the rules for a Promotion conflict with these Terms of Service, Promotion rules will apply and prevail.
 </p>
 <h2 id="subscriptions">6.     Subscriptions</h2>
 <p>Some parts of the Service are billed on a subscription basis (<b>“Subscription(s)”</b>). You will be billed in advance on a recurring and periodic basis (<b>“Billing Cycle”</b>). Billing cycles are set either on a monthly or annual basis, depending on the type of subscription plan you select when purchasing a Subscription.
 </p> 
 <p>At the end of each Billing Cycle, your Subscription will automatically renew under the exact same conditions unless you cancel it or STC Labs cancels it. You may cancel your Subscription renewal either through your online account management page or by contacting STC Labs customer support team at <a href="mailto:support@stclabs.dev?subject=RE:%20Billing">support@stclabs.dev</a>.
  </p>
 <p>A valid payment method, including a credit card or PayPal, is required to process the payment for your subscription. You shall provide STC Labs with accurate and complete billing information including full name, address, state, zip code, telephone number, and a valid payment method information. By submitting such payment information, you automatically authorize STC Labs to charge all Subscription fees incurred through your account to any of such payment instruments.
  </p>
 <p>All amounts paid are non-refundable. You further agree to be responsible for all taxes associated with the Service, along with any transaction fees and currency conversions added by your financial institution and intermediaries. All amounts are in US Dollars.
  </p>
 <p>Should automatic billing fail to occur for any reason, STC Labs will issue an electronic invoice indicating that you must proceed manually, within a certain deadline date, with the full payment corresponding to the billing period as indicated on the invoice.
 </p>
 <h2 id="freetrial">7.     Free Trial</h2>
 <p>STC Labs may, at its sole discretion, offer a Subscription with a free trial for a limited period of time (<b>“Free Trial”</b>).
  </p>
 <p>You may be required to enter your billing information in order to sign up for Free Trial.
  </p>
 <p>If you do enter your billing information when signing up for Free Trial, you will not be charged by STC Labs until Free Trial has expired. On the last day of the Free Trial period, unless you canceled your Subscription, you will be automatically charged the applicable Subscription fees for the type of Subscription you have selected.
  </p>
 <p>At any time and without notice, STC Labs reserves the right to (i) modify Terms of Service of Free Trial offer, or (ii) cancel such Free Trial offer.
 </p>
 <h2 id="feechanges">8.     Fee Changes</h2>
 <p>STC Labs, in its sole discretion and at any time, may modify Subscription fees for the Subscriptions. Any Subscription fee change will become effective at the end of the then-current Billing Cycle.
  </p>
 <p>STC Labs will provide you with reasonable prior notice of any change in Subscription fees to give you an opportunity to terminate your Subscription before such change becomes effective.
  </p>
 <p>Your continued use of the Service after Subscription fee change comes into effect constitutes your agreement to pay the modified Subscription fee amount.
 </p>
 <h2 id="refunds">9.     Refunds</h2>
 <p>Except when required by law, paid Subscription fees are non-refundable. Notwithstanding the foregoing, certain refund requests for Subscriptions may be considered by STC Labs on a case-by-case basis and granted at the sole discretion of STC Labs.
 </p>
 <h2 id="changestoservice">10.  Changes to Service</h2>
 <p>We reserve the right to withdraw or amend our Service, and any service or material we provide via the Service, in our sole discretion without notice. We will not be liable if for any reason all or any part of the Service is unavailable at any time or for any period. From time to time, we may restrict access to some parts of the Service, or the entire Service, to users, including registered users.
 </p>
 <h2 id="usergenerated">11.  User-Generated Content</h2>
 Our Service allows you to post, link, store, share and otherwise make publicly available certain information, text, graphics, videos, or other material (“Content”).
  
 <p>YOUR PERSONAL AND PRIVATE DATA AND DOCUMENTS SECURELY STORED ON THE SERVICE ARE NOT CONSIDERED AS PART OF CONTENT IN THE MEANING DESCRIBED IN THIS SECTION AND USE OF SUCH DATA IS GOVERNED BY OUR PRIVACY POLICY.
  </p>
 You are responsible for Content that You post on or through the Service, including its legality, reliability, and appropriateness.
  
 <p>By posting Content on or through the Service, You represent and warrant that: (i) Content is yours (you own it) and/or you have the right to use it and the right to grant us the rights and license as provided in these Terms, and (ii) that the posting of your Content on or through the Service does not violate the privacy rights, publicity rights, copyrights, contract rights or any other rights of any person or entity. We reserve the right to terminate the account of anyone found to be infringing on a copyright.
  </p>
 You retain any and all of your rights to any Content you submit, post, or display on or through the Service and you are responsible for protecting those rights. We take no responsibility and assume no liability for Content you or any third-party posts on or through the Service. However, by posting Content using the Service you grant us the right and license to use, modify, publicly perform, publicly display, reproduce, and distribute such Content on and through the Service. You agree that this license includes the right for us to make your Content available to other users of the Service, who may also use your Content subject to these Terms.
  
 <p>STC Labs has the right but not the obligation to monitor and edit all Content provided by users.
  </p>
 <p>In addition, Content found on or through this Service are the property of STC Labs or used with permission. You may not distribute, modify, transmit, reuse, download, repost, copy, or use said Content, whether in whole or in part, for commercial purposes or for personal gain, without express advance written permission from us.
 </p>
 <h2 id="prohibited">12.  Prohibited Uses</h2>
 You may use the Service only for lawful purposes and in accordance with Terms. You agree not to use the Service:
  <ol type="a">
<li>In any way that violates any applicable national or international law or regulation.
</li>
<li>For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way by exposing them to inappropriate content or otherwise.
 </li>
 <li>To transmit, or procure the sending of, any advertising or promotional material, including any “junk mail”, “chain letter,” “spam,” or any other similar solicitation.
 </li> 
 <li>To impersonate or attempt to impersonate Company, a Company employee, another user, or any other person or entity.
 </li>
 <li>In any way that infringes upon the rights of others, or in any way is illegal, threatening, fraudulent, or harmful, or in connection with any unlawful, illegal, fraudulent, or harmful purpose or activity.
 </li>
 <li>To engage in any other conduct that restricts or inhibits anyone’s use or enjoyment of the Service, or which, as determined by us, may harm or offend Company or users of the Service or expose them to liability.
 </li></ol>
 Additionally, you agree not to:
  <ol type="a">
  <li>Use the Service in any manner that could disable, overburden, damage, or impair the Service or interfere with any other party’s use of the Service, including their ability to engage in real-time activities through the Service.
 </li>
 <li> Use any robot, spider, or another automatic device, process, or means to access the Service for any purpose, including monitoring or copying any of the material on the Service.
 </li>
 <li>Use any manual process to monitor or copy any of the material on the Service or for any other unauthorized purpose without our prior written consent.
 </li>
 <li>Use any device, software, or routine that interferes with the proper working of the Service.
 </li>
 <li>Introduce any viruses, trojan horses, worms, logic bombs, or other material which is malicious or technologically harmful.
 </li>
 <li>Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of the Service, the server on which the Service is stored, or any server, computer, or database connected to the Service.
 </li>
 <li>Attack the Service via a denial-of-service attack or a distributed denial-of-service attack.
 </li>
 <li>Take any action that may damage or falsify Company rating.
 </li>
 <li>In any other way attempt to interfere with the proper working of the Service.
 </li></ol>
 <h2 id="nominors">13.  No Use by Minors</h2>
 <p>The Service at present is intended only for access and use by individuals at least eighteen (18) years old. By accessing or using any part of the Service, you warrant and represent that you are at least eighteen (18) years of age and with the full authority, right, and capacity to enter into this agreement and abide by all of the terms and conditions of the Terms. If you are not at least eighteen (18) years old, you are prohibited from both the access and the usage of the Service.
 </p>
 <h2 id="accounts">14.  Accounts</h2>
 When you create an account with us, you guarantee that you are above the age of 18, and that the information you provide us is accurate, complete, and current at all times. Inaccurate, incomplete, or obsolete information may result in the immediate termination of your account on the Service resulting from a breach of these Terms.
  
 <p>You are responsible for maintaining the confidentiality of your account and password, including but not limited to the restriction of access to your computer and/or account. You agree to accept responsibility for any and all activities or actions that occur under your account and/or password, whether your password is with our Service or a third-party service. You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account.
  </p>
 You may not use as a username the name of another person or entity or that is not lawfully available for use, a name or trademark that is subject to any rights of another person or entity other than you, without appropriate authorization. You may not use as a username any name that is offensive, vulgar, or obscene.
  
 <p>We reserve the right to refuse the Service, terminate accounts, remove or edit content, or cancel orders in our sole discretion.
 </p>
 <h2 id="ip">15.  Intellectual Property</h2>
 <p>The Service and its original content including but not limited to text, images, graphics or code (excluding a content provided by users), its features and functionality are and will remain the exclusive property of STC Labs and its licensors and are protected by copyright, trademark, and other laws of the United States and foreign countries. Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of STC Labs.
 </p>
 <h2 id="copyright">16.  Copyright Policy</h2>
 We respect the intellectual property rights of others. It is our policy to respond to any claim that Content posted on the Service infringes on the copyright or other intellectual property rights (<b>“Infringement”</b>) of any person or entity.
  
 <p>If you are a copyright owner or authorized on behalf of one, and you believe that the copyrighted work has been copied in a way that constitutes copyright infringement, please submit your claim via email to <a href="mailto:dmca@stclabs.dev?subject=RE:%20Infringement">dmca@stclabs.dev</a>, with the subject line: “Copyright Infringement” and include in your claim a detailed description of the alleged Infringement as detailed below, under “DMCA Notice and Procedure for Copyright Infringement Claims”
  </p>
 <p>You may be held accountable for damages (including costs and attorneys' fees) for misrepresentation or bad faith claims on the infringement of any Content found on and/or through the Service on your copyright.
 </p>
 <h2 id="dmca">17.  DMCA Notice and Procedure for Copyright Infringement Claims</h2>
 You may submit a notification pursuant to the Digital Millennium Copyright Act (DMCA) by providing our Copyright Agent with the following information in writing (see 17 U.S.C 512(c)(3) for further detail):
  <ol type="a">
<li>an electronic or physical signature of the person authorized to act on behalf of the owner of the copyright's interest;
</li>
<li>a description of the copyrighted work that you claim has been infringed, including the URL (i.e., web page address) of the location where the copyrighted work exists or a copy of the copyrighted work;
 </li>
 <li>identification of the URL or other specific location on the Service where the material that you claim is infringing is located;
 </li>
 <li>your address, telephone number, and email address;
 </li>
 <li>a statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law;
 </li>
 <li>a statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are the copyright owner or authorized to act on the copyright owner's behalf.
 </li></ol> 
 <p>You can contact our Copyright Agent via email at <a href="mailto:dmca@stclabs.dev?subject=RE:%20Infringement">dmca@stclabs.dev</a>
 </p>
 <h2 id="feedback">18.  Error Reporting and Feedback</h2>
 <p>You may provide us directly at <a href="mailto:support@stclabs.dev">support@stclabs.dev</a> or via websites with information and feedback concerning errors, suggestions for improvements, ideas, problems, complaints, and other matters related to our Service (<b>“Feedback”</b>). You acknowledge and agree that: (i) you shall not retain, acquire or assert any intellectual property right or another right, title or interest in or to the Feedback; (ii) Company may have developed or currently has in development ideas similar to the Feedback; (iii) Feedback does not contain confidential information or proprietary information from you or any third party; (iv) Company is not under any obligation of confidentiality with respect to the Feedback. In the event the transfer of the ownership to the Feedback is not possible due to applicable mandatory laws, you grant Company and its affiliates an exclusive, transferable, irrevocable, free-of-charge, sub-licensable, unlimited and perpetual right to use (including copy, modify, create derivative works, publish, distribute and commercialize) Feedback in any manner and for any purpose.
 </p>
 <h2 id="outgoinglinks">19.  Links to Other Web Sites</h2>
 Our Service may contain links to third-party web sites or services that are not owned or controlled by STC Labs.
  
 <p>STC Labs has no control over, and assumes no responsibility for the content, privacy policies, or practices of any third-party web sites or services. We do not warrant the offerings of any of these entities/individuals or their websites.
  </p>
 YOU ACKNOWLEDGE AND AGREE THAT STC LABS SHALL NOT BE RESPONSIBLE OR LIABLE, DIRECTLY OR INDIRECTLY, FOR ANY DAMAGE OR LOSS CAUSED OR ALLEGED TO BE CAUSED BY OR IN CONNECTION WITH USE OF OR RELIANCE ON ANY SUCH CONTENT, GOODS OR SERVICES AVAILABLE ON OR THROUGH ANY SUCH THIRD-PARTY WEBSITES OR SERVICES.
<p>
 WE STRONGLY ADVISE YOU TO READ THE TERMS OF SERVICE AND PRIVACY POLICIES OF ANY THIRD-PARTY WEBSITES OR SERVICES THAT YOU VISIT.
 </p>
 <h2 id="nowarranty">20.  Disclaimer of No Warranty</h2>
 THE SERVICE IS PROVIDED BY COMPANY ON AN “AS IS” AND “AS AVAILABLE” BASIS. COMPANY MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF OUR SERVICE, OR THE INFORMATION, CONTENT OR MATERIALS INCLUDED THEREIN. YOU EXPRESSLY AGREE THAT YOUR USE OF THE SERVICE, ITS CONTENT, AND ANY SERVICES OR ITEMS OBTAINED FROM US IS AT YOUR SOLE RISK.
  
 <p>NEITHER COMPANY NOR ANY PERSON ASSOCIATED WITH COMPANY MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE SERVICE. WITHOUT LIMITING THE FOREGOING, NEITHER COMPANY NOR ANYONE ASSOCIATED WITH COMPANY REPRESENTS OR WARRANTS THAT THE SERVICE, THEIR CONTENT, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICE WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT THE SERVICE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE SERVICE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICE WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
  </p>
 COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE.

 <p>THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
 </p>
 <h2 id="limitedliability">21.  Limitation of Liability</h2>
 <p>EXCEPT AS PROHIBITED BY LAW, YOU WILL HOLD US AND OUR OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS HARMLESS FOR ANY INDIRECT, PUNITIVE, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL LOSS OR DAMAGE, INCLUDING WITHOUT LIMITATION, ECONOMIC LOSS, LOSS OR DAMAGE TO ELECTRONIC MEDIA OR DATA, GOODWILL, OR OTHER INTANGIBLE LOSSES HOWEVER IT ARISES (INCLUDING ATTORNEYS' FEES AND ALL RELATED COSTS AND EXPENSES OF LITIGATION AND ARBITRATION, OR AT TRIAL OR ON APPEAL, IF ANY, WHETHER OR NOT LITIGATION OR ARBITRATION IS INSTITUTED), WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE, OR OTHER TORTIOUS ACTION, OR ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT, INCLUDING WITHOUT LIMITATION ANY CLAIM FOR PERSONAL INJURY OR PROPERTY DAMAGE, ARISING FROM THIS AGREEMENT AND ANY VIOLATION BY YOU OF ANY FEDERAL, STATE, OR LOCAL LAWS, STATUTES, RULES, OR REGULATIONS, EVEN IF COMPANY HAS BEEN PREVIOUSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGE. EXCEPT AS PROHIBITED BY LAW, IF THERE IS LIABILITY FOUND ON THE PART OF COMPANY, IT WILL BE LIMITED TO THE AMOUNT PAID FOR THE PRODUCTS AND/OR SERVICES, AND UNDER NO CIRCUMSTANCES WILL THERE BE CONSEQUENTIAL OR PUNITIVE DAMAGES. SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF PUNITIVE, INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE PRIOR LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU.
 </p>
 <h2 id="termination">22.  Termination</h2>
 We may terminate or suspend your account and bar access to the Service immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever and without limitation, including but not limited to a breach of Terms. If we terminate your account for the reasons unrelated to a breach of Terms, then we will work with you to ensure you retain copies of your data, wherever permitted by law.
  
 <p>If you wish to terminate your account, you may simply discontinue using the Service. You are entitled to cease using our Service at any time and for any reason without notice to us, but you will continue to be charged for the Service until you cancel your account by contacting us at <a href="mailto:support@stclabs.dev">support@stclabs.dev</a>
 </p>
 Upon termination of your account, your right to use the Service will cease immediately.
  
 <p>All provisions of these Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.
 </p>
 <h2 id="gowerninglaw">23.  Governing Law</h2>
 These Terms shall be governed and construed in accordance with the laws of State of California as applicable and without regard to its conflict of law provisions.
  
 <p>Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms together with our Privacy Policy constitute the entire agreement between us regarding our Service and supersede and replace any prior agreements we might have had between us regarding the Service.
 </p>
 <p>Subject to the section entitled “Resolution of Disputes” below, exclusive jurisdiction and venue of any action arising out of these Agreements or your use of the Service will be the state and federal courts located in the Northern District of California and each of the parties hereto waives any objection to jurisdiction and venue in such courts.
 </p>
 <h2 id="disputeresolution">24.  Resolution of Disputes</h2>
 YOU EXPRESSLY AGREE THAT YOU WILL SUBMIT ANY DISPUTE(S) ARISING FROM THIS TERMS OF SERVICE, PRIVACY POLICY AND/OR THE SERVICE, INCLUDING DISPUTES ARISING FROM OR CONCERNING THEIR INTERPRETATION, VIOLATION, INVALIDITY, NON-PERFORMANCE, OR TERMINATION, TO BINDING ARBITRATION UNDER THE RULES OF ARBITRATION OF THE AMERICAN ARBITRATION ASSOCIATION APPLYING CALIFORNIA LAW IN THE COUNTY OF SAN FRANCISCO.
  
 <p>AS A LIMITED EXCEPTION TO THE AGREEMENT TO ARBITRATE, YOU AND WE AGREE THAT: (A) YOU MAY MAKE CLAIMS TO SMALL CLAIMS COURT, IF YOUR CLAIMS QUALIFY FOR HEARING BY SUCH COURT; AND (B) IF YOU FAIL TO TIMELY PAY AMOUNTS DUE, WE MAY ASSIGN YOUR ACCOUNT FOR COLLECTION, AND THE COLLECTION AGENCY MAY PURSUE IN COURT CLAIMS LIMITED STRICTLY TO THE COLLECTION OF THE PAST DUE DEBT AND ANY INTEREST OR COST OF COLLECTION AGREEMENT.
 </p>
 <h2 id="noclassaction">25.  No Class Action</h2>
 <p>YOU AGREE THAT YOU MUST ASSERT ANY CLAIM AGAINST STC LABS INDIVIDUALLY, AND THAT YOUR CLAIMS MAY NOT BE COMBINED WITH ANYONE ELSE’S CLAIMS IN A CLASS ACTION OR ANY OTHER KIND OF JOINT OR REPRESENTATIVE ACTION, WHETHER BEFORE A COURT OR AN ARBITRATOR. IF A COURT OR ARBITRATOR DETERMINES IN A CLAIM BETWEEN YOU AND US THAT YOUR WAIVER OF ANY ABILITY TO PARTICIPATE IN CLASS OR REPRESENTATIVE ACTIONS IS UNENFORCEABLE, THE ARBITRATION AGREEMENT SET FORTH ABOVE WILL NOT APPLY, AND YOU AND WE AGREE THAT SUCH CLAIMS WILL BE RESOLVED BY A COURT OF APPROPRIATE JURISDICTION, OTHER THAN A SMALL CLAIMS COURT.
 </p>
 <h2 id="nojurytrial">26.  No Jury Trial</h2>
 <p>TO THE MAXIMUM EXTENT PERMITTED BY LAW, YOU AND WE WAIVE OUR RIGHTS TO A JURY TRIAL AND AGREE THAT ANY CLAIM ARISING OUT OF OR CONNECTED WITH YOUR USE OF THE SERVICE TO WHICH THE ARBITRATION PROVISIONS OF THIS AGREEMENT ARE FOR ANY REASON HELD NOT TO APPLY, WILL BE DETERMINED BY A JUDGE OF THE APPROPRIATE COURT.
 </p>
 <h2 id="timelimit">27.  Limited Time for Bringing Claims</h2>
 <p>Both you and we agree that no suit, arbitration or other legal proceeding connected with these Agreements or your use of the Service may be brought later than one year after the incident giving rise to the claim has occurred.
 </p>
 <h2 id="claimsbyothers">28.  Claims by Others</h2>
 <p>If anyone other than you brings a claim against STC Labs or its affiliates for damages, loss or injury to the claimant in any way connected with your use of the Service, including economic loss suffered by you or the claimant, you agree that you will indemnify, defend and hold STC Labs and its affiliates harmless from all liability in connection with such claim, and you will pay to us any amount a court or arbitrator orders us to pay or we reasonably agree to pay by way of settlement, plus the amount of our reasonable attorneys’ fees and costs in defending and settling the claim. These claims may include but are not limited to (for example) claims by your family members relating to an injury suffered by you. You agree that this obligation is binding on your heirs and successors and applies whether the claimant is claiming in his or her own right or is subrogated to your rights.
 </p>
 <h2 id="amendments">29.  Amendments to Terms</h2>
 We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will make reasonable efforts to provide at least 30 days’ electronic notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion. It is your responsibility to review these Terms periodically.
  
 <p>Your continued use of the Service following the public posting of revised Terms on our web page located at <a href="https://trust.game/legal/terms-of-service" target="_blank" rel="noreferrer">https://trust.game/legal/terms-of-service</a> means that you accept and agree to the changes. You are expected to check this page frequently, so you are aware of any changes, as they are binding on you.
 </p>
 By continuing to access or use our Service after any revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, you are no longer authorized to use the Service.
  
 <p>The Change Log section below is not a part of these Terms, nor is the Terms of Service archive or any content linked therefrom.
 </p>
 <h2 id="waiver">30.  Waiver and Severability</h2>
 No waiver by the Company of any term or condition set forth in these Terms shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of the Company to assert a right or provision under these Terms shall not constitute a waiver of such right or provision.
  
 <p>If any provision of Terms is held by a court or other tribunal of competent jurisdiction to be invalid, illegal or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of Terms will continue in full force and effect.
 </p>
 <h2 id="acknowledgment">31.  Acknowledgment</h2>
 <p>BY USING THE SERVICE OR OTHER SERVICES PROVIDED BY US, YOU ACKNOWLEDGE THAT YOU HAVE READ THESE TERMS OF SERVICE AND AGREE TO BE BOUND BY THEM.
 </p>
 <h2 id="california">32.  Note for California Users</h2>
 Under California Civil Code section 1789.3, California web users are entitled to the following specific consumer rights notice:<br/>
 The Service is provided by STC Labs. Phone: +1(978)TRUSTME (+1(978)878-7863). Mail: 3800 Firestar Way, Sacramento, CA, 95834. If you have any questions, concerns, or complaints regarding the Services, please contact <a href="mailto:support@stclabs.dev">support@stclabs.dev</a>.
  
 <p>California residents may contact the Complaint Assistance Unit of the Division of Consumer Services of the California Department of Consumer Affairs in writing at 1625 N. Market Blvd., Suite S-202, Sacramento, California 95834, or by telephone at (916) 445-1254 or (800) 952-5210 or Hearing Impaired at TDD (800) 326-2297 or TDD (916) 322-1700.</p>
 <h2 id="contactus">33.  Contact us</h2>
 <p>Please send your feedback, comments, requests for technical support:</p>
 <p>
 By email: <a href="mailto:support@stclabs.dev">support@stclabs.dev</a><br />
 By visiting this page on our website: <a href="https://trust.game/contact" target="_blank" rel="noreferrer">https://trust.game/contact</a>
 </p>
 <p>Thank you for reading!</p>
 <h3>Change Log</h3>
         
 <p>07/06/2022 – Initial version of the Terms of Service has been published and took effect.</p>
    </LongContent>
  );
}

export default LegalTerms;
