import React from "react";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import List from "@material-ui/core/List";
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import { makeStyles } from "@material-ui/core/styles";
import { useAuth } from "./../util/auth";
import { useOpenPlaySessions, joinPlayroom, updateTrustor } from "./../util/db";
import { Trans, useTranslation} from 'react-i18next';
import { elipsisMiddle } from "../util/util";

const useStyles = makeStyles((theme) => ({
  paperItems: {
    minHeight: "300px",
  },
  featured: {
    backgroundColor:
      theme.palette.type === "dark" ? theme.palette.action.selected : "#fdf8c2",
  },
  starFeatured: {
    color: theme.palette.warning.main,
  },
}));

function PlaySessions(props) {
  const classes = useStyles();

  const auth = useAuth();
  const { t, i18n } = useTranslation();

  const {
    data: sessions,
    status: snsStatus,
    error: snsError,
  } = useOpenPlaySessions(auth.user.uid);

  const snsAreEmpty = !sessions || sessions.length === 0;

  const join = async function(playroomId, playerId) {
       const role = Math.random() > 0.5 ? 1 : 0;
        const data = {
            playerA: {
              role: role
            },
            players: [playerId, auth.user.uid ],
            playerB: {
                id: auth.user.uid,
                role: 1 - role,
                balance: 0,
                choice: null,
                submittedOn: null
            },
            state: 1
        };
        await joinPlayroom(playroomId, data);
        await updateTrustor(auth.user.uid, { playroom: playroomId });
}

  return (
    <>
      {snsError && (
        <Box mb={3}>
          <Alert severity="error">{snsError.message}</Alert>
        </Box>
      )}
      <Paper className={classes.paperItems}>
        {(snsStatus === "loading" || (snsAreEmpty)) && (
          <Box py={5} px={3} align="center">
            {snsStatus === "loading" && <CircularProgress size={32} />}

            {(snsStatus !== "loading" && snsAreEmpty) && (
              <Trans i18nKey="playroom.sessions.nothing"></Trans>
            )}
          </Box>
        )}

        {(auth.user.playroom === undefined || auth.user.playroom === null) && snsStatus !== "loading" && sessions && sessions.length > 0 && (
          <List disablePadding={true}
          subheader={
            <ListSubheader disableSticky component="div" id="nested-list-subheader">
             { t("playroom.sessions.headline") }
            </ListSubheader>
          }>
            {sessions.map((item, index) => (
              
              <ListItem
                key={index}
                divider={index !== sessions.length - 1}
                className={item.featured ? classes.featured : ""}
              >
                { item.createdOn != null && <>
                <ListItemText primary={ item.createdOn.toDate().toLocaleTimeString(i18n.resolvedLanguage) } secondary={ item.createdOn.toDate().toLocaleDateString(i18n.resolvedLanguage, { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' }) }></ListItemText>
                <ListItemText style={{display:'block', textAlign:'left'}} primary={ elipsisMiddle(item.playerA.id) } secondary={ t("playroom.gametypes." + item.gameType) } />
                </>
              }

            
                <ListItemSecondaryAction>
                <Button
                            variant="contained"
                            size="medium"
                            color="primary"
                            onClick={() => join(item.id, item.playerA.id)}
                        >
                   <Trans i18nKey="playroom.sessions.join"></Trans>
                </Button>
                </ListItemSecondaryAction>
              </ListItem>
              
            ))}
          </List>
        )}
       </Paper>
    </>
  );
}

export default PlaySessions;
