import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useForm } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import { useAuth } from "../util/auth";
import { Trans, useTranslation} from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  content: {
    paddingBottom: 24,
  },
}));

function IntroModal(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const auth = useAuth();
  const [pending, setPending] = useState(false);
  const [formAlert, setFormAlert] = useState(null);

  const { register, handleSubmit, errors } = useForm();


  const onSubmit = (data) => {
    setPending(true);

    return auth
      .updateProfile(data)
      .then(() => {
        // Set success status
        props.onStatus({
          type: "success",
          message: t("settings.success"),
        });
      })
      .catch((error) => {    
          // Set error status
          setFormAlert({
            type: "error",
            message: error.message,
          });
      })
      .finally(() => {
        // Hide pending indicator
        setPending(false);
      });
  };

 

  return (
    <Dialog open={true} onClose={props.onDone}>
      <DialogTitle>
      <Trans i18nKey="intro.title"></Trans>
      </DialogTitle>
      <DialogContent className={classes.content}>
        {formAlert && (
          <Box mb={4}>
            <Alert severity={formAlert.type}>{formAlert.message}</Alert>
          </Box>
        )}

        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container={true} spacing={3}>
          <Grid item={true} xs={12}><Trans i18nKey="intro.prompt"></Trans></Grid>
            <Grid item={true} xs={12}>
                <TextField
                variant="outlined"
                type="text"
                label={t("settings.name.label")}
                name="name"
                placeholder={t("settings.name.placeholder")}
                defaultValue={auth.user.name}
                error={errors.name ? true : false}
                helperText={errors.name && errors.name.message}
                fullWidth={true}
                inputRef={register({
                  required: t("settings.name.validation"),
                })}
              />
            </Grid>
            <Grid item={true} xs={12}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                disabled={pending}
              >
                {!pending && <span><Trans i18nKey="settings.save"></Trans></span>}

                {pending && <CircularProgress size={28} />}
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default IntroModal;
