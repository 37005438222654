import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import Statistics from "./Statistics";
import { useCounters } from "../util/rtdb";


const useStyles = makeStyles((theme) => ({
  cardContent: {
    padding: theme.spacing(3),
  },
}));

function StatisticsSection(props) {
  const classes = useStyles();
  const counters = useCounters();

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
      <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
          backTo={-1}
        />
        

        <Grid container={true} spacing={4}>        
          <Grid item={true} xs={12} md={12}>
            <Card>
              <CardContent className={classes.cardContent}>
                <Statistics limit={5} counters={counters}/>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Section>
  );
}

export default StatisticsSection;
