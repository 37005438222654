import React from "react";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import List from "@material-ui/core/List";
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import { useAuth } from "./../util/auth";
import { useClosedPlaySessions } from "./../util/db";
import { Trans, useTranslation} from 'react-i18next';
import { elipsisMiddle, floatString } from "../util/util";
import { getRoundsPlayed, getMaxUtility } from "./Games/utils";

const useStyles = makeStyles((theme) => ({
  paperItems: {
    minHeight: "300px",
  },
  featured: {
    backgroundColor:
      theme.palette.type === "dark" ? theme.palette.action.selected : "#fdf8c2",
  },
  starFeatured: {
    color: theme.palette.warning.main,
  },
}));

function PlaySessionsHistory(props) {
  const classes = useStyles();

  const auth = useAuth();
  const { t, i18n } = useTranslation();

  const {
    data: sessions,
    status: snsStatus,
    error: snsError,
  } = useClosedPlaySessions(auth.user.uid);

  const snsAreEmpty = !sessions || sessions.length === 0;

  return (
    <>
      {snsError && (
        <Box mb={3}>
          <Alert severity="error">{snsError.message}</Alert>
        </Box>
      )}
      <Paper className={classes.paperItems}>
        {(snsStatus === "loading" || (snsAreEmpty)) && (
          <Box py={5} px={3} align="center">
            {snsStatus === "loading" && <CircularProgress size={32} />}

            {(snsStatus !== "loading" && snsAreEmpty) && (
              <Trans i18nKey="playroom.history.nothing"></Trans>
            )}
          </Box>
        )}

        {auth.user.playroom === null && snsStatus !== "loading" && sessions && sessions.length > 0 && (
          <List disablePadding={true}
          subheader={
            <ListSubheader disableSticky component="div" id="nested-list-subheader">
             { t("playroom.history.headline") }
            </ListSubheader>
          }>
            {sessions.filter(session => (session.createdOn !== null && session.playerB.id !== null)).sort((a, b) => b.createdOn - a.createdOn).map((item, index) => (           
                <ListItem
                  key={item.id}
                  divider={index !== sessions.length - 1}
                  className={item.featured ? classes.featured : ""}
                >
                  <ListItemText primary={ item.createdOn.toDate().toLocaleTimeString(i18n.resolvedLanguage) } secondary={ item.createdOn.toDate().toLocaleDateString(i18n.resolvedLanguage, { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' }) }></ListItemText>
                  <ListItemText primary={ t("playroom.rounds")  + getRoundsPlayed(item) } secondary={  t("playroom.maxutility")  + getMaxUtility(item) }></ListItemText>
                  <ListItemText primary={ t("playroom.balance") + floatString(item.playerA.id === auth.user.uid ? item.playerA.balance : item.playerB.balance,2) } secondary={ t("playroom.utilityrate") + floatString((item.playerA.id === auth.user.uid ? item.playerA.balance : item.playerB.balance) / getMaxUtility(item),2) }></ListItemText>
                  <ListItemText style={{display:'block', textAlign:'right'}} primary={ elipsisMiddle(item.playerA.id === auth.user.uid ? item.playerB.id : item.playerA.id) } secondary={ t("playroom.gametypes." + item.gameType) } />
               
                { /*<ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="join"
                   onClick={() => join(item.id, item.playerA.id)}
                  >
                    <ArrowForwardIosIcon />
                  </IconButton>
              </ListItemSecondaryAction> */}
              </ListItem>
            ))}
          </List>
        )}
       </Paper>
    </>
  );
}

export default PlaySessionsHistory;
