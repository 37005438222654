import React from "react";
import Meta from "./../components/Meta";
import { requireAuth, useAuth } from "./../util/auth";
import IntroModal from "./../components/IntroModal";
import NotificationsSection from "../components/NotificationsSection";
import { useTranslation} from 'react-i18next';

function NotificationsPage(props) {
  
  const auth = useAuth();
  const { t } = useTranslation();

  return (
    <>
    <Meta title={t("meta.notifications.title")} />
    { !auth.user.name && <IntroModal /> } 
    { auth.user.name &&
      <NotificationsSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title={t("notifications.headline") + auth.user.name}
        subtitle=""
      />
    }
    </>
  );
}

export default requireAuth(NotificationsPage);
