import { forwardRef } from 'react'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  inputCustom: {
    "-webkit-background-clip": "text !important",
    backgroundClip:  "text !important",
  },
}));

const CustomPhoneNumber = (props, ref) => {

  const classes = useStyles();

  return (
    <TextField
      {...props}
      inputRef={ref}
      inputProps={{
        className: classes.inputCustom,
      }}
      fullWidth
      size='small'
      variant='outlined'
      name='phone'
    />
  )
}
export default forwardRef(CustomPhoneNumber)

