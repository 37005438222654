import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useForm } from "react-hook-form";
import { useAuth } from "./../util/auth";
import { Checkbox, FormControlLabel, FormGroup, makeStyles } from "@material-ui/core";
import { Trans, useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
  inputCustom: {
    "-webkit-background-clip": "text !important",
    backgroundClip:  "text !important",
  },
}));

function SettingsGeneral(props) {
  
  const auth = useAuth();
  const classes = useStyles();

  const { t } = useTranslation();

  const [pending, setPending] = useState(false);
  const [noEmail, setNoEmail] = useState(auth.user.email ? false : true);
  const [checkedNewsletter, setCheckedNewsletter] = useState(auth.user.sendNewsletter || false);
  const [checkedNotifications, setCheckedNotifications] = useState(auth.user.sendNotifications || true);

  const { register, handleSubmit, errors } = useForm();

  const onSubmit = (data) => {
    // Show pending indicator
    setPending(true);

    return auth
      .updateProfile({...data , sendNewsletter: !noEmail && checkedNewsletter, sendNotifications: !noEmail && checkedNotifications})
      .then(() => {
        // Set success status
        props.onStatus({
          type: "success",
          message: t("settings.success"),
        });
      })
      .catch((error) => {
        if (error.code === "auth/requires-recent-login") {
          props.onStatus({
            type: "requires-recent-login",
            // Resubmit after reauth flow
            callback: () => onSubmit(data),
          });
        } else {
          // Set error status
          props.onStatus({
            type: "error",
            message: error.message,
          });
        }
      })
      .finally(() => {
        // Hide pending indicator
        setPending(false);
      });
  };

  const handleEmailChanged = (email) => {
   if (noEmail !== (email === "")) setNoEmail(email === "");
  };

  const handleNfxChange = (email) => {
    setCheckedNotifications(!checkedNotifications);
   };

   const handleNLChange = (email) => {
    setCheckedNewsletter(!checkedNewsletter);
   };

 return (
  <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container={true} spacing={2}>
        <Grid item={true} xs={12}>
          <TextField
            variant="outlined"
            type="text"
            label={t("settings.name.label")}
            name="name"
            placeholder={t("settings.name.placeholder")}
            defaultValue={auth.user.name}
            error={errors.name ? true : false}
            helperText={errors.name && errors.name.message}
            fullWidth={true}
            inputRef={register({
              required: t("settings.name.validation"),
            })}
            inputProps={{ className: classes.inputCustom}}
          />
        </Grid>
        <Grid item={true} xs={12}>
          <TextField
            variant="outlined"
            type="email"
            label={t("settings.email.label")}
            name="email"
            placeholder={t("settings.email.placeholder")}
            defaultValue={auth.user.email}
            error={errors.email ? true : false}
            helperText={errors.email && errors.email.message}
            fullWidth={true}
            inputRef={register}
            inputProps={{ className: classes.inputCustom}}
            onChange={(e) => handleEmailChanged(e.target.value)}
          />
        </Grid>
        <Grid item={true} xs={12}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            type="submit"
            disabled={pending}
            fullWidth={true}
          >
            {!pending && <span><Trans i18nKey="settings.save"></Trans></span>}

            {pending && <CircularProgress size={28} />}
          </Button>
          <FormGroup>
            <br />
            <FormControlLabel control={<Checkbox color="primary" disabled={noEmail} checked={checkedNotifications} onChange={handleNfxChange} inputProps={{ 'aria-label': 'controlled' }} />} label={t("settings.consent.nfx")}></FormControlLabel>
            <br />
            <FormControlLabel control={<Checkbox color="primary" disabled={noEmail} checked={checkedNewsletter} onChange={handleNLChange} inputProps={{ 'aria-label': 'controlled' }}  />} label={t("settings.consent.news")}></FormControlLabel>
          </FormGroup>

        </Grid>
      </Grid>
    </form> 
  );
}

export default SettingsGeneral;
