import { Button, CircularProgress, Grid, Typography } from "@material-ui/core";
import React from "react";
import { Trans } from "react-i18next";
import SmileyFeedback from "./SmileyFeedback";
import { getPlayer, getRole } from "./utils";

function AfterParty(props) {

    const game = props.game;
    const role = getRole(game, props.uid);
    const player = getPlayer(game, props.uid);

    return( 
    <>
        <Typography paragraph={true}><Trans i18nKey={"playroom.resolution.gameover"}></Trans> { (game.state > 2 && game.state <6) && <Trans i18nKey={"playroom.continue"}></Trans>} { game.state === 6 && <Trans i18nKey="playroom.exited"></Trans>}</Typography>
                    

        { ((role === 0 && game.state === 4) || (role === 1 && game.state === 5))  && <><Typography paragraph={true}><Trans i18nKey="playroom.waiting"></Trans><CircularProgress size={32} /></Typography></>} 
        <Grid container={true} spacing={4}>

            { ((role === 0 && game.state !== 4) || (role === 1 && game.state !== 5)) && game.state < 6 &&
                <Grid item={true} xs={6} md={2}>
                    <Button
                                variant="contained"
                                size="medium"
                                color="primary"
                                onClick={() => props.replay()}
                            >
                        <Trans i18nKey="playroom.again"></Trans>
                    </Button> 
                    </Grid>
            } 
            <Grid item={true} xs={6} md={2}>   
                <Button
                            variant="contained"
                            size="medium"
                            color="secondary"
                            onClick={() => props.exit()}
                        >
                    <Trans i18nKey="playroom.exit"></Trans>
                </Button> 
            </Grid>
            <Grid item={true} xs={12} md={8}>   <SmileyFeedback playroom={game.id} player={player}></SmileyFeedback> </Grid>
        </Grid>
    </>);
}

export default AfterParty;