import React from "react";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import List from "@material-ui/core/List";
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { makeStyles } from "@material-ui/core/styles";
import { useAuth } from "./../util/auth";
import { useNotificationsByTrustor } from "./../util/db";
import LinkMui from "@material-ui/core/Link";
import { Link, useNavigate } from "./../util/router";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Trans, useTranslation} from 'react-i18next';
import { localize } from "../util/i18n";

const useStyles = makeStyles((theme) => ({
  paperItems: {
    minHeight: "300px",
  },
  featured: {
    backgroundColor:
      theme.palette.type === "dark" ? theme.palette.action.selected : "#fdf8c2",
  },
  starFeatured: {
    color: theme.palette.warning.main,
  },
}));

function Notifications(props) {
  const classes = useStyles();

  const auth = useAuth();
  const { t, i18n } = useTranslation();
  const navigate= useNavigate();

  const {
    data: notifications,
    status: nfxStatus,
    error: nfxError,
  } = useNotificationsByTrustor(auth.user.uid, props.limit);

  const nfxsAreEmpty = !notifications || notifications.length === 0;

  return (
    <>
      {nfxError && (
        <Box mb={3}>
          <Alert severity="error">{nfxError.message}</Alert>
        </Box>
      )}
      <Paper className={classes.paperItems}>
        {(nfxStatus === "loading" || (nfxsAreEmpty)) && (
          <Box py={5} px={3} align="center">
            {nfxStatus === "loading" && <CircularProgress size={32} />}

            {(nfxStatus !== "loading" && nfxsAreEmpty) && (
              <Trans i18nKey="notifications.nothing"></Trans>
            )}
          </Box>
        )}

        {nfxStatus !== "loading" && notifications && notifications.length > 0 && (
          <List disablePadding={true}
          subheader={
            <ListSubheader disableSticky component="div" id="nested-list-subheader">
              <Box component="div"  sx={{ display: 'inline', width: '100%' }}>{ !!props.limit ? t("notifications.recent") : t("notifications.all") }
              { !!props.limit && <LinkMui component={Link} to="/notifications" color="primary">
                              <IconButton 
                                    edge="end"
                                    aria-label="close"
                                    onClick={props.onDone}   
                                  >
                                    <VisibilityIcon  style={{display:'inline', textAlign:'right'}}/>
                                  </IconButton>
                        </LinkMui> }
              </Box>
              
            </ListSubheader>
          }>
            {notifications.map((item, index) => (
              <ListItem
                key={index}
                divider={index !== notifications.length - 1}
                className={item.featured ? classes.featured : ""}
                onClick={() => navigate(item.payload.data.path)}
              >
                <ListItemText primary={ item.timestamp.toDate().toLocaleTimeString(i18n.resolvedLanguage) } secondary={ item.timestamp.toDate().toLocaleDateString(i18n.resolvedLanguage, { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' }) }></ListItemText>
                <ListItemText style={{display:'block', textAlign:'right'}} primary={ localize(item.payload.data.title) }
                      secondary={  item.payload.data.body && 
                        localize(item.payload.data.body)} 
                />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="update"
                    onClick={() => navigate(item.payload.data.path)}
                  >
                    <ArrowForwardIosIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        )}
       </Paper>
    </>
  );
}

export default Notifications;
