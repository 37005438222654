import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useForm } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import 'react-phone-number-input/style.css'
import { useAuth } from "../util/auth";
import { createInvitation, getTrustorsByPhone } from "../util/db";
import CustomPhoneNumber from './CustomPhoneNumber';
import PhoneInput from 'react-phone-number-input';
import { Trans, useTranslation} from 'react-i18next';
//import { requestContacts, supported } from "../util/contacts"



const useStyles = makeStyles((theme) => ({
  content: {
    paddingBottom: 24,
  },
}));

function InviteModal(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const auth = useAuth();
  const [pending, setPending] = useState(false);
  const [formAlert, setFormAlert] = useState(null);
  const [mynumber, setnumber] = useState("");

  const { register, handleSubmit, errors } = useForm();

  const supported =  ('contacts' in navigator && 'ContactsManager' in window);

  // const getContacts = () => {
  //   return;
  // }

  const onSubmit = (data) => {
    setPending(true);

    if (auth.user.phoneNumber === mynumber) {
      setPending(false);
      setFormAlert({
        type: "error",
        message: t("invite.error.self"),
      });
      return;
    }

    if (props.data.find(i => (i.initiator.phoneNumber === mynumber || i.invitee.phoneNumber === mynumber)) || props.conns.find(i => (i.phoneNumbers[0] === mynumber || i.phoneNumbers[1] === mynumber ))) {
      setPending(false);
      setFormAlert({
        type: "error",
        message: t("invite.error.existing"),
      });
      return;
    }

    getTrustorsByPhone(mynumber).then( (docs) => {
        var trustorId = "noIDyet";
        if (docs.length > 1) {
          setPending(false);
                setFormAlert({
                  type: "error",
                  message: t("invite.error.server"), 
                });
                return;
        }

        if (docs.length === 1) trustorId = docs[0].id; 
          const invitation = {
            initiator: {
              id: auth.user.id,
              name: auth.user.name,
              phoneNumber: auth.user.phoneNumber
            },
            invitee: {
              id: trustorId,
              name: data.name,
              phoneNumber: mynumber
            }
          }

          createInvitation(invitation)
            .then(() => {
              // Let parent know we're done so they can hide modal
              props.onDone();
            })
            .catch((error) => {
              // Hide pending indicator
              setPending(false);
              // Show error alert message
              setFormAlert({
                type: "error",
                message: error.message,
              });
            });
    });

    
  };

  return (
    <Dialog open={true} onClose={props.onDone}>
      <DialogTitle>
        {!props.id && <Trans i18nKey="invite.title"></Trans>}
      </DialogTitle>
      <DialogContent className={classes.content}>
        {formAlert && (
          <Box mb={4}>
            <Alert severity={formAlert.type}>{formAlert.message}</Alert>
          </Box>
        )}
        {supported && <Button variant="outlined"><Trans i18nKey="invite.contacts"></Trans></Button>}
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container={true} spacing={3}>
          <Grid item={true} xs={12}><Trans i18nKey="invite.prompt"></Trans></Grid>
            <Grid item={true} xs={12}>
              <TextField
                variant="outlined"
                type="text"
                label={t("invite.name.label")}
                name="name"
                error={errors.name ? true : false}
                helperText={errors.name && errors.name.message}
                fullWidth={true}
                autoFocus={true}
                inputRef={register({
                  required: t("invite.name.prompt"),
                })}
              />
            </Grid><Grid item={true} xs={12}><Trans i18nKey="invite.prompt2"></Trans></Grid>
            <Grid item={true} xs={12}>
            <PhoneInput
          international
          defaultCountry="US"
          //placeholder="Enter phone number"
          countryCallingCodeEditable={true}
          withCountryCallingCode={true}
          value={mynumber}
          onChange={setnumber}
          inputComponent={CustomPhoneNumber}
          inputRef={register({
            required:  t("invite.phones.prompt"),
          })}
             />
              
            </Grid>
            <Grid item={true} xs={12}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                disabled={pending}
              >
                {!pending && <span><Trans i18nKey="invite.button"></Trans></span>}

                {pending && <CircularProgress size={28} />}
              </Button>
            </Grid>
            <Grid item={true} xs={12}><Trans i18nKey="invite.memo"></Trans>
           
						</Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default InviteModal;
