import React, { useState, useEffect } from "react";
import { Typography } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import { useAuth } from "../util/auth";
import { floatString } from "../util/util";
import { useNavigate } from "../util/router";
import { makeStyles } from "@material-ui/core/styles";
import TransactionsModal from "./TransactionsModal";
import { Trans, useTranslation} from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    customTooltip: {
      backgroundColor: theme.palette.background.tooltip,
    },
    customArrow: {
      color: theme.palette.background.tooltip,
    }
  }));


function PersonalSecureBalance(props) {

  const navigate = useNavigate();
  const { t } = useTranslation();
  const [showingTransactions, setShowTransactions] = useState(false);

  useEffect(()=>{
    setShowTransactions(props.showHistory);
  },[props.showHistory]);
  
const classes = useStyles();
const auth = useAuth();

return (
    <>
    <Tooltip classes={{tooltip: classes.customTooltip, arrow: classes.customArrow}}  arrow title={t("dashboard.connections.psb.tooltip")} placement="top">
    <Button
                variant="outlined"
                color="default"
                size="large"
                type="button" 
                onClick={() => setShowTransactions(true)}       
              ><Typography variant="body1"><Trans i18nKey="dashboard.connections.psb.caption"></Trans>{floatString(auth.user.personalSecureBalance_micros / 1000000)}</Typography> 
              </Button>
    </Tooltip>
    {showingTransactions && <TransactionsModal onClose={() => {setShowTransactions(false); props.onHideHistory(); navigate("/dashboard");}} title={t("dashboard.connections.psb.transactions.title")} parties={[auth.user.uid]}/>}

    </>
);

}

export default PersonalSecureBalance;