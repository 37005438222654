import React, {useEffect, useReducer, useCallback, useRef} from 'react';
import window from 'global';
import PropTypes from "prop-types";
import reducer from "../redux/reducer";
import useEventListener from "../hooks/useEventListener";
import useIsServer from "../hooks/useIsServer";
import Knob from "./Knob";
import Labels from "./Labels";
import Svg from "./Svg";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid } from '@material-ui/core';

const spreadDegrees = 320;

const getRadians = (degrees) => {
    return degrees * Math.PI / 180;
};

const useStyles = makeStyles((theme) => ({
    resetButton: {
      textDecoration: "underline",
      textTransform: "lowercase",
    },
  }));

const generateRange = (min, max) => {
    let rangeOfNumbers = [];
    for(let i = min; i <= max; i++) {
        rangeOfNumbers.push(i);
    }
    return rangeOfNumbers;
};

const styles = ({
    circularSlider: {
        position: 'relative',
        display: 'inline-block',
        opacity: 0,
        transition: 'opacity 1s ease-in'
    },

    mounted: {
        opacity: 1
    },
});

const CircularSlider = ({
        label = 'ANGLE',
        resetButtonLabel = 'Reset',
        zeroValueLabelPrefix = 'There\'s nothing',
        zeroValueLabelSuffixNeg = 'in negative track',
        zeroValueLabelSuffixPos = 'in positive track',
        width = 280,
        direction = 1,
        min = 0,
        max = 360,
        knobColor = '#4e63ea',
        knobSize = 36,
        knobPosition = 'top',
        labelColor = '#272b77',
        labelBottom = false,
        labelTop = true,
        labelFontSize = '1rem',
        valueFontSize = '2.5rem',
        appendToValue = '',
        prependToValue = '',
        verticalOffset = '1.5rem',
        hideLabelValue = false,
        hideKnob = false,
        knobDraggable = true,
        progressColorNeg = '#80C3F3',
        progressColorPos = '#4990E2',
        progressSize = 8,
        trackColor = '#DDDEFB',
        trackSize = 8,
        data = [],
        dataIndex = 0,
        progressLineCap = 'round',
        renderLabelValue = null,
        initValue = 0,
        children,
        onChange = value => {},
    }) => {

    const svgFullPathNeg = useRef(null);
    const svgFullPathPos = useRef(null);

    const initialState = {
        mounted: true,
        isDragging: false,
        width: width,
        radius: width / 2,
        knobPosition: knobPosition,
        label: 0,
        data: data,
        radians: 0,
        degrees: 0,
        offset: 0,
        knob: {
            x: 0,
            y: 0,
        },
        dashFullArrayNeg: 0,
        dashFullArrayPos: 0,
        dashFullOffsetNeg: 0,
        dashFullOffsetPos: 0
    };

    const isServer = useIsServer();
    const [state, dispatch] = useReducer(reducer, initialState);
    const circularSlider = useRef(null);

    const touchSupported = !isServer && ('ontouchstart' in window);
    const SLIDER_EVENT = {
        DOWN: touchSupported ? 'touchstart' : 'mousedown',
        UP: touchSupported ? 'touchend' : 'mouseup',
        MOVE: touchSupported ? 'touchmove' : 'mousemove',
    };

    const classes = useStyles();

    const calculateDegreesFromValue = (value) => {
        return value > 0 ? (value/max) * spreadDegrees / 2 : -(value / min) * spreadDegrees / 2;
    }

    const resetValue = (value) => {
       
        const radius = state.radius - trackSize / 2;
      
        let degrees = calculateDegreesFromValue(value);

        if (value === 0) degrees = 0;

        const dashOffset = (degrees / spreadDegrees) * state.dashFullArrayPos;

        const currentLabel = degrees < 0 ? (2*-min*degrees/spreadDegrees).toFixed(3) : (2*max*degrees / spreadDegrees).toFixed(3);

        if(currentLabel !== state.label) {
            // props callback for parent
            onChange(parseInt(currentLabel));
        }

        dispatch({
            type: 'setKnobPosition',
            payload: {
                dashFullOffsetNeg:  dashOffset < 0 ? state.dashFullArrayNeg + parseInt(dashOffset*2) : state.dashFullArrayNeg,
                dashFullOffsetPos:  dashOffset > 0 ? state.dashFullArrayPos - parseInt(dashOffset*2) : state.dashFullArrayPos,
                degrees: degrees,
                label: parseInt(currentLabel),
                knob: {
                    x: (radius * Math.cos(getRadians(degrees - 90)) + radius),
                    y: (radius * Math.sin(getRadians(degrees - 90)) + radius),
                }
            }
        });
    }

    const setKnobPosition = useCallback((radians) => {
        
        const radius = state.radius - trackSize / 2;
        
        const offsetRadians = radians >= Math.PI/2 ? - 3/2*Math.PI + radians: radians + Math.PI / 2;

        let degrees = ( 360 * offsetRadians / (2 * Math.PI) );
        
        degrees = Math.max(-spreadDegrees/2, degrees);
        degrees = Math.min(spreadDegrees/2, degrees);

        const currentLabel = degrees < 0 ? (2*-min*degrees/spreadDegrees).toFixed(3) : (2*max*degrees / spreadDegrees).toFixed(3);
      
        if (state.label === max && degrees < -45) return;
      
        if (state.label === min && degrees > 45) return;

        if(currentLabel !== state.label) {
            // props callback for parent
            onChange(parseInt(currentLabel));
        }

        const dashOffset = (degrees / spreadDegrees) * state.dashFullArrayPos;

        dispatch({
            type: 'setKnobPosition',
            payload: {
                dashFullOffsetNeg:  dashOffset < 0 ? state.dashFullArrayNeg + parseInt(dashOffset*2) : state.dashFullArrayNeg,
                dashFullOffsetPos:  dashOffset > 0 ?  state.dashFullArrayPos - parseInt(dashOffset*2) : state.dashFullArrayPos,
                label: parseInt(currentLabel),
                degrees: degrees,
                knob: {
                    x: (radius * Math.cos(getRadians(degrees - 90)) + radius),
                    y: (radius * Math.sin(getRadians(degrees - 90)) + radius),
                }
            }
        });
    }, [state.dashFullArrayNeg, state.dashFullArrayPos, state.radius, state.label, trackSize, max, min, onChange]);

    const onMouseDown = () => {
        dispatch({
            type: 'onMouseDown',
            payload: {
                isDragging: true
            }
        });
    };

    const onMouseUp = () => {
        dispatch({
            type: 'onMouseUp',
            payload: {
                isDragging: false
            }
        });
    };

    const onMouseMove = useCallback((event) => {
        if (!state.isDragging || !knobDraggable) return;

        event.preventDefault();

        let touch;
        if (event.type === 'touchmove') {
            touch = event.changedTouches[0];
        }

        const offsetRelativeToDocument = (ref) => {
            const rect = ref.current.getBoundingClientRect();
            const scrollLeft = !isServer && ((window?.pageXOffset ?? 0) || (document?.documentElement?.scrollLeft ?? 0));
            const scrollTop = !isServer && ((window?.pageYOffset ?? 0) || (document?.documentElement?.scrollTop ?? 0));
            return {top: rect.top + scrollTop, left: rect.left + scrollLeft};
        };

        const mouseXFromCenter = (event.type === 'touchmove' ? touch.pageX : event.pageX) -
            (offsetRelativeToDocument(circularSlider).left + state.radius);
        const mouseYFromCenter = (event.type === 'touchmove' ? touch.pageY : event.pageY) -
            (offsetRelativeToDocument(circularSlider).top + state.radius);

        const radians = Math.atan2(mouseYFromCenter, mouseXFromCenter);
       
        setKnobPosition(radians);
    }, [state.isDragging, state.radius, setKnobPosition, knobDraggable, isServer]);

      // Get svg path length onmount or on min, max change
    useEffect(() => {
        dispatch({
            type: 'init',
            payload: {
                mounted: true,
                data: state.data.length ? [...state.data] : [...generateRange(min, max)],
                dashFullArrayPos: svgFullPathPos.current.getTotalLength ? svgFullPathPos.current.getTotalLength() : 0,
                dashFullArrayNeg: svgFullPathNeg.current.getTotalLength ? svgFullPathNeg.current.getTotalLength() : 0,
            }
        });
        // eslint-disable-next-line
    }, [max, min, svgFullPathPos, svgFullPathNeg]);

    // Set knob position
    useEffect(() => {
        const dataArrayLength = state.data.length;
         if(!!dataArrayLength) {
                resetValue(initValue);
        }

        // eslint-disable-next-line
    }, [state.dashFullArrayNeg, state.dashFullArrayPos, state.knobPosition, state.data.length, dataIndex, direction]);

    useEventListener(SLIDER_EVENT.MOVE, onMouseMove);
    useEventListener(SLIDER_EVENT.UP, onMouseUp);

    const sanitizedLabel = label.replace(/[\W_]/g, "_");

    return (
        <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12} sm={10} container justifyContent="center">
                <div style={{...styles.circularSlider, ...(state.mounted && styles.mounted)}} ref={circularSlider}>
                {renderLabelValue || (
                    <Labels
                        label={state.label === 0 && state.degrees !== 0 ? zeroValueLabelPrefix : label}
                        labelColor={labelColor}
                        labelBottom={state.label === 0 && state.degrees !== 0 }
                        labelBottomText={state.degrees < 0 ? zeroValueLabelSuffixNeg : zeroValueLabelSuffixPos }
                        labelTop={labelTop}
                        labelFontSize={labelFontSize}
                        verticalOffset={verticalOffset}
                        valueFontSize={valueFontSize}
                        appendToValue={appendToValue}
                        prependToValue={prependToValue}
                        hideLabelValue={hideLabelValue}
                        precision={6}
                        value={`${state.label}`}
                    />
                )}
                <Svg
                    width={width}
                    label={sanitizedLabel}
                    direction={direction}
                    strokeDasharrayNeg={state.dashFullArrayNeg}
                    strokeDashoffsetNeg={state.dashFullOffsetNeg}
                    strokeDasharrayPos={state.dashFullArrayPos}
                    strokeDashoffsetPos={state.dashFullOffsetPos}
                    svgFullPathNeg={svgFullPathNeg}
                    svgFullPathPos={svgFullPathPos}
                    progressSize={progressSize}
                    progressColorNeg={progressColorNeg}
                    progressColorPos={progressColorPos}
                    progressLineCap={progressLineCap}
                    trackColor={trackColor}
                    trackSize={trackSize}
                    radiansOffset={state.radians}
                />
                <Knob
                    isDragging={state.isDragging}
                    knobPosition={{ x: state.knob.x, y: state.knob.y }}
                    knobSize={knobSize}
                    knobColor={knobColor}
                    trackSize={trackSize}
                    hideKnob={hideKnob}
                    knobDraggable={knobDraggable}
                    onMouseDown={onMouseDown}
                >
                    {children}
                </Knob>
                </div>
            </Grid> 
            <Grid item xs={12} sm={2} container justifyContent="center"  style={{ display: "flex", alignItems: "center" }}><Box><Button className={classes.resetButton} onClick={() => resetValue(0)}>{resetButtonLabel}</Button></Box></Grid>
        </Grid>
    );
};

CircularSlider.propTypes = {
    label: PropTypes.string,
    resetButtonLabel: PropTypes.string,
    zeroValueLabelPrefix: PropTypes.string,
    zeroValueLabelSuffixNeg: PropTypes.string,
    zeroValueLabelSuffixPos: PropTypes.string,
    width: PropTypes.number,
    direction: PropTypes.number,
    min: PropTypes.number,
    max: PropTypes.number,
    knobColor: PropTypes.string,
    knobPosition: PropTypes.string,
    hideKnob: PropTypes.bool,
    knobDraggable: PropTypes.bool,
    labelColor: PropTypes.string,
    labelBottom: PropTypes.bool,
    labelFontSize: PropTypes.string,
    valueFontSize: PropTypes.string,
    appendToValue: PropTypes.string,
    renderLabelValue: PropTypes.element,
    prependToValue: PropTypes.string,
    verticalOffset: PropTypes.string,
    hideLabelValue: PropTypes.bool,
    progressLineCap: PropTypes.string,
    progressColorNeg: PropTypes.string,
    progressColorPos: PropTypes.string,
    progressSize: PropTypes.number,
    trackColor: PropTypes.string,
    trackSize: PropTypes.number,
    data: PropTypes.array,
    dataIndex: PropTypes.number,
    initValue: PropTypes.number,
    onChange: PropTypes.func
};

export default CircularSlider;
