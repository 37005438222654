import { Typography } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import { floatString } from "../util/util";
import { makeStyles } from "@material-ui/core/styles";
import TransactionsModal from "./TransactionsModal";
import { useNavigate } from "../util/router";
import { Trans, useTranslation} from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    customTooltip: {
      backgroundColor: theme.palette.background.tooltip,
    },
    customArrow: {
      color: theme.palette.background.tooltip,
    }
  }));

function TotalTrustedBalance(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [showingTransactions, setShowTransactions] = useState(false);

  useEffect(()=>{
    setShowTransactions(props.showHistory);
  },[props.showHistory]);

    let ttb_micros = 0;

    const parties = ["dummy_party"];

    props.connections.forEach(conn => {
        parties.push(conn.id);
        ttb_micros += conn.trustedBalance_micros;
    });

return (
    <>
    <Tooltip classes={{tooltip: classes.customTooltip, arrow: classes.customArrow}}  arrow title={t("dashboard.connections.ttb.tooltip")} placement="top">
    <Button
                variant="outlined"
                color="default"
                size="large"
                type="button" 
                onClick={() => setShowTransactions(true)}       
              ><Typography variant="body1"><Trans i18nKey="dashboard.connections.ttb.caption"></Trans>{floatString((ttb_micros / 1000000)) }</Typography> 
              </Button>
    </Tooltip>
    {showingTransactions && <TransactionsModal onClose={() => { setShowTransactions(false); props.onHideHistory(); navigate("/dashboard");}} title={t("dashboard.connections.ttb.transactions.title")} parties={parties}/>}

    </>
);

}

export default TotalTrustedBalance;
