import React from "react";
import Meta from "./../components/Meta";
import StatisticsSection from "./../components/StatisticsSection";
import { useTranslation } from 'react-i18next';

function StatisticsPage(props) {
  const { t } = useTranslation();

  return (
    <>
    <Meta title={t("meta.statistics.title")} />
    
      <StatisticsSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title={t("statistics.headline")}
        subtitle=""
      />
    </>
  );
}

export default StatisticsPage;