import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { useAuth } from "../util/auth";
import CircularSlider from './CircularSlider';
import { adjustBalance } from "./../util/db.js";
import TransactionsHistory from "./TransactionsHistory";
import { useDarkMode } from "./../util/theme";
import { floatString } from "../util/util";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { Switch } from "@material-ui/core";
import { Trans, useTranslation} from 'react-i18next';
import i18n from "../util/i18n";


const useStyles = makeStyles((theme) => ({
  content: {
    position: "sticky",
    paddingBottom: 24,
  },
  root: {
    position: "sticky",
  },
  resetButton: {
    textDecoration: "underline",
    textTransform: "lowercase",
  },
}));

function EditConnectionModal(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const auth = useAuth();

  const [value, setValue] = useState(0);
  const [exactValueStr, setExactValueStr] = useState("");
  const [pending, setPending] = useState(false);
  const [exact, setExact] = useState(false);
  const [formAlert, setFormAlert] = useState(null);
  const {register, handleSubmit } = useForm();
  const darkMode = useDarkMode();

  const conn = props.connection;

  const onSubmit = (data) => {
    if (parseInt(value) === 0) props.onDone();

    setPending(true);
    adjustBalance(auth.user.uid, props.connection.id, props.connection.lastTxn,parseInt(value), data.txnNote)
    .then( (docs) => {
      props.onDone();
    })
    .catch ( (error) => {
      setFormAlert({
        type: "error",
        message: error,
      });
    })
    .finally(() => {
      setPending(false);
    });
    ;
 
  };
  
  return (
    <Dialog open={true}  onClose={props.onClose}>
      <DialogTitle>
      <Grid container justifyContent="space-between"><Trans i18nKey="dashboard.connections.edit.title"></Trans>{ conn.trustors[0] === auth.user.uid ? conn.names[1] : conn.names[0]} ({conn.trustors[0] === auth.user.uid ? formatPhoneNumberIntl(conn.phoneNumbers[1]) : formatPhoneNumberIntl(conn.phoneNumbers[0])})
                  <IconButton
                    edge="end"
                    aria-label="close"
                    onClick={props.onDone}   
                  >
                    <CloseIcon />
                  </IconButton>
      </Grid>
      </DialogTitle>
      <DialogContent className={classes.content}>
      {formAlert && (
          <Box mb={4}>
            <Alert severity={formAlert.type}>{formAlert.message}</Alert>
          </Box>
        )}
      <Box sx={{paddingBottom: 25}}><Trans i18nKey="dashboard.connections.edit.prompt"></Trans></Box>
      
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container justifyContent="center" spacing={3}>
        
          <Grid item xs={12} sm={3} container justifyContent="center" style={{ display: "flex", alignItems: "center" }}><Box >
          <Trans i18nKey="dashboard.connections.edit.exact"></Trans><Switch
          color="primary"
          checked={exact}
          onChange={() => { setExact(!exact); setExactValueStr((value / 1000000).toFixed(6)); setValue(value >= 0 ? Math.min(auth.user.personalSecureBalance_micros, value)  : Math.max(-(props.connection.trustedBalance_micros), value) );}}
          inputProps={{ 'aria-label': 'controlled' }}
         />
            {/*<Button variant="outlined" size="small" onClick={() => { setExact(!exact); setExactValueStr((value / 1000000).toFixed(6));}}>{exact ? <>Dial</> : <>Precise</>}</Button> */}  </Box></Grid>
          <Grid item xs={12} sm={exact ? 7 : 9} container justifyContent="center" >
            {exact && <Box sx={{ height: 280 }} display="flex" 
        alignItems="center"><TextField
                  size="medium"
                  fullWidth={true}
                  value={exactValueStr}
                  variant="outlined"
                  color={ (value === 0.0 || value === undefined) ? "primary" : value > 0 ? "primary" : "secondary"}
                  type="number"
                  name="value"
                  inputRef={register({ required: true })}
                  inputProps={{step: "0.000001", lang: i18n.resolvedLanguage, inputMode: "decimal"}}
                  onChange={(e) => {setExactValueStr(e.target.value); setValue(parseInt(parseFloat(e.target.value).toFixed(6)*1000000))}}
                  /></Box>}
            {!exact && <CircularSlider
                min={-(props.connection.trustedBalance_micros)}
                max={auth.user.personalSecureBalance_micros}
                label={ (value > 0 ? t("dashboard.connections.edit.deposit") : (value < 0 ? t("dashboard.connections.edit.withdraw") : t("dashboard.connections.edit.adjustment"))) + t("dashboard.connections.edit.amount") }
                resetButtonLabel={t("dashboard.connections.edit.reset")}
                zeroValueLabelPrefix={t("dashboard.connections.edit.noTrustPrefix")}
                zeroValueLabelSuffixPos={t("dashboard.connections.edit.noTrustSuffixDeposit")}
                zeroValueLabelSuffixNeg={t("dashboard.connections.edit.noTrustSuffixWithdraw")}
                labelColor={ darkMode.value ? "#ddd" : "#555" }
                knobColor="#3f50b5"
                knobSize={50}
                progressColorNeg={"#f44336"}
                progressColorPos={"#009c9a"}
                progressSize={10}
                trackColor="#AAAAAA"
                trackSize={10}
                initValue={value}
                onChange={ value => { setValue(value);  } }
            />}</Grid>
            <Grid item xs={12} sm={2} container justifyContent="center" spacing={3}>{exact && <Button className={classes.resetButton} onClick={() => { setValue(0); setExactValueStr("0.000000");}}><Trans i18nKey="dashboard.connections.edit.reset"></Trans></Button>}</Grid>
            <Box display="flex" flexDirection="column" >{exact && <>{ (value > 0 ? t("dashboard.connections.edit.deposit") : (value < 0 ? t("dashboard.connections.edit.withdraw") : t("dashboard.connections.edit.adjustment"))) + t("dashboard.connections.edit.amount") + ": " +
             floatString((value >= 0 ? Math.min(auth.user.personalSecureBalance_micros, value) / 1000000 : Math.max(-(props.connection.trustedBalance_micros), value) / 1000000))}</>}</Box>
          
          <Grid item  xs={12} sm={12}> <TextField
                  multiline={true}
                  rows={3}
                  variant="filled"
                  type="text"
                  label={t("dashboard.connections.edit.note")}
                  name="txnNote"
                  placeholder={t("dashboard.connections.edit.noteplaceholder")}
                  fullWidth={true}
                  inputRef={register}
                  />
                </Grid>
                <Grid item xs={12} sm={12}><Button
                  variant="contained"
                  color="primary"
                  size="large"
                  type="submit"  
                  disabled={pending || value === 0 || isNaN(value)|| value === undefined}          
                  >
                  {!pending && <span><Trans i18nKey="dashboard.connections.edit.adjust"></Trans></span>}

                  {pending && <CircularProgress size={28} />}
                </Button></Grid>
        </Grid>
          </form>
          <br />
          <Divider />
          <TransactionsHistory connection={props.connection}></TransactionsHistory>
      </DialogContent>
    </Dialog>
  );
}

export default EditConnectionModal;
