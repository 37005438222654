import React from "react";
import Meta from "./../components/Meta";
import FaqSection from "./../components/FaqSection";
import { useTranslation } from 'react-i18next';

function FaqPage(props) {
  const { t } = useTranslation();

  return (
    <>
      <Meta title={t("meta.faq.title")} />
      <FaqSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title={t("faq.headline")}
        subtitle=""
      />
    </>
  );
}

export default FaqPage;
