import React from "react";
import Meta from "./../components/Meta";
import { useLocation } from "./../util/router";
import { Trans } from 'react-i18next';

function NotFoundPage(props) {

  let location = useLocation();

  return (
    <>
      <Meta title="404" />
      <div
        style={{
          padding: "50px",
          width: "100%",
          textAlign: "center",
        }}
      ><Trans i18nKey="pageNotFound.part1"></Trans> <code>{location.pathname}</code> <Trans i18nKey="pageNotFound.part2"></Trans>   
      </div>
    </>
  );
}

export default NotFoundPage;
