import { initializeApp, getApps, getApp } from "firebase/app";
import { getMessaging, onMessage, isSupported } from "firebase/messaging";
import { getAnalytics } from "firebase/analytics";
import { getFunctions } from 'firebase/functions';
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

let app;
let messaging = new Promise((rej, res) => {});
let functions;

if (getApps().length === 0) {
  // Initialize Firebase app
  app = initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET, 
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  });

  functions = getFunctions(app, "us-west2");

  getAnalytics(app);
  // Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
  // key is the counterpart to the secret key you set in the Firebase console.
  if (process.env.REACT_APP_FIREBASE_APPCHECK_DEBUG_TOKEN !== "false") {
    window.FIREBASE_APPCHECK_DEBUG_TOKEN = process.env.REACT_APP_FIREBASE_APPCHECK_DEBUG_TOKEN === "true" ? true : process.env.REACT_APP_FIREBASE_APPCHECK_DEBUG_TOKEN;
  }
  initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider(process.env.REACT_APP_RECAPTCHA3_PUBLIK_KEY),

    // Optional argument. If true, the SDK automatically refreshes App Check
    // tokens as needed.
    isTokenAutoRefreshEnabled: true
  });

  isSupported().then( yes => {
     if (yes) {
      messaging = getMessaging(app);
     } else {
            messaging.getToken = () => {};
            messaging.onMessage = () => {};
          }
   });
  } else {
    // Use existing app if already initialized
    app = getApp();
  }


export const firebaseFx = functions;

export const firebaseApp = app;

export const onMessageListener = () => 
     new Promise((resolve) => {
      onMessage(messaging, (payload) => {
        resolve(payload);
      });
    });


