import { getDatabase, ref, child, get, onValue, orderByValue, query, orderByChild, limitToLast } from "firebase/database";
import {
    useQuery,
    hashQueryKey,
  } from "react-query";
  import { client } from "./db"

const db = getDatabase();
const dbRef = ref(getDatabase());

/*** COUNTERS ***/
export function getCountersOnce() {
    return get(child(dbRef, `cnts`));
}; 

export function useCounters() {
    const quid = "cnts_listener";
    return useQuery(
        // Unique query key: https://react-query.tanstack.com/guides/query-keys
        ["cnts",  { quid } ],
        // Query function that subscribes to data and auto-updates the query cache
        createQuery(() => ref(db, 'cnts')),
        { enabled: !!quid }
    );
 }

 export function useConnectionsLeaders() {
  const quid = "pMTBcs_listener";
  return useQuery(
      // Unique query key: https://react-query.tanstack.com/guides/query-keys
      ["pMTBcs",  { quid } ],
      // Query function that subscribes to data and auto-updates the query cache
      createQuery(() => query(ref(db, 'pMTBcs'), orderByValue(), limitToLast(10))),
      { enabled: !!quid }
  );
}

export function useTrustorsTTBLeaders() {
  const quid = "tBsTTB_listener";
  return useQuery(
      // Unique query key: https://react-query.tanstack.com/guides/query-keys
      ["tBsTTB",  { quid } ],
      // Query function that subscribes to data and auto-updates the query cache
      createQuery(() => query(ref(db, 'tBs'), orderByChild("TTB"), limitToLast(10))),
      { enabled: !!quid }
  );
}

export function useTrustorsPSBLeaders() {
  const quid = "tBsPSB_listener";
  return useQuery(
      // Unique query key: https://react-query.tanstack.com/guides/query-keys
      ["tBsPSB",  { quid } ],
      // Query function that subscribes to data and auto-updates the query cache
      createQuery(() => query(ref(db, 'tBs'), orderByChild("PSB"), limitToLast(10))),
      { enabled: !!quid }
  );
}


 /*** HELPERS ***/
// Store Firestore unsubscribe functions
const unsubs = {};

function createQuery(getRef) {
  // Create a query function to pass to `useQuery`
  return async ({ queryKey }) => {
    let unsubscribe;
    let firstRun = true;

    const data = await new Promise((resolve, reject) => {
      unsubscribe = onValue(
        getRef(),
        // Success handler resolves the promise on the first run.
        // For subsequent runs we manually update the React Query cache.
        (response) => {
          const data = response.val();
          if (firstRun) {
            firstRun = false;
            resolve(data);
          } else {
            client.setQueryData(queryKey, data);
          }
        },
        // Error handler rejects the promise on the first run.
        // We can't manually trigger an error in React Query, so on a subsequent runs we
        // invalidate the query so that it re-fetches and rejects if error persists.
        (error) => {
          if (firstRun) {
            firstRun = false;
            reject(error);
          } else {
            client.invalidateQueries(queryKey);
          }
        }
      );
    });

    // Unsubscribe from an existing subscription for this `queryKey` if one exists
    // Then store `unsubscribe` function so it can be called later
    const queryHash = hashQueryKey(queryKey);
    unsubs[queryHash] && unsubs[queryHash]();
    unsubs[queryHash] = unsubscribe;

    return data;
  };
}

// Automatically remove Firestore subscriptions when all observing components have unmounted
client.queryCache.subscribe(({ type, query }) => {
    if (
      type === "observerRemoved" &&
      query.getObserversCount() === 0 &&
      unsubs[query.queryHash]
    ) {
      // Call stored Firestore unsubscribe function
      unsubs[query.queryHash]();
      delete unsubs[query.queryHash];
    }
  });

