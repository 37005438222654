import React from "react";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import List from "@material-ui/core/List";
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import CircularProgress from "@material-ui/core/CircularProgress";
import { floatString } from "../util/util";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation, Trans } from 'react-i18next';


const useStyles = makeStyles((theme) => ({
  paperItems: {
    minHeight: "300px",
  },
}));

function Statistics(props) {

  const { t } = useTranslation();

  const classes = useStyles();
     const {
        data: counters,
        status: countersStatus,
        error: countersError,
      } = props.counters;
    
      const countersAreEmpty = !counters || counters.length === 0;

    return( 
     <Paper className={classes.paperItems}>
        {countersError && (
        <Box mb={3}>
          <Alert severity="error">{countersError.message}</Alert>
        </Box>
      )}
        { countersAreEmpty &&  <Trans i18nKey="statistics.nodata"></Trans>}
        { countersError && (
          <Box mb={3}>
            <Alert severity="error">{countersError.message}</Alert>
          </Box>
        )}
        <List disablePadding={true}
            subheader={
                <ListSubheader disableSticky component="div" id="nested-list-subheader">
                 <Trans i18nKey="statistics.subheader"></Trans>
                </ListSubheader>
            }>
                <ListItem
                    key="cb"
                    divider
                >
                   { countersStatus !== "loading" && <><ListItemText style={{display:'block', textAlign:'left'}} primary={t("statistics.cb")} /><ListItemText style={{display:'block', textAlign:'right'}} primary={floatString(counters.gCBm / 1000000)} /></>}
                   { countersStatus === "loading" && <CircularProgress size={28} />}
                 </ListItem>
                <ListItem
                    key="gtb"
                    divider
                >
                   { countersStatus !== "loading" && <><ListItemText style={{display:'block', textAlign:'left'}} primary={t("statistics.gtb")} /><ListItemText style={{display:'block', textAlign:'right'}} primary={floatString(counters.gTBm / 1000000)} /></>}
                   { countersStatus === "loading" && <CircularProgress size={28} />}
                </ListItem>
                <ListItem
                    key="apc"
                    divider
                >
                   { countersStatus !== "loading" && <><ListItemText style={{display:'block', textAlign:'left'}} primary={t("statistics.apc")} /><ListItemText style={{display:'block', textAlign:'right'}} primary={floatString(counters.gTBm / 1000000 / counters.tcc)} /></>}
                   { countersStatus === "loading" && <CircularProgress size={28} />}
                </ListItem>
                <ListItem
                    key="gsb"
                    divider
                >
                   { countersStatus !== "loading" && <><ListItemText style={{display:'block', textAlign:'left'}} primary={t("statistics.gsb")} /><ListItemText style={{display:'block', textAlign:'right'}} primary={floatString(counters.gPBm / 1000000)} /></>}
                   { countersStatus === "loading" && <CircularProgress size={28} />}
                </ListItem>
                <ListItem
                    key="apt"
                    divider
                >
                   { countersStatus !== "loading" && <><ListItemText style={{display:'block', textAlign:'left'}} primary={t("statistics.apt")} /><ListItemText style={{display:'block', textAlign:'right'}} primary={floatString(counters.gPBm / 1000000 / counters.ttc)} /></>}
                   { countersStatus === "loading" && <CircularProgress size={28} />}
                </ListItem>
                <ListItem
                    key="tet"
                    divider
                >
                   { countersStatus !== "loading" && <><ListItemText style={{display:'block', textAlign:'left'}} primary={t("statistics.tet")} /><ListItemText style={{display:'block', textAlign:'right'}} primary={floatString( 3 * counters.nct)} /></>}
                   { countersStatus === "loading" && <CircularProgress size={28} />}
                </ListItem>
                <ListItem
                    key="edir"
                    divider
                >
                   { countersStatus !== "loading" && <><ListItemText style={{display:'block', textAlign:'left'}} primary={t("statistics.edir")} /><ListItemText style={{display:'block', textAlign:'right'}} primary={floatString(counters.gCBm / 3 / counters.gTBm * 100)+"%"} /></>}
                   { countersStatus === "loading" && <CircularProgress size={28} />}
                </ListItem>
                <ListItem
                    key="eeg"
                    divider
                >
                   { countersStatus !== "loading" && <><ListItemText style={{display:'block', textAlign:'left'}} primary={t("statistics.eeg")} /><ListItemText style={{display:'block', textAlign:'right'}} primary={floatString(counters.gCBm / 3 / 1000000 / counters.pMTBtc)} /></>}
                   { countersStatus === "loading" && <CircularProgress size={28} />}
                </ListItem>
                <ListItem
                    key="elj"
                    divider
                >
                   { countersStatus !== "loading" && <><ListItemText style={{display:'block', textAlign:'left'}} primary={t("statistics.elj")} /><ListItemText style={{display:'block', textAlign:'right'}} primary={floatString(counters.gCBm / 3 / 1000000)} /></>}
                   { countersStatus === "loading" && <CircularProgress size={28} />}
                </ListItem>
                <ListItem
                    key="lpc"
                    divider
                >
                   { countersStatus !== "loading" && <><ListItemText style={{display:'block', textAlign:'left'}} primary={t("statistics.lpc")} /><ListItemText style={{display:'block', textAlign:'right'}} primary={counters.lpc} /></>}
                   { countersStatus === "loading" && <CircularProgress size={28} />}
                </ListItem>
                <ListItem
                    key="ttc"
                    divider
                >
                   { countersStatus !== "loading" && <><ListItemText style={{display:'block', textAlign:'left'}} primary={t("statistics.ttc")} /><ListItemText style={{display:'block', textAlign:'right'}} primary={counters.ttc} /></>}
                   { countersStatus === "loading" && <CircularProgress size={28} />}
                </ListItem>
                <ListItem
                    key="ntt"
                    divider
                >
                   { countersStatus !== "loading" && <><ListItemText style={{display:'block', textAlign:'left'}} primary={t("statistics.ntt")} /><ListItemText style={{display:'block', textAlign:'right'}} primary={counters.ntt} /></>}
                   { countersStatus === "loading" && <CircularProgress size={28} />}
                </ListItem>
                <ListItem
                    key="tcc"
                    divider
                >
                   { countersStatus !== "loading" && <><ListItemText style={{display:'block', textAlign:'left'}} primary={t("statistics.tcc")} /><ListItemText style={{display:'block', textAlign:'right'}} primary={counters.tcc} /></>}
                   { countersStatus === "loading" && <CircularProgress size={28} />}
                </ListItem>
                <ListItem
                    key="nct"
                    divider
                >
                   { countersStatus !== "loading" && <><ListItemText style={{display:'block', textAlign:'left'}} primary={t("statistics.nct")} /><ListItemText style={{display:'block', textAlign:'right'}} primary={counters.nct} /></>}
                   { countersStatus === "loading" && <CircularProgress size={28} />}
                </ListItem>
                <ListItem
                    key="pic"
                    divider
                >
                   { countersStatus !== "loading" && <><ListItemText style={{display:'block', textAlign:'left'}} primary={t("statistics.pic")} /><ListItemText style={{display:'block', textAlign:'right'}} primary={counters.pic} /></>}
                   { countersStatus === "loading" && <CircularProgress size={28} />}
                </ListItem>
                <ListItem
                    key="pit"
                    divider
                >
                   { countersStatus !== "loading" && <><ListItemText style={{display:'block', textAlign:'left'}} primary={t("statistics.pit")} /><ListItemText style={{display:'block', textAlign:'right'}} primary={counters.pit} /></>}
                   { countersStatus === "loading" && <CircularProgress size={28} />}
                </ListItem>
                
                <ListItem
                    key="mtbc"
                    divider
                >
                   { countersStatus !== "loading" && <><ListItemText style={{display:'block', textAlign:'left'}} primary={t("statistics.pmtbc")} /><ListItemText style={{display:'block', textAlign:'right'}} primary={counters.pMTBtc} /></>}
                   { countersStatus === "loading" && <CircularProgress size={28} />}
                </ListItem>
                <ListItem
                    key="psbt"
                    divider
                >
                   { countersStatus !== "loading" && <><ListItemText style={{display:'block', textAlign:'left'}} primary={t("statistics.ppsbt")} /><ListItemText style={{display:'block', textAlign:'right'}} primary={counters.pPSBtc} /></>}
                   { countersStatus === "loading" && <CircularProgress size={28} />}
                </ListItem> 
        </List>
     </Paper>
     );
}

export default Statistics;
