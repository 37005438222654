import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import List from "@material-ui/core/List";
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import CallMadeIcon from '@material-ui/icons/CallMade';
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import InviteModal from "./InviteModal";
import EditInviteModal from "./EditInviteModal";
import EditConnectionModal from "./EditConnectionModal"
import PersonalSecureBalance from "./PersonalSecureBalance";
import TotalTrustedBalance from "./TotalTrustedBalance"
import { useAuth } from "../util/auth";
import { useNavigate } from "../util/router";
import { useIncomingInvitesToTrustor,
         useOutgoingInvitesByTrustor,
         useActiveConnectionsByTrustor,
         updateInvitation }
  from "../util/db";
import { floatString } from "../util/util";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { Trans, useTranslation} from 'react-i18next';


const useStyles = makeStyles((theme) => ({
  paperItems: {
    minHeight: "300px",
  },
  featured: {
    backgroundColor:
      theme.palette.type === "dark" ? theme.palette.action.selected : "#fdf8c2",
  },
  starFeatured: {
    color: theme.palette.warning.main,
  },
  customTooltip: {
    backgroundColor: theme.palette.background.tooltip,
  },
  customArrow: {
    color: theme.palette.background.tooltip,
  }
}));

function DashboardConnections(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const auth = useAuth();

  const navigate = useNavigate();

  const {
    data: incomingInvites,
    status: incomingStatus,
    error: incomingError,
  } = useIncomingInvitesToTrustor(auth.user); 

  const {
    data: outgoingInvites,
    status: outgoingStatus,
    error: outgoingError,
  } = useOutgoingInvitesByTrustor(auth.user); 


  const {
    data: activeConnections,
    status: activeStatus,
    error: activeError,
  } = useActiveConnectionsByTrustor(auth.user);

  const {
    data: counters,
    status: countersStatus
  } = props.counters;

  const ttb_micros = () =>  {
    let ttb_micros_result = 0;
    activeConnections.forEach(conn => {
      ttb_micros_result += conn.trustedBalance_micros;
    });
    return ttb_micros_result;
  };

  const [creatingInvite, setCreatingInvite] = useState(false);
  const [showingTTBHistory, setShowTTBHistory] = useState(false);
  const [showingPSBHistory, setShowPSBHistory] = useState(false);
  const [updatingInvite, setUpdatingInvite] = useState(null);
  const [updatingConnection, setUpdatingConnection] = useState(null);
  const [formAlert, setFormAlert] = useState(null);
  const [pending, setPending] = useState(false);

  const incomingAreEmpty = !incomingInvites || incomingInvites.length === 0;

  const outgoingAreEmpty = !outgoingInvites || outgoingInvites.length === 0;

  const activeAreEmpty = !activeConnections || activeConnections.length === 0;

  useEffect(() => {
    if (props.invite && !updatingInvite && incomingStatus!=="loading" && outgoingStatus!=="loading") {
      const invite = incomingInvites.find(i => i.id === props.invite) || outgoingInvites.find(i => i.id === props.invite);
      if (invite) { 
        // if invitation found - present
        setUpdatingInvite(invite); 
      } else {
        // if not found or no invites at all - see if it has not yet been accepted and is active
        navigate("/c/"+props.invite, { replace: true });
      }
    }
  }, [updatingInvite, props.invite, incomingStatus, outgoingStatus, incomingAreEmpty, outgoingAreEmpty, incomingInvites, outgoingInvites, navigate]);

  useEffect(() => {
    if (props.connection && !updatingConnection && activeStatus!=="loading") {
      const connection = activeConnections.find(i => i.id === props.connection);
      if (connection) { 
        setUpdatingConnection(connection); 
      } 
    }
  }, [updatingConnection, props.connection, activeStatus,  activeAreEmpty, activeConnections]);

  
  useEffect(() => {
    if (!showingTTBHistory && props.ttb) {
      setShowTTBHistory(true);
    }
  },[props.ttb, showingTTBHistory])

  useEffect(() => {
    if (!showingPSBHistory && props.psb) {
      setShowPSBHistory(true);
    }
  },[props.psb, showingPSBHistory])


  const updateInviteStatus = (id, newStatus) => {
    
    const phoneNumber = updatingInvite.initiator.phoneNumber;
    const name = updatingInvite.initiator.name;
    const trId = updatingInvite.initiator.id;

    setUpdatingInvite(null);
    // here we use initiator's provided invitee name for trustor because it will be displayed to initiator only
    return updateInvitation(id, newStatus, {id: auth.user.uid , name: updatingInvite.invitee.name, phoneNumber: auth.user.phoneNumber }, {id: trId, name: name, phoneNumber: phoneNumber })
    .then(() => {
     
    })
    .catch((error) => {  
        // Set error status
        //console.log(error);
        setFormAlert({
          type: "error",
          message: error.message,
        });
      
    })
    .finally(() => {
      // Hide pending indicator
      setPending(false);  
    });
  }

  return (
    <>
      {incomingError && (
        <Box mb={3}>
          <Alert severity="error">{incomingError.message}</Alert>
        </Box>
      )}
      {outgoingError && (
        <Box mb={3}>
          <Alert severity="error">{outgoingError.message}</Alert>
        </Box>
      )}
      {activeError && (
        <Box mb={3}>
          <Alert severity="error">{activeError.message}</Alert>
        </Box>
      )}
      <Paper className={classes.paperItems}>
      {formAlert && (
          <Box mb={4}>
            <Alert severity={formAlert.type}>{formAlert.message}</Alert>
          </Box>
        )}
        {activeStatus === "loading" && <CircularProgress size={32}></CircularProgress> }
        {activeStatus !== "loading" && <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          padding={2}
                        >
          <Grid container justifyContent="space-between" spacing={2} direction="row">
            <Grid item container xs={6} sm={6} justifyContent="flex-start"><PersonalSecureBalance showHistory={showingPSBHistory} onHideHistory={() => setShowPSBHistory(false)}></PersonalSecureBalance></Grid>
            <Grid item container xs={6} sm={6} justifyContent="flex-end"><TotalTrustedBalance connections={activeConnections} showHistory={showingTTBHistory} onHideHistory={() => setShowTTBHistory(false)}/></Grid>
            {countersStatus !== "loading" && <><Grid item container xs={6} sm={6} justifyContent="flex-start"><Tooltip classes={{tooltip: classes.customTooltip, arrow: classes.customArrow}} arrow title={t("dashboard.connections.edir.tooltip")} placement="top">
              <Typography variant="body1"><Trans i18nKey="dashboard.connections.edir.caption"></Trans>{floatString(counters.gCBm / 3 / counters.gTBm * 100)+"%"}</Typography></Tooltip></Grid>
            <Grid item container xs={6} sm={6} justifyContent="flex-end"><Tooltip classes={{tooltip: classes.customTooltip, arrow: classes.customArrow}} arrow title={t("dashboard.connections.edi.tooltip")} placement="top">
              <Typography variant="body1"><Trans i18nKey="dashboard.connections.edi.caption"></Trans>{floatString(ttb_micros() * counters.gCBm / 3 / counters.gTBm / 1000000)}</Typography></Tooltip>
            </Grid></>} 
          </Grid>
        </Box>}

        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          padding={2}
        >
          <Typography variant="h5"><Trans i18nKey="dashboard.connections.title"></Trans></Typography>
          <Button
            variant="contained"
            size="medium"
            color="primary"
            onClick={() => setCreatingInvite(true)}
          >
            <Trans i18nKey="dashboard.connections.cta"></Trans>
          </Button>
        </Box>
        <Divider />

        {(incomingStatus === "loading" || outgoingStatus === "loading" || activeStatus === "loading" || (incomingAreEmpty && outgoingAreEmpty && activeAreEmpty)) && (
          <Box py={5} px={3} align="center">
            {(incomingStatus === "loading" || outgoingStatus === "loading" || activeStatus === "loading") && <CircularProgress size={32} />}

            {((incomingStatus !== "loading" && incomingAreEmpty) && (outgoingStatus !== "loading" && outgoingAreEmpty)  && (activeStatus !== "loading" && activeAreEmpty))  && (
             <><Trans i18nKey="dashboard.connections.lonelypre"></Trans><CallMadeIcon></CallMadeIcon><Trans i18nKey="dashboard.connections.lonelyafter"></Trans></>
            )}
          </Box>
        )}

        {pending && <CircularProgress size={28} />}

        {incomingStatus !== "loading" && incomingInvites && incomingInvites.length > 0 && (
          <List disablePadding={true}
          subheader={
            <ListSubheader disableSticky component="div" id="nested-list-subheader">
              <Trans i18nKey="dashboard.connections.yougotinvited"></Trans>({incomingInvites.length})
            </ListSubheader>
          }>
            {incomingInvites.map((item, index) => (
              <ListItem
                key={index}
                divider={index !== incomingInvites.length - 1}
                className={item.featured ? classes.featured : ""}
                onClick={() => setUpdatingInvite(item)}
              >
                <ListItemText primary={ item.initiator.name} secondary={ formatPhoneNumberIntl(item.initiator.phoneNumber)}></ListItemText>
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="update"
                    onClick={() => setUpdatingInvite(item)}
                  >
                    <ArrowForwardIosIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        )}

        {outgoingStatus !== "loading" && outgoingInvites && outgoingInvites.length > 0 && (
          <List disablePadding={true}
          subheader={
            <ListSubheader disableSticky component="div" id="nested-list-subheader">
              <Trans i18nKey="dashboard.connections.yousentinvites"></Trans>({outgoingInvites.length})
            </ListSubheader>
          }>
            {outgoingInvites.map((item, index) => (
              <ListItem
                key={index}
                divider={index !== outgoingInvites.length - 1}
                className={item.featured ? classes.featured : ""}
                onClick={() => setUpdatingInvite(item)}
              >
                 <ListItemText primary={ item.invitee.name} secondary={ formatPhoneNumberIntl(item.invitee.phoneNumber)}></ListItemText>
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="update"
                    onClick={() => setUpdatingInvite(item)}
                  >
                    <ArrowForwardIosIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
       )}

      {activeStatus !== "loading" && activeConnections && activeConnections.length > 0 && (
          <List disablePadding={true}
          subheader={
            <ListSubheader disableSticky component="div" id="nested-list-subheader">
              <Trans i18nKey="dashboard.connections.activeconnections"></Trans>({activeConnections.length})
            </ListSubheader>
          }>
            {activeConnections.map((item, index) => (
              <ListItem
                key={index}
                divider={index !== activeConnections.length - 1}
                className={item.featured ? classes.featured : ""}
                onClick={() => setUpdatingConnection(item)}
                
              >
                <ListItemText primary={ item.trustors[0] === auth.user.uid ? item.names[1] : item.names[0]} secondary={ item.trustors[0] === auth.user.uid ? formatPhoneNumberIntl(item.phoneNumbers[1]) : formatPhoneNumberIntl(item.phoneNumbers[0])}></ListItemText>
                <ListItemText><Grid container justifyContent="flex-end"><Trans i18nKey="dashboard.connections.mtb"></Trans>{ floatString(item.trustedBalance_micros / 1000000) }</Grid></ListItemText>
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="update"
                    onClick={() => setUpdatingConnection(item)}
                  >
                    <ArrowForwardIosIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
       )}
       
       </Paper>
      
      {creatingInvite && <InviteModal onDone={() => setCreatingInvite(false)} data={ incomingInvites.concat(outgoingInvites) } conns={ activeConnections }/>}

      {updatingInvite && updatingInvite.status === 0 && (
        <EditInviteModal
          invite={updatingInvite}
          onDone={(newStatus) => { updateInviteStatus(updatingInvite.id, newStatus); navigate("/dashboard");}}
          onClose={ () => { setUpdatingInvite(null); navigate("/dashboard"); }}
        />
      )}

      {updatingConnection  && (
        <EditConnectionModal
          connection={updatingConnection}
          onDone={() => { setUpdatingConnection(null); navigate("/dashboard");}}
          onClose={ () => { setUpdatingConnection(null); navigate("/dashboard");}}
        />
      )}
    </>
  );
}

export default DashboardConnections;
