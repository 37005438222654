import React from "react";
import Meta from "./../components/Meta";
import HeroSection from "./../components/HeroSection";
import HeroSection3 from "./../components/HeroSection3";
import StatsSection from "./../components/StatsSection";
//import ClientsSection from "./../components/ClientsSection";
import FeaturesSection from "./../components/FeaturesSection";
//import TestimonialsSection from "./../components/TestimonialsSection";
import NewsletterSection from "./../components/NewsletterSection";
import { useTranslation } from 'react-i18next';

function IndexPage(props) {

  const { t } = useTranslation(); 

  return (
    <>
      <Meta />
      <HeroSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title={t("index.title")}
        subtitle={t("index.subtitle")}
        image="https://uploads.divjoy.com/undraw-japan_ubgk.svg"
        buttonText={t("index.button")}
        buttonColor="primary"
        buttonPath="/dashboard"
      />
    { /*   <ClientsSection
        bgColor="light"
        size="normal"
        bgImage=""
        bgImageOpacity={1}
        title=""
        subtitle=""
  /> */ }
      <FeaturesSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title={t("features.title")}
        subtitle={t("features.subtitle")}
      />
      { /*  <TestimonialsSection
        bgColor="light"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Here's what people are saying"
        subtitle=""
      /> */ }
      <StatsSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
      />

      <HeroSection3
        bgColor="default"
        size="medium"
        bgImage=""
        // Background image opacity (0-1)
        bgImageOpacity={1}
        title={t("hero3.title")}
        subtitle={t("hero3.subtitle")}
        buttonText={t("hero3.button")}
        buttonColor="primary"
        buttonPath="/dashboard"
      />
      <NewsletterSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title={t("newsletter.title")}
        subtitle={t("newsletter.subtitle")}
        buttonText={t("newsletter.subscribe")}
        buttonColor="primary"
        inputPlaceholder={t("newsletter.placeholder")}
        subscribedMessage={t("newsletter.message")}
        inputLabel={t("newsletter.label")}
      />
      
    </>
  );
}

export default IndexPage;
