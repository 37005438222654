import { Button, Slider } from "@material-ui/core";
import React from "react";
import { useTranslation } from 'react-i18next';
import { rules, getRole, getRoleAChoice } from "./utils";

function ChoiceMaker(props) {
    let marks = [
        {
          value: 0,
          label: '0',
        },
        {
          value: 1,
          label: '1',
        },
        {
          value: 2,
          label: '2',
        },
        {
          value: 3,
          label: '3',
        },
        {
            value: 4,
            label: '4',
          },
          {
            value: 5,
            label: '5',
          },
          {
            value: 6,
            label: '6',
          },
          {
            value: 7,
            label: '7',
          },
          {
            value: 8,
            label: '8',
          },
          {
            value: 9,
            label: '9',
          },
          {
            value: 10,
            label: '10',
          },
          {
            value: 11,
            label: '11',
          },
          {
            value: 12,
            label: '12',
          },
          {
            value: 13,
            label: '13',
          },
          {
              value: 14,
              label: '14',
            },
            {
              value: 15,
              label: '15',
            },
            {
              value: 16,
              label: '16',
            },
            {
              value: 17,
              label: '17',
            },
            {
              value: 18,
              label: '18',
            },
            {
              value: 19,
              label: '19',
            },
            {
              value: 20,
              label: '20',
            }
      ];

    const game = props.game;
    const role = getRole(game, props.uid);
    const endowment = game.state === 1 ? game.endowment : getRoleAChoice(game) * rules[game.gameType].multiplier;
    const step = rules[game.gameType].choice === "integer" ? 1 : rules[game.gameType].precision;

    const { t } = useTranslation();

    return (<>
            { // show slider choice control for discrete or continuous choice
                    (rules[game.gameType].choice === "integer" || rules[game.gameType].choice === "float") &&
                    <> 
                    <Slider
                            aria-label="Share choice"
                            value={props.choice}
                            onChange={props.handleChoiceChange}
                            step={step}
                            min={0.0}
                            max={endowment }
                            marks={marks}
                            valueLabelDisplay="auto"
                            /> 
                            <Button
                            variant="contained"
                            size="medium"
                            color="primary"
                            onClick={() => props.submitChoice(props.choice)}
                        >
                            { t("playroom.rules."+game.gameType+".numericchoice."+game.state, {amount: props.choice})} 
                        </Button> &nbsp; { t("playroom.rules."+game.gameType+".numericchoice.keep", {amount: endowment - props.choice})}
                        </>
            }
            { // show 2 buttons binary choice for binary type
                    rules[game.gameType].choice === "binary" && 
                        <>
                         <Button
                            variant="contained"
                            size="medium"
                            color="primary"
                            onClick={() =>  props.submitChoice(1) }
                        >
                            {t("playroom.rules."+game.gameType+".binarychoice."+role+".1", {amount: 1})} 
                        </Button>&nbsp;&nbsp;&nbsp;
                        <Button
                            variant="contained"
                            size="medium"
                            color="secondary"
                            onClick={() =>  props.submitChoice(0) }
                        >
                            {t("playroom.rules."+game.gameType+".binarychoice."+role+".0", {amount: 0})} 
                        </Button>
                        </>
            }
        
    </>);
}

export default ChoiceMaker;