import React from "react";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import List from "@material-ui/core/List";
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import { useAuth } from "./../util/auth";
import { useTransactionsByConnection } from "./../util/db";
import { Trans, useTranslation } from 'react-i18next';
import { localize } from "../util/i18n";

const useStyles = makeStyles((theme) => ({
  paperItems: {
    minHeight: "300px", 
  },
  featured: {
    backgroundColor:
      theme.palette.type === "dark" ? theme.palette.action.selected : "#fdf8c2",
  },
  starFeatured: {
    color: theme.palette.warning.main,
  },
}));

function TransactionsHistory(props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const auth = useAuth();

  const {
    data: transactions,
    status: txnStatus,
    error: txnError,
  } = useTransactionsByConnection(props.connection.id);

  const txnsAreEmpty = !transactions || transactions.length === 0;

  const getDestinationName = (transaction) => {
      switch (transaction.to) {
        case transaction.connection: return t("transactions.destination.mtb"); 
        case auth.user.uid: return t("transactions.destination.you");
        case process.env.REACT_APP_CENTRAL_BALANCE_ID: return t("transactions.destination.cb");
        default: return props.connection.trustors[0] === auth.user.uid ? props.connection.names[1] : props.connection.names[0];
      }
  }

  const getActorName = (transaction) => {
    switch (transaction.type) {
        case "deposit": return transaction.from === auth.user.uid ? t("transactions.actor.you") : (props.connection.trustors[0] === auth.user.uid ? props.connection.names[1] : props.connection.names[0]);
        case "withdrawal": return transaction.to === auth.user.uid ? t("transactions.actor.you") : (props.connection.trustors[0] === auth.user.uid ? props.connection.names[1] : props.connection.names[0]);
        default: return t("transactions.actor.system");
    }
  }

  return (
    <>
      {txnError && (
        <Box mb={3}>
          <Alert severity="error">{txnError.message}</Alert>
        </Box>
      )}
      <Paper className={classes.paperItems}>
        {(txnStatus === "loading" || (txnsAreEmpty)) && (
          <Box py={5} px={3} align="center">
            {txnStatus === "loading" && <CircularProgress size={32} />}

            {(txnStatus !== "loading" && txnsAreEmpty) && (
               <Trans i18nKey="transactions.nodata"></Trans>
            )}
          </Box>
        )}

        {txnStatus !== "loading" && transactions && transactions.length > 0 && (
          <List disablePadding={true}
          subheader={
            <ListSubheader disableSticky component="div" id="nested-list-subheader">
              <Trans i18nKey="transactions.subheader"></Trans>
            </ListSubheader>
          }>
            {transactions.map((item, index) => (
              <ListItem
                key={index}
                divider={index !== transactions.length - 1}
                className={item.featured ? classes.featured : ""}
              >
                <ListItemText primary={ item.timestamp.toDate().toLocaleTimeString(i18n.resolvedLanguage) } secondary={ item.timestamp.toDate().toLocaleDateString(i18n.resolvedLanguage, { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' }) }></ListItemText>
                <ListItemText style={{display:'block', textAlign:'right'}} primary={ t("transactions."+item.type+".title", {type: item.type, amount: item.amount_micros / 1000000, who: getActorName(item), dest: getDestinationName(item)})}
                secondary={ item.note && localize(item.note) }   />
                </ListItem>
            ))}
          </List>
        )}
       </Paper>
    </>
  );
}

export default TransactionsHistory;
