import React from "react";
import Meta from "./../components/Meta";
import HeroSection2 from "./../components/HeroSection2";
import { useTranslation } from 'react-i18next';

function AboutPage(props) {
  const { t } = useTranslation();
  return (
    <>
      <Meta title={t("meta.rules.title")}  description={t("meta.rules.description")} />
      <HeroSection2
        bgColor="primary"
        size="large"
        bgImage=""
        bgImageOpacity={0.2}
        title={t("rules.title")}
        subtitle={t("rules.subtitle")}
        
      />
    </>
  );
}

export default AboutPage;
