import {
  useQuery,
  hashQueryKey,
  QueryClient,
  QueryClientProvider as QueryClientProviderBase,
} from "react-query";
import {
  getFirestore,
  onSnapshot,
  doc,
  collection,
  query,
  where,
  limit,
  orderBy,
  getDoc,
  getDocs,
  setDoc,
  updateDoc,
  addDoc,
  deleteDoc,
  serverTimestamp,
  increment,
  runTransaction,
  Timestamp
} from "firebase/firestore";
import { firebaseApp } from "./firebase";
import { 
   deviceDetect
} from "react-device-detect";

const crypto = require("crypto");

// Initialize Firestore
const db = getFirestore(firebaseApp);

// React Query client
export const client = new QueryClient();

/**** TRUSTORS ****/

export async function trustorExists(uid)  {
  const result = await getDoc(doc(db, "trustors", uid));
  return result.exists();
}

// Create a new user
export function createTrustor(uid, data) {
  // create trustor and claim all existing invitations/connections by phone number,
  // but not those that are already accepted/suspended
  
  return setDoc(doc(db, "trustors", uid), {
    ...data,
    createdOn: serverTimestamp(),
    lastTxn: "genesis",
    registeredOn: serverTimestamp(),
    updatedOn: serverTimestamp(),
   }, { merge: true });
}

// Subscribe to user data
// Note: This is called automatically in `auth.js` and data is merged into `auth.user`
export function useTrustor(uid) {
  // Manage data fetching with React Query: https://react-query.tanstack.com/overview
  return useQuery(
    // Unique query key: https://react-query.tanstack.com/guides/query-keys
    ["user", { uid }],
    // Query function that subscribes to data and auto-updates the query cache
    createQuery(() => doc(db, "trustors", uid)),
    // Only call query function if we have a `uid`
    { enabled: !!uid }
  );
}



// Update an existing user
export function updateTrustor(uid, data) {
  return updateDoc(doc(db, "trustors", uid), data , { merge: true });
}

export function getTrustorsByPhone(phone) {
  const q = query(collection(db, "trustors"), where("phoneNumber", "==", phone));
  return getDocs(q).then(format);
}

export function setMessagingTokenForTrustor(trustorId, token) {
  return setDoc(doc(db, "trustors/"+trustorId+"/tokens", token), {
    device: deviceDetect().userAgent,
    firstSeen: serverTimestamp(),
    lastValid: serverTimestamp()
  });
}

export function useTokens(uid) {
  // Manage data fetching with React Query: https://react-query.tanstack.com/overview
  const tqid = uid+"_tokens";
  return useQuery(
    // Unique query key: https://react-query.tanstack.com/guides/query-keys
    ["tokens", { tqid }],
    // Query function that subscribes to data and auto-updates the query cache
    createQuery(() => query(collection(db, "trustors/"+uid+"/tokens")),
                   where("device","!=", ""))
    // Only call query function if we have a `uid`
    ,{ enabled: !!uid }
  );
}

export function removeMessagingTokenForTrustor(trustorId, token) {
  return deleteDoc(doc(db, "trustors/"+trustorId+"/tokens", token));
}


/**** INVITATIONS ****/

export async function unclaimedInviteExistsForNumber(phoneNumber)  {
  const q = query(collection(db, "invitations"), where("invitee.phoneNumber", "==", phoneNumber),
    where("status","==", 0),
    where("invitee.id", "==", "noIDyet"));
  const result = await getDocs(q);
  return result.size > 0;
}

export function claimInvitations(trustorId, phoneNumber) {
  const q = query(collection(db, "invitations"), where("invitee.phoneNumber", "==", phoneNumber),
            where("status","==", 0),
            where("invitee.id", "==", "noIDyet"));
  return getDocs(q).then( (docs) => {
     docs.forEach( (document) => {
      updateDoc(doc(db, "invitations", document.id), { "invitee.id":  trustorId , updatedOn: serverTimestamp() } ,{ merge: true });
     });
  })
  .catch((error) => {
    console.log(error);
  });
  
}

// Create a new item
export function createInvitation(data) {
  return addDoc(collection(db, "invitations"), {
    ...data,
    createdOn: serverTimestamp(),
    acceptedOn: new Timestamp(0, 0),
    status: 0,  // 0 - sent, 1 - accepted, 2 - rejected, 3 - rescinded, 4 - blocked, 5 - accept requested
    updatedOn: serverTimestamp()
  });
}

export function useIncomingInvitesToTrustor(trustor) {
  const snapKey  = trustor.id + ".incoming";
  return useQuery(
    ["invitations", { snapKey }],
    createQuery(() =>
      query(
        collection(db, "invitations"),
        where("invitee.id", "==", trustor.id),
        where("status", "==", 0)    
      )
    ),
    { enabled: !!trustor }
  );
}

export function useOutgoingInvitesByTrustor(trustor) {
  const snapKey  = trustor.id + ".outgoing";
  return useQuery(
    ["invitations", { snapKey }],
    createQuery(() =>
      query(
        collection(db, "invitations"),
        where("initiator.id", "==", trustor.id),
        where("status", "==", 0)    
      )
    ),
    { enabled: !!trustor }
  );
}

// Update an invitation
export function updateInvitation(id, status, trustor, trustee) {
 // 0 - sent, 1 - accepted, 2 - rejected, 3 - rescinded, 4 - blocked, 5 - accept requested
  return updateDoc(doc(db, "invitations", id), { status: status, updatedOn: serverTimestamp() } , { merge: true });
}

// Fetch an invitation
export function getInvitation(id) {
  return getDoc(doc(db, "invitations", id)).then(format);
}

/**** CONNECTIONS ****/
export function createConnection(data) {
  return addDoc(collection(db, "connections"), {
    ...data,
    createdOn: serverTimestamp(),
    acceptedOn: new Timestamp(0, 0),
    lastTxn: "genesis",
    status: 2,
    trustedBalance_micros: 0,
    updatedOn: serverTimestamp()
  });
}

export function useActiveConnectionsByTrustor(trustor) {
  const trustorId = trustor.id + ".active";

  return useQuery(
    ["connections", { trustorId }],
    createQuery(() =>
      query(
        collection(db, "connections"),
        where("trustors", "array-contains", trustor.id),
        where("status", "==", 0)
      )
    ),
    { enabled: !!trustor.id && !!trustor.phoneNumber }
  );
}

export function adjustBalance(trustorId, connId, lastTxn, amount_micros, note) {
  return runTransaction(db, async (transaction) => {

    let trustorRef = doc(db,"trustors",trustorId);
		let connRef = doc(db,"connections",connId);
		let transRef = doc(db,"transactions",lastTxn);

    let trustorDoc = await transaction.get(trustorRef);
    let connDoc = await transaction.get(connRef);

    	// verify that there is enough personal secure balance to deposit
		if  (amount_micros > 0 && trustorDoc.data().personalSecureBalance_micros < amount_micros) {
      return Promise.reject("Not enough PSB to deposit " + amount_micros);
		}
    // verify that there is enough trustedBalance to withdraw
    if  (amount_micros < 0 && connDoc.data().trustedBalance_micros < Math.abs(amount_micros)) {
      return Promise.reject("Not enough MTB to withdraw " + amount_micros);
    }

    // create and record transaction
    let ref = doc(collection(db, "transactions"));
    var transRecord = { amount_micros:  Math.abs(amount_micros), from: amount_micros < 0 ? connId : trustorId, to: amount_micros < 0 ? trustorId : connId,
       connection: connId, parties: [amount_micros < 0 ? connId : trustorId, amount_micros < 0 ? trustorId : connId],
       type: amount_micros < 0 ? "withdrawal" : "deposit", timestamp: new Date(), note: note, prevTxn: lastTxn, checksum: "genesis" };
		if (lastTxn !== "genesis") {
      let transDocument = await transaction.get(transRef);
      if (!transDocument.exists()) {
        return Promise.reject("Previous transactions document does not exist: " + lastTxn    );
      }
      transRecord.checksum = getChecksum(transRecord, transDocument.checksum);
    }
    transaction.set(ref, transRecord);

    // update balances
    let data1 = { personalSecureBalance_micros: increment(-amount_micros), "lastTxn" : ref.id, "updatedOn": serverTimestamp()};
    let data2 = { trustedBalance_micros: increment(amount_micros), "lastTxn" : ref.id, "updatedOn": serverTimestamp()};
    transaction.update(trustorRef, data1);
    transaction.update(connRef, data2);
  });	
}

/*** TRANSACTIONS ***/
export function useTransactionsByConnection(connId) {
  return useQuery(
    ["transactions", { connId }],
    createQuery(() =>
      query(
        collection(db, "transactions"),
        where("connection", "==", connId),
        orderBy("timestamp", "desc")
      )
    ),
    { enabled: !!connId }
  );
}

export function useTransactionsByParties(parties) {
  const qid = getHash(parties.toString());
  return useQuery(
    ["transactions", { qid }],
    createQuery(() =>
      query(
        collection(db, "transactions"),
        where("parties", "array-contains-any", parties),
        orderBy("timestamp", "desc")
      )
    ),
    { enabled: !!parties }
  );
}

/*** NOTIFICATION ***/
export function useNotificationsByTrustor(trustorId, nfxsLimit) {
  return useQuery(
    ["notifications", { trustorId }],
    createQuery(() =>
      query(
        collection(db, "notifications"),
        where("recipient", "==", trustorId),
        orderBy("timestamp", "desc"),
        limit(nfxsLimit)
      )
    ),
    { enabled: !!trustorId }
  );
}

/*** PLAYROOMS ****/

export function useOpenPlaySessions(trustorId) {
  return useQuery(
    ["playsessions"],
    createQuery(() =>
      query(
        collection(db, "playrooms"),
        where("state", "==", 0),
      )
    ),
    { enabled: !!trustorId }
  );
}

export function useClosedPlaySessions(trustorId) {
  return useQuery(
    ["playhistory", { trustorId }],
    createQuery(() =>
      query(
        collection(db, "playrooms"),
        where("players", "array-contains", trustorId),
        where("state", ">", 5),
        where("nextRoom","==", null),
      )
    ),
    { enabled: !!trustorId }
  );
}

export function createPlayroom(data) {
  return addDoc(collection(db, "playrooms"), {
    ...data,
    playerA: { 
      ...data.playerA,
      joinedOn: serverTimestamp()
    },
    createdOn: serverTimestamp(),
  });
}

export function joinPlayroom(playroomId, data) {
  return setDoc(doc(db, "playrooms", playroomId), {
    ...data,
    playerB: { 
      ...data.playerB,
      joinedOn: serverTimestamp()
    },
  }, { merge: true });
}

export function usePlayroom(playroomId) {
  return useQuery(
    ["playrooms", { playroomId }],
    createQuery(() => doc(db, "playrooms", playroomId)),
    { enabled: !!playroomId }
  );
}

export function updateChoice(playroomId, player, choice, state) {
   const data = player === "A" ? { state: state, "playerA.choice": choice, "playerA.submittedOn": serverTimestamp() } : { state: state, "playerB.choice": choice, "playerB.submittedOn": serverTimestamp() }
   return updateDoc(doc(db, "playrooms", playroomId), data , { merge: true });
}

export function updateState(playroomId, state, newPlayroom = null ) {
  const data =  { state: state, nextRoom: newPlayroom }
  return updateDoc(doc(db, "playrooms", playroomId), data , { merge: true });
}

export function updateBalances(playroomId, game , balanceA, balanceB) {
  const data =  { playerA: { ...game.playerA, balance: balanceA },  playerB: { ...game.playerB, balance: balanceB } }
  return updateDoc(doc(db, "playrooms", playroomId), data , { merge: true });
}

export function updateFeedback(playroomId, player, feedback) {
  const data = player === "A" ? { "playerA.feedback": feedback } : { "playerB.feedback": feedback }
   return updateDoc(doc(db, "playrooms", playroomId), data , { merge: true });
}


/**** HELPERS ****/

function getChecksum(record, prevChecksum) {
      let timestring = record.timestamp.toISOString().split('.')[0]+"Z";
			let hashstring = prevChecksum + record.amount_micros + record.from + record.to + record.connection + record.type + timestring + record.note + record.prevTxn;
      const hash = getHash(hashstring);
			return hash;
}

function getHash(input) {
  const sha256Hasher = crypto.createHmac("sha256","");
  const hash = sha256Hasher.update(input).digest("hex");
  return hash;
}

// Store Firestore unsubscribe functions
const unsubs = {};

function createQuery(getRef) {
  // Create a query function to pass to `useQuery`
  return async ({ queryKey }) => {
    let unsubscribe;
    let firstRun = true;
    // Wrap `onSnapshot` with a promise so that we can return initial data
    const data = await new Promise((resolve, reject) => {
      unsubscribe = onSnapshot(
        getRef(),
        // Success handler resolves the promise on the first run.
        // For subsequent runs we manually update the React Query cache.
        (response) => {
          const data = format(response);
          if (firstRun) {
            firstRun = false;
            resolve(data);
          } else {
            client.setQueryData(queryKey, data);
          }
        },
        // Error handler rejects the promise on the first run.
        // We can't manually trigger an error in React Query, so on a subsequent runs we
        // invalidate the query so that it re-fetches and rejects if error persists.
        (error) => {
          if (firstRun) {
            firstRun = false;
            reject(error);
          } else {
            client.invalidateQueries(queryKey);
          }
        }
      );
    });

    // Unsubscribe from an existing subscription for this `queryKey` if one exists
    // Then store `unsubscribe` function so it can be called later
    const queryHash = hashQueryKey(queryKey);
    unsubs[queryHash] && unsubs[queryHash]();
    unsubs[queryHash] = unsubscribe;

    return data;
  };
}

// Automatically remove Firestore subscriptions when all observing components have unmounted
client.queryCache.subscribe(({ type, query }) => {
  if (
    type === "observerRemoved" &&
    query.getObserversCount() === 0 &&
    unsubs[query.queryHash]
  ) {
    // Call stored Firestore unsubscribe function
    unsubs[query.queryHash]();
    delete unsubs[query.queryHash];
  }
});

// Format Firestore response
function format(response) {
  // Converts doc into object that contains data and `doc.id`
  const formatDoc = (doc) => ({ id: doc.id, ...doc.data() });
  if (response.docs) {
    // Handle a collection of docs
    return response.docs.map(formatDoc);
  } else {
    // Handle a single doc
    return response.exists() ? formatDoc(response) : null;
  }
}

// React Query context provider that wraps our app
export function QueryClientProvider(props) {
  return (
    <QueryClientProviderBase client={client}>
      {props.children}
    </QueryClientProviderBase>
  );
}
